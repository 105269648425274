/* eslint-disable no-cond-assign */
/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
import { notify } from '@sobrus-com/sobrus-design-system';
import { CTag as Tag } from 'Components';
import { convertFromRaw, Modifier, SelectionState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { OrderedSet } from 'immutable';
import { isEqual } from 'lodash';
import { useMemo } from 'react';
import { renderMarkup } from 'react-render-markup';
import { useLocation } from 'react-router-dom';
import moment from 'Services/moment';
import { MESSAGES } from 'Values/CONST';

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const sliceText = (message, number = 15) => {
  let newMessage;
  if (message?.length > number) {
    newMessage = `${message?.slice(0, number)} . . .`;
    return newMessage;
  }
  return message;
};

export const days = (day) => {
  switch (day) {
    case 0:
      return 'Lundi';
    case 1:
      return 'Mardi';
    case 2:
      return 'Mercredi';
    case 3:
      return 'Jeudi';
    case 4:
      return 'Vendredi';
    case 5:
      return 'Samedi';
    case 6:
      return 'Dimanche';
    default:
      break;
  }
};
export const openInNewTab = (url) => {
  const setUrl = () => {
    if (!url.startsWith('https')) {
      if (url.startsWith('http')) return url;
      return `https://${url}`;
    }
    return url;
  };
  // // (setUrl());
  const newWindow = window.open(setUrl(), '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};
const buildFormData = (formData, data, parentKey) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
};

export const jsonToFormData = (data) => {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
};

/// / pdf

const findWithRegex = (regex, contentBlock, diff, callback) => {
  const text = contentBlock.getText();
  let matchArr;
  let start;
  let end;
  let i = 0;
  while ((matchArr = regex.exec(text)) !== null) {
    start = i === 0 ? matchArr.index : matchArr.index - i * diff;
    end = i === 0 ? start + matchArr[0].length : start + matchArr[0].length;
    // // ("step " + i, { start, end });
    i++;
    callback(start, end);
  }
};
const onReplace = (content, mention, replacedWith) => {
  const regex = new RegExp(mention, 'g');
  const selectionsToReplace = [];
  const blockMap = content.getBlockMap();

  const diff = mention.length - replacedWith.length;

  blockMap.forEach((contentBlock) =>
    findWithRegex(regex, contentBlock, diff, (start, end) => {
      const blockKey = contentBlock.getKey();
      const blockSelection = SelectionState.createEmpty(blockKey).merge({
        anchorOffset: start,
        focusOffset: end,
      });

      selectionsToReplace.push(blockSelection);
    })
  );

  let contentState = content;

  selectionsToReplace.forEach((selectionState) => {
    contentState = Modifier.replaceText(contentState, selectionState, replacedWith, OrderedSet.of('BOLD'));
  });

  return contentState;
};
export const replaceContent = (content, contract, arrangement) => {
  let newContent = onReplace(convertFromRaw(content), '@Prénom', contract.ownerFirstName);
  newContent = onReplace(newContent, '@Nom', contract.ownerLastName);
  newContent = onReplace(newContent, "@Ordre d'article", arrangement.disposition.toString());
  newContent = !contract.ownerCin
    ? onReplace(newContent, '@CIN', '______')
    : onReplace(newContent, '@CIN', contract.ownerCin);
  newContent = !contract.ownerRib
    ? onReplace(newContent, '@RIB', '________________________')
    : onReplace(newContent, '@RIB', contract.ownerRib);
  newContent = !contract.ownerBank
    ? onReplace(newContent, '@Banque', '____________')
    : onReplace(newContent, '@Banque', contract.ownerBank);
  newContent = !contract.ownerPhoneNumber
    ? onReplace(newContent, '@Téléphone', '__________')
    : onReplace(newContent, '@Téléphone', contract.ownerPhoneNumber);
  newContent = !contract.ownerAddress
    ? onReplace(newContent, '@Adresse', '______________________________________________________________')
    : onReplace(newContent, '@Adresse', contract.ownerAddress);
  newContent = onReplace(
    newContent,
    "@Date d'embauche",
    moment(contract.ownerHiringDate.substring(0, 10)).format('DD/MM/YYYY')
  );
  newContent = contract.ownerResignationDate
    ? onReplace(
        newContent,
        '@Date de démission',
        moment(contract.ownerResignationDate.substring(0, 10)).format('DD/MM/YYYY')
      )
    : onReplace(newContent, '@Date de démission', '___/___/______');
  newContent = contract.ownerInternshipStartDate
    ? onReplace(
        newContent,
        '@Date de début de stage',
        moment(contract.ownerInternshipStartDate.substring(0, 10)).format('DD/MM/YYYY')
      )
    : onReplace(newContent, '@Date de début de stage', '___/___/______');
  newContent = contract.ownerInternshipEndDate
    ? onReplace(
        newContent,
        '@Date de fin de stage',
        moment(contract.ownerInternshipEndDate.substring(0, 10)).format('DD/MM/YYYY')
      )
    : onReplace(newContent, '@Date de fin de stage', '___/___/______');
  newContent = contract.ownerCnss
    ? onReplace(newContent, '@CNSS', contract.ownerCnss.toString())
    : onReplace(newContent, '@CNSS', '__________');
  newContent = !contract.ownerBirthDate
    ? onReplace(newContent, '@Date de Naissance', '___/___/______')
    : onReplace(
        newContent,
        '@Date de Naissance',
        moment(contract.ownerBirthDate.substring(0, 10)).format('DD/MM/YYYY')
      );
  newContent = !contract.ownerBonusesAndCommissions
    ? onReplace(newContent, '@Primes et Commissions', '______________________________________________________________')
    : onReplace(newContent, '@Primes et Commissions', contract.ownerBonusesAndCommissions);
  newContent = !contract.ownerNationality
    ? onReplace(newContent, '@Nationalité', '_____________')
    : onReplace(newContent, '@Nationalité', contract.ownerNationality);
  newContent = !contract.ownerCity
    ? onReplace(newContent, '@Ville', '_____________')
    : onReplace(newContent, '@Ville', contract.ownerCity);
  newContent = contract.ownerDepartment
    ? onReplace(newContent, '@Département', contract.ownerDepartment)
    : onReplace(newContent, '@Département', '__________');
  newContent = contract.ownerJob
    ? onReplace(newContent, '@Poste', contract.ownerJob)
    : onReplace(newContent, '@Poste', '__________');
  newContent = onReplace(
    newContent,
    '@Salaire net',
    `${parseFloat(contract.ownerSalary).toFixed(2).toString()} Dirhams`
  );
  newContent = onReplace(
    newContent,
    '@Salaire actuel',
    `${parseFloat(contract.ownerActualSalary).toFixed(2).toString()} Dirhams`
  );
  newContent = onReplace(
    newContent,
    "@Date d'aujourd'hui",
    moment(contract.generatedAt.substring(0, 10)).format('DD/MM/YYYY')
  );
  newContent = !contract.contractDate
    ? onReplace(newContent, '@Date de contrat', '___/___/______')
    : onReplace(newContent, '@Date de contrat', moment(contract.contractDate.substring(0, 10)).format('DD/MM/YYYY'));
  newContent = !contract.ownerCivility
    ? onReplace(newContent, '@Civilité', '_____')
    : onReplace(newContent, '@Civilité', contract.ownerCivility);

  return newContent;
};
// export const options = {
//   blockStyleFn: (block) => {
//     if (block.getType() === 'align-center') {
//       return {
//         style: {
//           'text-align': 'center',
//         },
//       };
//     }
//     if (block.getType() === 'align-left') {
//       return {
//         style: {
//           'text-align': 'left',
//         },
//       };
//     }
//     if (block.getType() === 'align-right') {
//       return {
//         style: {
//           'text-align': 'right',
//         },
//       };
//     }
//     if (block.getType() === 'justify') {
//       return {
//         style: {
//           'text-align': 'justify',
//         },
//       };
//     }
//     return {
//       style: {
//         'text-align': 'left',
//       },
//     };
//   },
// };

export const options = {
  blockStyleFn: (block) => {
    const data = block.getData();
    // eslint-disable-next-line eqeqeq
    if (data.size == 0) return;

    let style = {};
    if (data.get('text-align')) style = { ...style, textAlign: data.get('text-align') };
    return {
      // ar mode
      // attributes: {
      //   dir: 'auto',
      // },
      style,
    };
  },
};

export const convertToHtml = (draftContent) => {
  const content = JSON.parse(draftContent);
  const convertOptions = {
    blockStyleFn: (block) => {
      if (block.getType() === 'align-center') {
        return {
          style: {
            'text-align': 'center',
          },
        };
      }
      if (block.getType() === 'align-left') {
        return {
          style: {
            'text-align': 'left',
          },
        };
      }
      if (block.getType() === 'align-right') {
        return {
          style: {
            'text-align': 'right',
          },
        };
      }
      if (block.getType() === 'justify') {
        return {
          style: {
            'text-align': 'justify',
          },
        };
      }
      return {
        style: {
          'text-align': 'left',
        },
      };
    },
  };
  let markup = stateToHTML(convertFromRaw(content), convertOptions);
  markup = markup.replaceAll('\n', '');

  return <div>{renderMarkup(markup)}</div>;
};

// Create custom comparison function:
export const compareProps = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
};

export const catchFunction = (error, setLoading = false, message = '') => {
  setLoading && setLoading(false);
  if (error?.message === 'canceled') {
    return 0;
  }
  if (error?.response?.data?.message === "collaborater doesn't belong to the event team") {
    notify({
      type: 'warning',
      msg: MESSAGES.WARNING.EVENT_AFFECT,
      delay: 5000,
    });
    return 0;
  }
  if (error?.response?.data?.message === "collaborater doesn't belong to the event team") {
    notify({
      type: 'warning',
      msg: MESSAGES.WARNING.EVENT_AFFECT,
      delay: 5000,
    });
    return 0;
  }
  if (error?.response?.data?.message === 'event cannot be reported') {
    notify({
      type: 'warning',
      msg: `${MESSAGES.WARNING.EVENT_REPORT.PART1} ${message} ${MESSAGES.WARNING.EVENT_REPORT.PART2}`,
      delay: 5000,
    });
    return 0;
  }
  if (error?.response?.data?.message?.startsWith('code')) {
    localStorage.clear();
    window.location.reload();
  } else if (error?.response?.data?.message?.includes('Cannot delete or update a parent row')) {
    notify({
      type: 'warning',
      msg: MESSAGES.WARNING.DELETE,
      delay: 5000,
    });
    return 0;
  } else {
    notify({
      type: 'danger',
      msg: error?.response?.data?.message || error?.response?.data,
      delay: 5000,
    });
  }
  return 0;
};

export const getCalendarStatus = (status) => {
  switch (status) {
    case 'Non affecté':
      return (
        <Tag MinSize={120} color="#94989D" bg="rgba(148,152,157,.08)">
          Non affecté
        </Tag>
      );
    case 'Affecté':
      return (
        <Tag MinSize={120} color="#1BBAAC" bg="rgba(27,186,172,.08)">
          Affecté
        </Tag>
      );
    case 'Confirmé':
      return (
        <Tag MinSize={120} color="#74AC0C" bg="rgba(116,172,12,.06)">
          Confirmé
        </Tag>
      );
    case 'Achevé':
      return (
        <Tag MinSize={120} color="#5D4BA7" bg="rgba(129,116,181,.08)">
          Achevé
        </Tag>
      );
    case 'Reporté':
      return (
        <Tag MinSize={120} color="#EBC132" bg="rgba(235,193,50,.08)">
          Reporté
        </Tag>
      );
    case 'Annulé':
      return (
        <Tag MinSize={120} color="#F05A29" bg="rgba(240,90,41,.06)">
          Annulé
        </Tag>
      );
    default:
      break;
  }
};

export const getStatusColors = (status) => {
  switch (status) {
    case 'Non affecté':
      return '#94989D';
    case 'Affecté':
      return '#1BBAAC';
    case 'Confirmé':
      return '#74AC0C';
    case 'Achevé':
      return '#5D4BA7';
    case 'Reporté':
      return '#EBC132';
    case 'Annulé':
      return '#F05A29';
    default:
      break;
  }
};

export const getRequestStatus = (status) => {
  return (
    <>
      {status === 'Waiting' ? (
        <Tag MinSize={120} bg="#55ACED">
          En attente
        </Tag>
      ) : status === 'Denied' ? (
        <Tag MinSize={120} bg="#F05A29">
          Refusée
        </Tag>
      ) : (
        status === 'Validated' && (
          <Tag MinSize={120} bg="#1BBAAC">
            Validée
          </Tag>
        )
      )}
    </>
  );
};
export const leaveRequestStatus = (status) => {
  return (
    <>
      {status === 'Waiting' ? (
        <Tag MinSize={120} bg="#55ACED">
          En attente
        </Tag>
      ) : status === 'Denied' ? (
        <Tag MinSize={120} bg="#F05A29">
          Refusée
        </Tag>
      ) : status === 'Validated' ? (
        <Tag MinSize={120} bg="#1BBAAC">
          Validée
        </Tag>
      ) : (
        status === 'Signed' && (
          <Tag MinSize={120} bg="#1BBAAC">
            Signée
          </Tag>
        )
      )}
    </>
  );
};

export const jobStatusTag = (status) => (
  <>
    {status === 'En poste' ? (
      <Tag MinSize={100} bg="#1BBAAC">
        En Poste
      </Tag>
    ) : (
      status === 'Sortant' && (
        <Tag MinSize={100} bg="#F05A29">
          Sortant
        </Tag>
      )
    )}
  </>
);
export const hardwaresStatusTag = (status) => (
  <>
    {status === 'new' ? (
      <Tag MinSize={120} bg="#55ACED">
        Neuf
      </Tag>
    ) : status === 'good' ? (
      <Tag MinSize={120} bg="#1BBAAC">
        Bon état
      </Tag>
    ) : status === 'average' ? (
      <Tag MinSize={120} bg="#EAA207">
        Etat moyen
      </Tag>
    ) : status === 'bad' ? (
      <Tag MinSize={120} bg="#F05A29">
        Mauvais état
      </Tag>
    ) : (
      '--'
    )}
  </>
);
export const softWaresStatusTag = (status) => (
  <>
    {status === 'valid' ? (
      <Tag MinSize={120} bg="#1BBAAC">
        Valide
      </Tag>
    ) : (
      status === 'expired' && (
        <Tag MinSize={120} bg="#F03829">
          Expiré
        </Tag>
      )
    )}
  </>
);
export const hardwaresStatus = (status) => (
  <>
    {status === 'for_sale' ? 'À vendre' : status === 'sold' ? 'Vendu' : status === 'scrapped' ? 'Mis au rebut' : '--'}
  </>
);
