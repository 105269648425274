import { useAcl } from 'Hooks';
import { useEffect } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import API from 'Services/API';
import useSWR from 'swr';

const fetcher = (url) => API.get(url).then((res) => res.data);
export const useSwr = (url, user, defaultState, dispatch) => {
  const { can } = useAcl();
  const { data } = useSWR(can(['get_collaborater_notifications']) ? url : null, fetcher, { ...defaultState });
  useEffect(() => {
    if (data && user?.id) {
      unstable_batchedUpdates(() => {
        dispatch({
          type: 'SET_PAGES',
          value: Math.ceil(data.totalUnseen / 10),
        });
        dispatch({
          type: 'SET_HISTORY_PAGES',
          value: Math.ceil(data.totalSeen / 10),
        });
        dispatch({
          type: 'SET_NOTIFICATIONS',
          value: data?.unseenNotifications,
        });
        dispatch({
          type: 'SET_HISTORY_NOTIFICATIONS',
          value: data?.seenNotifications,
        });
        dispatch({ type: 'SET_TOTAL_NOTIFICATIONS', value: data?.totalUnseen });
        dispatch({
          type: 'SET_TOTAL_HISTORY_NOTIFICATIONS',
          value: data?.totalSeen,
        });
      });
    }
  }, [data, dispatch, user]);
};
