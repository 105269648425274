import { CircularProgress, IconButton, SpinnerLoading } from '@sobrus-com/sobrus-design-system';
import { memo } from 'react';
import ContentLoader from 'react-content-loader';
import { compareProps } from 'Services/functionHelpers';

export const ButtonLoader = memo(({ outline, size }) => {
  return (
    <CircularProgress
      style={{
        marginLeft: 15,
        marginBottom: -2,
        color: `${outline ? 'primary' : 'light'}`,
      }}
      size={size}
    />
  );
}, compareProps);
const defaultProps = {
  outline: false,
  size: 26,
};
ButtonLoader.defaultProps = defaultProps;

export const IconLoader = memo((size) => {
  return (
    <IconButton
      style={{
        margin: '0 4px',
        lineHeight: 1,
      }}
    >
      <CircularProgress style={{ marginBottom: -2, color: '#785ea8' }} size={size} />
    </IconButton>
  );
}, compareProps);

const defaultIconLoaderProps = {
  size: 20,
};
IconLoader.defaultProps = defaultIconLoaderProps;

export const PagesLoader = memo(() => (
  <div
    style={{
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <SpinnerLoading color="rgb(120, 94, 168)" title="Sobrus - Workspace" loading />
  </div>
));

export const ItemsLoader = memo(() => {
  return (
    <ContentLoader
      speed={3}
      width={100}
      height={16}
      viewBox="0 0 100 16"
      backgroundColor="#f3f3f3"
      foregroundColor="rgb(120, 94, 168,0.3)"
    >
      <rect x="0" y="0" rx="3" ry="3" width="100" height="16" />
    </ContentLoader>
  );
});

export const ImagesLoader = memo(() => {
  return (
    <ContentLoader
      speed={2}
      width={200}
      height={150}
      viewBox="0 0 200 150"
      backgroundColor="#f3f3f3"
      foregroundColor="#785ea8"
    >
      <rect x="0" y="0" rx="3" ry="3" width="200" height="150" />
    </ContentLoader>
  );
});

export const CardLoading = () => {
  return [1, 2, 3].map((index) => (
    <ContentLoader
      key={index}
      speed={2}
      width={800}
      height={78}
      viewBox="0 0 800 78"
      backgroundColor="#ffffff"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="16" ry="16" width="800" height="78" />
      <rect x="1" y="1" rx="16" ry="16" width="800" height="77" />
    </ContentLoader>
  ));
};
