import { AuthContext } from 'Context';
import { useContext } from 'react';
import { Redirect } from 'react-router-dom';

const FailureComponent = () => {
  const { error } = useContext(AuthContext);
  const verifyError = () => {
    if (error === 'code1' || error === 'code2' || error === 'code3') {
      localStorage.clear();
      return <Redirect to="/auth/login" />;
    }
    if (error === 'code5' || error === 'code6' || error === 'code7') {
      return (
        <Redirect
          to={{
            pathname: '/error',
            state: {
              message:
                'Votre compte Workspace semble être introuvable ou désactivé, si vous ignorez la raison de cette erreur vous pouvez contacter un administrateur',
              codeError: error,
            },
          }}
        />
      );
    }
    return <Redirect to="/auth/login" />;
  };

  return verifyError();
};

export default FailureComponent;
