import classNames from 'classnames';
import { mapToCssModules } from 'Components/utils';
// import PropTypes from 'prop-types';
import { RiDeleteBin6Line } from 'react-icons/ri';

export function TooltipMenuDelete(props) {
  const { className, iconSize, iconColor, label, cssModule, tag: Tag, ...attributes } = props;

  const classes = mapToCssModules(classNames('TooltipMenu__menu-item', className), cssModule);

  return (
    <Tag {...attributes} className={classes}>
      <RiDeleteBin6Line size={iconSize} color={iconColor} />{' '}
      <span className="TooltipMenu__menu-item-text">{label}</span>
    </Tag>
  );
}
// TooltipMenuDelete.propTypes = {
//   children: PropTypes.node,
//   inline: PropTypes.bool,
//   /** Pass in a Component to override default element */
//   tag: PropTypes.oneOfType([
//     PropTypes.func,
//     PropTypes.string,
//     PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
//     PropTypes.arrayOf(
//       PropTypes.oneOfType([
//         PropTypes.func,
//         PropTypes.string,
//         PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
//       ])
//     ),
//   ]),
//   color: PropTypes.string,
//   className: PropTypes.string,
//   cssModule: PropTypes.object,
//   iconColor: PropTypes.string,
//   iconSize: PropTypes.number,
//   label: PropTypes.string,
// };
TooltipMenuDelete.defaultProps = {
  tag: 'button',
  iconSize: 19,
  iconColor: '#00aed4',
  label: 'Supprimer',
};
