import { useMemo, createContext, useState } from 'react';

export function DataContextProvider({ children }) {
  const [optionsData, setOptionsData] = useState({});
  const [tableData, setTableData] = useState({});
  const value = useMemo(
    () => ({ optionsData, setOptionsData, tableData, setTableData }),
    [optionsData, setOptionsData, tableData, setTableData]
  );
  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}

export const DataContext = createContext();
