import { AnimatedModal, Backdrop, Card, Fade } from '@sobrus-com/sobrus-design-system';
import { CgClose } from 'react-icons/cg';

export function PopupContainer({ children, open, setOpen, setReset, close }) {
  return (
    <div style={{ width: '100%', height: 'fit-content' }} className="EventAddUpdate">
      <AnimatedModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="EventAddUpdateModal"
        onClose={() => {
          setOpen(false);
          setReset && setReset(false);
        }}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000,
        }}
      >
        <Fade in={open}>
          <Card className="card">
            {close && (
              <button
                onClick={() => {
                  setOpen(false);
                  setReset && setReset(false);
                }}
                className="sob-modal-header-close"
                type="button"
              >
                <CgClose size={20} color="#f05a29" />
              </button>
            )}
            {children}
          </Card>
        </Fade>
      </AnimatedModal>
    </div>
  );
}
