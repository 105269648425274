import { DataContext } from 'Context';
import { useAcl } from 'Hooks';
import { isEqual } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useParams } from 'react-router-dom';
import API from 'Services/API';
import { catchFunction } from 'Services/functionHelpers';

export const useGetOptions = (does, op, url, selectOptions, name = '') => {
  const { can } = useAcl();
  const [options, setOptions] = useState([]);
  const { optionsData, setOptionsData } = useContext(DataContext);

  const getData = async (signal, callback) => {
    try {
      const { data } = await API.get(`${url}`, {
        signal,
      });

      const opData = name
        ? data[name]?.map((d) => {
            return {
              value: d[selectOptions[0]],
              label: d[selectOptions[1]],
              ...d,
            };
          })
        : data?.map((d) => ({
            value: d[selectOptions[0]],
            label: d[selectOptions[1]],
            ...d,
          }));
      callback(opData);
      // // (opData);
    } catch (error) {
      catchFunction(error);
    }
  };
  const getOptions = useCallback(
    async (signal) => {
      if (can([does])) {
        unstable_batchedUpdates(() => {
          if (optionsData[op]?.isEmty === false) {
            setOptions(optionsData[op].data);
            getData(signal, (opData) => {
              if (!isEqual(opData, optionsData[op].data)) {
                setOptions(opData);
                setOptionsData((prev) => ({
                  ...prev,
                  [op]: { data: opData, isEmty: false },
                }));
              }
            });
          } else {
            getData(signal, (opData) => {
              setOptions(opData);
              setOptionsData((prev) => ({
                ...prev,
                [op]: { data: opData, isEmty: false },
              }));
            });
          }
        });
      }
    },

    [url, name]
  );
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    getOptions(signal);
    return () => controller.abort();
  }, [getOptions]);

  return useMemo(() => options, [options]);
};
export const usePostOptions = (does, op, url, selectOptions, name = '', formData = '') => {
  const { can } = useAcl();
  const [options, setOptions] = useState([]);
  const { optionsData, setOptionsData } = useContext(DataContext);

  const postData = async (signal, callback) => {
    try {
      const { data } = await API.post(`${url}`, formData, {
        signal,
      });

      const opData = name
        ? data[name]?.map((d) => {
            return {
              value: d[selectOptions[0]],
              label: d[selectOptions[1]],
              ...d,
            };
          })
        : data?.map((d) => ({
            value: d[selectOptions[0]],
            label: d[selectOptions[1]],
            ...d,
          }));
      callback(opData);
      // // (opData);
    } catch (error) {
      catchFunction(error);
    }
  };
  const getOptions = useCallback(
    async (signal) => {
      if (can([does])) {
        unstable_batchedUpdates(() => {
          if (optionsData[op]?.isEmty === false) {
            setOptions(optionsData[op].data);
            postData(signal, (opData) => {
              if (!isEqual(opData, optionsData[op].data)) {
                setOptions(opData);
                setOptionsData((prev) => ({
                  ...prev,
                  [op]: { data: opData, isEmty: false },
                }));
              }
            });
          } else {
            postData(signal, (opData) => {
              setOptions(opData);
              setOptionsData((prev) => ({
                ...prev,
                [op]: { data: opData, isEmty: false },
              }));
            });
          }
        });
      }
    },

    [url, name]
  );
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    getOptions(signal);
    return () => controller.abort();
  }, [getOptions]);

  return useMemo(() => options, [options]);
};

export const useDragOptions = (url) => {
  const { id } = useParams();
  const [options, setOptions] = useState([]);
  const [, setLoading] = useState(false);
  const getOptions = useCallback(async () => {
    if (id) {
      try {
        const { data } = await API.get(`${url}`);
        const test = data.articlesArrangement.sort((a, b) => a.disposition - b.disposition);
        unstable_batchedUpdates(() => {
          setOptions(
            test?.map((d) => ({
              id: d?.contractArticleModel?.id,
              name: d?.contractArticleModel?.name,
            }))
          );
          setLoading(false);
        });
      } catch (error) {
        catchFunction(error, setLoading);
      }
    }
  }, [url]);
  useEffect(() => {
    getOptions();
  }, [getOptions]);

  return options;
};
