/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-fallthrough */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unstable-nested-components */
import React, { memo, useEffect, useRef } from 'react';
import classNames from 'classnames';
import RUG, { DragArea, DropArea } from 'react-upload-gallery';
import { CgClose } from 'react-icons/cg';
import addImage from 'Assets/add_image.png';
import { compareProps } from 'Services/functionHelpers';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { mapToCssModules } from '../../utils';
// Add style manually
import './reactUploadGallery.css'; // or scss
// import '../communStyle.css';
import './style.css';

// const propTypes = {
//   children: PropTypes.node,
//   horizontal: PropTypes.bool,
//   className: PropTypes.string,
//   cssModule: PropTypes.object,
//   onRemove: PropTypes.func,
//   onUpdate: PropTypes.func,
//   desc: PropTypes.string,
//   hideDeleteBtn: PropTypes.bool,
//   hideDeleteBtn: PropTypes.bool,
//   hideUpdateBtn: PropTypes.bool,
// };
function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
const defaultProps = {
  horizontal: false,
  desc: 'Sélectionnez ou Glissez-déposez votre logo ici ..',
};

function MultiImagePicker(props) {
  const {
    img,
    uids,
    className,
    cssModule,
    horizontal,
    onRemove,
    onWarning,
    photoIds,
    desc,
    hideDeleteBtn,
    imagesData,
    setImagesData,
    initialState,
    callbackChange,
    loading,
    progress,
    not,
    tag: Tag,
    ...attributes
  } = props;

  const classes = mapToCssModules(
    classNames(
      'sob-multi-image-picker',
      horizontal ? 'sob-multi-image-picker-direction-horizontal' : 'sob-multi-image-picker-direction-vertical',
      className
    ),
    cssModule
  );
  // const customOpenDialogue = null;
  // const [initialStateProvided, setInitialStateProvided] = useState(initialState ? true : false)
  // useEffect(() => {
  //     setInitialStateProvided(true);
  // }, [initialState]);
  // const getImageUid = (uid) => {
  //   return imagesData.filter((item, index) => item.uid === uid)[0];
  // };
  return (
    <RUG
      sorting
      helperClass="sorting"
      autoUpload={false}
      className={classes}
      onWarning={onWarning}
      header={({ openDialogue }) => {
        // customOpenDialogue = openDialogue;
        return (
          <DropArea>
            {(isDrag) => (
              <button
                type="button"
                onClick={!loading ? openDialogue : () => {}}
                className={`sob-multi-image-picker-header ${isDrag ? 'sob-multi-image-is-drag' : ''}`}
              >
                {loading ? (
                  <div className="galerie__loading">
                    <CircularProgressWithLabel
                      variant="determinate"
                      value={typeof progress === 'number' ? progress : 0}
                      size={70}
                    />
                    Uploading images … {`${photoIds?.length}/${imagesData?.length}`}
                  </div>
                ) : (
                  <>
                    {img ?? <img className="sob-multi-image-picker-header-icon" src={addImage} alt="add icon" />}
                    <span className="sob-multi-image-picker-header-desc">{desc}</span>
                  </>
                )}
              </button>
            )}
          </DropArea>
        );
      }}
      initialState={initialState}
      onChange={(images) => {
        let formattedImages = [];
        formattedImages = not
          ? images?.filter((el) => !uids?.includes(el?.uid))
          : [
              ...formattedImages,
              ...images.map((item, index) => {
                let label = '';
                let attribute = '';
                let thirdSelect = '';
                // check the item has label in initialState
                if (item?.label && item?.label?.length !== 0) {
                  label = item?.label;
                }
                // check if item has attribute in initialState
                if (item?.attribute && item?.attribute?.length !== 0) {
                  attribute = item?.attribute;
                }
                // check if item has thirdSelect in initialState
                if (item?.thirdSelect && item?.thirdSelect?.length !== 0) {
                  thirdSelect = item?.thirdSelect;
                }
                const searchedItem = imagesData.filter((item2, index2) => item2?.uid === item?.uid);
                if (searchedItem.length !== 0) {
                  label = searchedItem[0].label;
                  attribute = searchedItem[0].attribute;
                  thirdSelect = searchedItem[0].thirdSelect;
                }
                return { ...item, label, attribute, thirdSelect };
              }),
            ];

        setImagesData([...formattedImages]);
        callbackChange(formattedImages);
      }}
      {...attributes}
    >
      <DropArea>
        {(isDrag) => {
          return (
            <DragArea className="rug-items __card __sorting">
              {(image) => {
                return (
                  <div
                    key={image.uid}
                    className="rug-item"
                    style={{ margin: 10, border: isDrag ? '1px solid red' : '' }}
                  >
                    <div className="rug-card" style={{ margin: 0 }}>
                      <div className="rug-card-name">
                        <div>
                          {image.name}
                          <div className="rug-card-size">{image.size}</div>
                        </div>
                      </div>
                      <div className="rug-card-image" style={{ backgroundImage: `url(${image.source})` }} />
                      {!loading && !hideDeleteBtn ? (
                        <button
                          type="button"
                          onClick={() => {
                            setImagesData(imagesData.filter((item, index) => item.uid !== image.uid));
                            if (onRemove) onRemove(image);
                            if (image.remove) image.remove(image.uid);
                          }}
                          className="card-image-action-delete"
                        >
                          <CgClose size={15} color="#fff" />
                        </button>
                      ) : (
                        ''
                      )}
                    </div>
                    {/* {inputs && (
                      <>
                        <Input
                          id={`Input__${image.uid}`}
                          defaultValue={getImageUid(image.uid)?.label}
                          onChange={(e) => {
                            // setImageName(e.target.value)
                            const imagesDataTmp = imagesData;
                            const updatedFileIndex = imagesDataTmp.findIndex((item, index) => item.uid === image.uid);
                            const updatedFile = { ...imagesDataTmp[updatedFileIndex], label: e.target.value };
                            imagesDataTmp[updatedFileIndex] = updatedFile;
                            setImagesData(imagesDataTmp);
                          }}
                          type="text"
                          placeholder="Donnez nom à cette image .."
                          style={{ marginBottom: 15, marginTop: 15 }}
                          {...inputProps}
                        />
                        <Input
                          onChange={(e) => {
                            // setImageName(e.target.value)
                            const imagesDataTmp = imagesData;
                            const updatedFileIndex = imagesDataTmp.findIndex((item, index) => item.uid === image.uid);
                            const updatedFile = { ...imagesDataTmp[updatedFileIndex], attribute: e.target.value };
                            imagesDataTmp[updatedFileIndex] = updatedFile;
                            setImagesData(imagesDataTmp);
                          }}
                          value={getImageUid(image.uid)?.attribute}
                          type="select"
                          style={{ marginRight: 0, marginBottom: 15 }}
                          {...selectProps}
                        >
                          <option value="" defaultValue>
                            {selectProps && selectProps.placeholder && selectProps.placeholder.length !== 0
                              ? selectProps.placeholder
                              : 'Sélectionner attribut'}
                          </option>
                          {selectOptions.map((item, index) => (
                            <option value={item?.value}>{item?.label}</option>
                          ))}
                        </Input>
                        <Input
                          onChange={(e) => {
                            // setImageName(e.target.value)
                            const imagesDataTmp = imagesData;
                            const updatedFileIndex = imagesDataTmp.findIndex((item, index) => item.uid === image.uid);
                            const updatedFile = { ...imagesDataTmp[updatedFileIndex], thirdSelect: e.target.value };
                            imagesDataTmp[updatedFileIndex] = updatedFile;
                            setImagesData(imagesDataTmp);
                          }}
                          value={getImageUid(image.uid)?.thirdSelect}
                          type="select"
                          style={{ marginRight: 0 }}
                          {...selectProps}
                        >
                          <option value="" defaultValue>
                            {selectProps && selectProps.placeholder && selectProps.placeholder.length !== 0
                              ? selectProps.placeholder
                              : ''}
                          </option>
                          {thirdSelectOptions.map((item, index) => (
                            <option value={item?.value}>{item?.label}</option>
                          ))}
                        </Input>
                      </>
                    )} */}
                  </div>
                );
              }}
            </DragArea>
          );
        }}
      </DropArea>
    </RUG>
  );
}
const Circle = memo(({ progress }) => {
  const circularRef = useRef(null);
  const valueRef = useRef(null);
  useEffect(() => {
    valueRef.current.textContent = `${progress}%`;
    circularRef.current.style.background = `conic-gradient(${'#1BBAAC'} ${+progress * 3.6}deg, ${'#EBEBEB'} ${
      +progress * 3.6
    }deg)`;
  }, [progress]);
  return (
    <div className="circularProgress" ref={circularRef}>
      <div className="values__statistics" ref={valueRef} />
    </div>
  );
}, compareProps);
// MultiImagePicker.propTypes = propTypes;
MultiImagePicker.defaultProps = defaultProps;

export { MultiImagePicker };
