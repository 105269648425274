export const MESSAGES = {
  HOME: {
    REGISTER_TIME: 'Votre pointage a été bien pris en compte ',
    ADD_POST: 'Votre publication a été ajoutée avec succès ',
    UPDATE_POST: 'Votre publication a été modifiée avec succès ',
    DELETE_POST: 'Votre publication a été supprimée avec succès ',
  },
  COLLABORATERS: {
    DELETE_COLLABORATERS: 'Le collaborateur a été supprimé avec succès ',
    UPDATE_COLLABORATERS: 'Le collaborateur  a été modifié avec succès ',
    ADD_COLLABORATERS: 'Le collaborateur a été ajouté avec succès ',
    UPDATE_PHONEBOOK: "Les paramètres d'annuaire ont été modifiés avec succès ",
    UPDATE_ACCOUNT_PARAMS: 'Les paramètres du compte ont été modifiés avec succès ',
    ADD_PAYRISES: 'L’augmentation de salaire a été ajoutée avec succès ',
    UPDATE_PAYRISES: 'L’augmentation de salaire a été modifié avec succès ',
    DELETE_PAYRISES: 'L’augmentation de salaire a été annulée avec succès ',
    ADD_CONTRAT: 'Le contrat a été généré avec succès ',
    ADD_INTERVIEW: 'L’entretien a été ajouté avec succès ',
    UPDATE_HORAIRES: 'Les Horaires ont été modifiés avec succès ',
    DEFAULT_HORAIRE: 'Les horaires ont été par défaut appliqués ',
  },
  INTERVIEWS: {
    UPDATE: 'L’entretien a été modifié avec succès ',
    DELETE: 'L’entretien a été annulé avec succès ',
  },
  ACTIVITES: {
    UPDATE: 'L’activité a été modifiée avec succès',
    ADD: 'L’activite a été ajouter avec succès ',
  },
  GALERIE: {
    UPDATE: 'L’album a été modifié avec succès',
    ADD: 'L’album a été ajouté avec succès',
  },
  DEMANDES: {
    VALIDATION: 'La demande a été validée avec succès ',
    REFUS: 'La demande a été refusée avec succès ',
    ADD: 'Votre demande est créée avec succès ',
    UPDATE: 'Votre demande est modifiée avec succès ',
  },
  ADD_CONTRAT: 'Le contrat a été généré avec succès ',
  HARDWARES: {
    ADD: 'Le matériel a été créé avec succès ',
    UPDATE: 'Le matériel a été modifié avec succès ',
    RESTITUTE: 'Le matériel a été restitué avec succès ',
    ASSIGN: 'Le matériel a été affecté avec succès ',
  },
  SOFTWARES: {
    ADD: 'Le logiciel a été créé avec succès ',
    UPDATE: 'Le logiciel a été modifié avec succès ',
    RESTITUTE: 'Le logiciel a été restitué avec succès ',
    ASSIGN: 'Le logiciel a été affecté avec succès ',
  },
  MISSIONS: {
    ADD: 'La mission  a été créée avec succès ',
    UPDATE: 'La mission a été modifiée avec succès ',
    DELETE: 'La mission a été annulée avec succès ',
  },
  TRANSACTIONS: {
    EXPENSES_ADD: 'La dépense a été créée avec succès ',
    EXPENSES_DELETE: 'La dépense a été annulée avec succès ',
    REGHARGE_ADD: 'La recharge  a été créée avec succès ',
    REGHARGE_DELETE: 'La recharge a été annulée avec succès ',
  },
  CANDIDATE: {
    INTERWIEWS_ADD: 'L’entretien a été créé avec succès ',
    INTERWIEWS_DELETE: 'L’entretien a été annulé avec succès ',
    INTERWIEWS_UPDATE: 'L’entretien a été modifié avec succès ',
    CHANGE_STATUS: 'Le statut du candidat a été modifié avec succès ',
  },
  RECRUTMENTREQUESTS: {
    ADD: 'L’expression de besoin a été créé avec succès ',
    UPDATE: 'L’expression de besoin a été modifié avec succès ',
    ARCHIVED: 'L’expression de besoin a été archivée avec succès  ',
    RESTORED: 'L’expression de besoin a été restorée avec succès  ',
  },
  ABSENCESLEAVES: {
    DELETE_CONSUMED_LEAVES: 'Le congé a été annulé avec succès ',
    DELETE_SPECIAL_LEAVES: 'Le congé a été annulé avec succès ',
    DELETE_CONSUMED_LEAVES_CONFIRMATION: 'Voulezvous vraiment annuler ce congé signé ? ',
    ADD_SPECIAL_LEAVE: 'Le congé spécial a été créé avec succès ',
    SIGNED: 'La demande a été signée avec succès ',
    VALIDATED: 'La demande a été acceptée ',
    DENIED_ADMIN: 'La demande a été refusée par admin avec succès ',
    DENIED_MANAGER: 'La demande a été  refusée par manager avec succès ',
    ANNULER: 'la demande a été annulée avec succès ',
    ADD__LEAVE: ' Le congé a été créé avec succès ',
    RECALCULATE: 'Les absences du mois ont été recalculés avec succès',
    STATUS: 'Le statut d’absence a été modifié avec succès',
  },
  CALENDAR: {
    REPPORTED: 'L’événement a été reporté avec succès ',
    ACHEVER: 'L’evénement a été achevé  avec succès ',
    ASSIGNER: 'L’événement a été assigné  avec succès ',
    ANNULER: 'L’événement a été annulé  avec succès ',
    CONFIRMER: 'L’événement a été confirmé avec succès ',
    REPORTER: 'L’événement a été  reporté avec succès ',
    WARNING: 'voir le message avec nissrine ',
  },
  SETTINGS: {
    JOB_ADD: 'Le poste a été ajouté avec succès ',
    JOB_UPDATE: 'Le poste a été modifié avec succès ',
    JOB_DELETE: 'Le poste a été supprimé avec succès ',
    DEPARTEMENT_ADD: 'Le département a été ajouté avec succès ',
    DEPARTEMENT_UPDATE: 'Le département a été modifié avec succès ',
    DEPARTEMENT_DELETE: 'Le département a été supprimé avec succès ',
    DEMANDES_ADD: "L'objet des demandes a été ajouté avec succès ",
    DEMANDES_UPDATE: "L'objet des demandes a été modifié avec succès ",
    DEMANDES_DELETE: "L'objet des demandes a été supprimé avec succès ",
    MODEL_ARTICLE_ADD: "Le modèle d'article des contrats a été ajouté avec succès ",
    MODEL_ARTICLE_UPDATE: "Le modèle d'article des contrats a été modifié avec succès ",
    MODEL_ARTICLE_DELETE: "Le modèle d'article des contrats a été supprimé avec succès ",
    MODEL_CONTRACT_ADD: 'Le modèle de contrat a été ajouté avec succès ',
    MODEL_CONTRACT_UPDATE: 'Le modèle de contrat a été modifié avec succès ',
    MODEL_CONTRACT_DELETE: 'Le modèle de contrat a été supprimé avec succès ',
    SKILLS_ADD: 'La compétence a été ajoutée avec succès ',
    SKILLS_UPDATE: 'La compétence a été modifiée avec succès ',
    SKILLS_DELETE: 'La compétence a été supprimée avec succès ',
    HARDWARS_ADD: 'Le matériel a été ajouté avec succès ',
    HARDWARS_UPDATE: 'Le matériel a été modifié avec succès ',
    HARDWARS_DELETE: 'Le matériel  a été supprimée avec succès ',
    SOFTWARE_ADD: 'Le logiciel a été ajouté avec succès ',
    SOFTWARE_UPDATE: 'Le logiciel a été modifié avec succès ',
    SOFTWARE_DELETE: 'Le logiciel a été supprimée avec succès ',
    LOCATIONS_ADD: 'La position a été ajoutée avec succès ',
    LOCATIONS_UPDATE: 'La position a été modifiée avec succès ',
    LOCATIONS_DELETE: 'La position a été suppriméee avec succès ',
    COMPANYPARAMS_UPDATE: "Les paramètres de l'entreprise ont été modifiés avec succès ",
    ROLES_ADD: 'Le rôle a été ajouté avec succès ',
    ROLES_UPDATE: 'Le rôle a été modifié avec succès ',
    HOLIDAYS_ADD: 'Le jour fériés a été ajouté avec succès ',
    HOLIDAYS_UPDATE: 'Le jour fériés a été modifié avec succès ',
    HOLIDAYS_DELETE: 'Le jour fériés a été supprimé avec succès ',
    EXPENSES_ADD: 'Le type des dépenses a été ajouté avec succès ',
    EXPENSES_UPDATE: 'Le type des dépenses a été modifié avec succès ',
    EXPENSES_DELETE: 'Le type des dépenses a été supprimé avec succès ',
    RECHARGES_ADD: 'Le type des recharges a été ajouté avec succès ',
    RECHARGES_UPDATE: 'Le type des recharges a été modifié avec succès ',
    RECHARGES_DELETE: 'Le type des recharges a été supprimé avec succès ',
    COEFFICIENT_UPDATE: 'Le coefficient de kilométrique a été modifié avec succès ',
    SPECIAL_TYPE_ADD: 'Le congé spécial a été ajouté avec succès ',
    SPECIAL_TYPE_UPDATE: 'Le congé spécial a été modifié avec succès ',
    SPECIAL_TYPE_DELETE: 'Le congé spécial a été supprimé avec succès ',
    ANNUAL_LEAVES_UPDATE: 'Les paramètres par défaut du congé ont été modifiés avec succès ',
    EMAIL_UPDATE: ' Le modèle de réponse  a été modifié avec succès ',
    TEAM_ADD: 'L’équipe a été ajoutée avec succès ',
    TEAM_UPDATE: 'L’équipe a été modifiée avec succès ',
    TEAM_DELETE: 'L’équipe a été supprimée avec succès ',
    EVENT_ADD: 'Le type d’événements a été ajouté avec succès ',
    EVENT_UPDATE: 'Le type d’événements a été modifié avec succès ',
    EVENT_DELETE: 'Le type d’événements a été supprimé avec succès ',
  },
  NOTIFICATION: {
    VU: 'La notification a été marquée comme lue avec succès ',
  },
  WARNING: {
    EVENT_AFFECT: "Le collaborateur n'appartient pas à l'équipe de l'événement",
    EVENT_REPORT: {
      PART1: 'Un événement',
      PART2: 'Ne peut pas être reporter',
    },
    TRANSACTION_EMPTY: "Aucune transaction n'est disponible",
    DELETE: 'Impossible de supprimer un élément déjà utilisé',
  },
};
