import { Fade } from '@sobrus-com/sobrus-design-system';
import { memo } from 'react';
import { compareProps } from 'Services/functionHelpers';

export const AnimatedTr = memo(({ index, children, noAnimation, ...props }) => {
  if (noAnimation) {
    return <tr {...props}>{children}</tr>;
  }
  return (
    <Fade in style={{ transitionDelay: `${20 * index}ms` }}>
      <tr {...props}>{children}</tr>
    </Fade>
  );
}, compareProps);
AnimatedTr.defaultProps = {
  noAnimation: false,
};
