import { useEffect, useState, useCallback } from 'react';

// import { useHistory } from "react-router";
import API from 'Services/SobrusAPI';

export const useGet = (url, name, queryState = {}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const FetchGet = useCallback(async () => {
    try {
      setData([]);
      setLoading(true);
      const res = await API.get(`${url}`, { params: queryState });
      setData(res?.data[name] || res?.data);
      setLoading(false);
    } catch (_error) {
      // (error);
    }
  }, [url, name, queryState]);
  useEffect(() => {
    FetchGet();
  }, [FetchGet]);
  return {
    data,
    setData,
    loading,
    setLoading,
    error,
    setError,
  };
};
