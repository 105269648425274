import { notify } from '@sobrus-com/sobrus-design-system';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useNotify = (message) => {
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (history?.action === 'PUSH')
      if (location?.state?.detail === 'success') {
        notify({
          type: 'success',
          msg: message || location.state.message,
          delay: 5000,
        });
      }
  }, [location, history]);
};
