import '@draft-js-plugins/mention/lib/plugin.css';
import 'App.scss';
import { AuthContext, DataContextProvider } from 'Context';
import { useAuth } from 'Hooks';
import MainNavigator from 'Navigation/MainNavigator';
import { useLayoutEffect, useMemo } from 'react';
import './communStyle.css';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { REACT_APP_ENV } from './env';

if (REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: 'https://30e9e9c5f4d449c685f1f0e29ef993d9@o1417707.ingest.sentry.io/6760323',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
console.log('vercel v 3');
function App() {
  const { user, getUser, error, setError, loading, logout, IP, setUser } = useAuth();
  useLayoutEffect(() => {
    getUser();
  }, [getUser]);
  const value = useMemo(
    () => ({ user, getUser, setError, error, loading, logout, IP, setUser }),
    [user, getUser, setError, error, loading, logout, IP, setUser]
  );
  return (
    <AuthContext.Provider value={value}>
      <DataContextProvider>
        <MainNavigator />
      </DataContextProvider>
    </AuthContext.Provider>
  );
}

export default App;
