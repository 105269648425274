import React, { useContext, useState } from 'react';
import { AuthContext } from 'Context';
import { BASE_URL } from 'env';
import moment from 'Services/moment';
import { CustomInput, TooltipMenuDelete } from 'Components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Avatar from 'react-avatar';
import { RiMore2Fill } from 'react-icons/ri';
import { TooltipMenu, TooltipMenuEdit } from '@sobrus-com/sobrus-design-system';
import { useAcl } from 'Hooks';
import { PAGES } from 'Values/acl';

function SubComments({
  openResponses,
  comment,
  post,
  postSubComment,
  setDisplayCommentLike,
  displayCommentLike,
  likeSubComment,
  deleteSubComment,
  updateSubComment,
}) {
  const { user } = useContext(AuthContext);
  const { values, handleSubmit, setFieldValue, handleChange, handleBlur, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      content: '',
      postId: post?.id,
      repliedToCommentId: comment?.id,
    },
    validationSchema: Yup.object({
      content: Yup.string().trim().required(''),
    }),
    onSubmit: (submitValues) => {
      postSubComment(submitValues, comment, setFieldValue);
    },
  });

  return (
    <>
      {' '}
      {openResponses === comment?.id &&
        comment?.replies?.map((replie) => (
          <Test
            {...{
              replie,
              post,
              likeSubComment,
              comment,
              setDisplayCommentLike,
              displayCommentLike,
              deleteSubComment,
              updateSubComment,
            }}
          />
        ))}
      {openResponses === comment?.id && (
        <div className="sub_comment_container">
          <div className="sub_comment_container_image_container">
            {user?.photo ? (
              <img
                style={{ height: 40, width: 40, borderRadius: 8 }}
                src={`${BASE_URL}/images/collaborators/${user?.photo}`}
                alt=""
              />
            ) : (
              <Avatar name={`${user?.firstName} ${user?.lastName}`} size="40" round color="#785ea8" />
            )}
          </div>
          <form style={{ width: '100%' }} onSubmit={handleSubmit}>
            <CustomInput
              style={{ height: 42 }}
              label
              value={values.content}
              handleBlur={handleBlur}
              handleChange={handleChange}
              hasError={errors.content}
              isTouched={touched.content}
              name="content"
              placeholder="Insérer votre commentaire ici …"
            />
          </form>
        </div>
      )}
    </>
  );
}

function Test({
  replie,
  post,
  likeSubComment,
  comment,
  setDisplayCommentLike,
  displayCommentLike,
  deleteSubComment,
  updateSubComment,
}) {
  const { can } = useAcl();
  const { user } = useContext(AuthContext);
  const [updateDisplaySubComment, setUpdateDisplaySubComment] = useState(false);
  const [initialValues, setInitialValues] = useState({
    content: '',
    postId: post?.id,
    repliedToCommentId: comment?.id,
  });
  const { values, handleSubmit, setFieldValue, handleChange, handleBlur, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      content: Yup.string().trim().required(''),
    }),
    onSubmit: (submitValues) => {
      updateSubComment(submitValues, comment, replie, setFieldValue, setUpdateDisplaySubComment);
    },
  });
  return (
    <div className="sub_comment_container">
      <div className="sub_comment_container_image_container">
        {user?.photo ? (
          <img
            style={{ height: 40, width: 40, borderRadius: 8 }}
            src={`${BASE_URL}/images/collaborators/${replie?.createdBy?.photo}`}
            alt=""
          />
        ) : (
          <Avatar
            name={`${replie?.createdBy?.firstName} ${replie?.createdBy?.lastName}`}
            size="40"
            round
            color="#785ea8"
          />
        )}
      </div>
      {updateDisplaySubComment === replie?.id ? (
        <form style={{ width: '100%' }} onSubmit={handleSubmit}>
          <CustomInput
            style={{ height: 42 }}
            label
            value={values.content}
            handleBlur={handleBlur}
            handleChange={handleChange}
            hasError={errors.content}
            isTouched={touched.content}
            name="content"
            placeholder="Insérer votre commentaire ici …"
          />
        </form>
      ) : (
        <>
          <div style={{ flex: 1 }}>
            <div className="card_content_comment_content_container">
              <p className="commented_name">{`${replie?.createdBy?.firstName} ${replie?.createdBy?.lastName}`}</p>
              <p className="comment_content">{replie?.content}</p>
            </div>
            <div className="card_content_comment_content_container">
              <p className="coment_and_like">
                <span
                  style={{
                    cursor: 'pointer',
                    color: replie.reactions.some((reaction) => reaction.collaborator.id === user?.id)
                      ? '#785ea8'
                      : '#000',
                    fontWeight: replie.reactions.some((reaction) => reaction.collaborator.id === user?.id) ? 600 : 400,
                  }}
                  onClick={() => {
                    likeSubComment(replie, comment);
                  }}
                  aria-hidden="true"
                >
                  J’aime
                </span>
              </p>
              <p className="coment_and_like_timing">
                {moment(replie?.createdAt).fromNow()} &bull;{' '}
                <span
                  style={{
                    cursor: replie?.reactions?.length > 0 ? 'pointer' : 'auto',
                  }}
                  onClick={() => {
                    replie?.reactions?.length > 0 && setDisplayCommentLike(displayCommentLike ? false : replie);
                  }}
                  aria-hidden="true"
                >
                  {replie?.reactions?.length ?? '0'}
                  {` J’aime  ${replie?.reactions?.length > 1 ? 's' : ''}`}
                </span>
              </p>
            </div>
          </div>
          <div>
            {can(PAGES.HOME.COMMENT__DELETEORUPDATE) && (user?.isAdmin || user?.id === replie?.createdBy?.id) && (
              <TooltipMenu
                trigger={
                  <button type="button" className="TooltipMenu__actions-trigger-btn">
                    {' '}
                    <RiMore2Fill size={19} color="#785ea8" />
                  </button>
                }
              >
                {(user?.isAdmin || user?.id === replie?.createdBy?.id) && can(PAGES.HOME.COMMENT__DELETE) && (
                  <TooltipMenuDelete
                    onClick={() => {
                      deleteSubComment(replie, comment);
                    }}
                  />
                )}
                {user?.id === replie?.createdBy?.id && can(PAGES.HOME.COMMENT__UPDATE) && (
                  <TooltipMenuEdit
                    onClick={() => {
                      setUpdateDisplaySubComment(!updateDisplaySubComment ? replie?.id : false);
                      setInitialValues((prev) => ({
                        ...prev,
                        content: replie?.content,
                      }));
                    }}
                  />
                )}
              </TooltipMenu>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default SubComments;
