import { FormFeedback, FormGroup, InputSelect, Label } from '@sobrus-com/sobrus-design-system';
import { memo } from 'react';

import { compareProps } from 'Services/functionHelpers';

//  const  compareProps = (prevProps, nextProps) => {
//   return isEqual(prevProps.name, nextProps.name)
// }

export const CustomInputSelect = memo((props) => {
  return (
    <FormGroup>
      {!props.label && <Label htmlFor={props.name}>{props.placeholder}</Label>}
      <InputSelect
        menuIsOpen={props.menuIsOpen}
        autoComplete="off"
        name={props.name}
        id={props.name}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        placeholder={props.placeholder}
        value={props.value}
        isClearable={props.isClearable}
        options={props.options}
        isMulti={props.isMulti}
        invalid={props.hasError && props.isTouched}
        styles={{
          option: (provided, state) => {
            // //(state.isSelected)
            return {
              ...provided,
              backgroundColor: state.isSelected ? '#785ea8' : '#fff',
              color: !state.isSelected ? '#000' : '#FFF',
              fontFamily: 'Poppins, sans-serif',
              fontWeight: '300',
              fontSize: 14,
              ':hover': {
                color: state.isSelected && '#fff',
                backgroundColor: state.isSelected ? '#785ea8' : '#785ea8c1',
              },
            };
          },
        }}
      />
      {props.hasError && props.isTouched ? <FormFeedback invalid="true">{props.hasError}</FormFeedback> : null}
    </FormGroup>
  );
}, compareProps);

export function CustomSearchInputSelect(props) {
  const {
    menuIsOpen,
    name,
    handleChange,
    handleBlur,
    placeholder,
    value,
    isClearable,
    options,
    isMulti,
    hasError,
    isTouched,
    openMenuOnClick,
  } = props;
  return (
    <InputSelect
      isClearable={isClearable}
      openMenuOnClick={openMenuOnClick}
      menuIsOpen={menuIsOpen}
      autoComplete="off"
      name={name}
      id={name}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder}
      value={value}
      options={options}
      isMulti={isMulti}
      invalid={hasError && isTouched}
      styles={{
        option: (provided, state) => {
          // //(state.isSelected)
          return {
            ...provided,
            backgroundColor: state.isSelected ? '#785ea8' : '#fff',
            color: !state.isSelected ? '#000' : '#FFF',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: '300',
            fontSize: 14,
            ':hover': {
              color: '#fff',
              backgroundColor: state.isSelected ? '#785ea8' : 'rgba(120, 94, 168,.6)',
            },
          };
        },
      }}
    />
  );
}

const defaultProps = {
  validations: false,
  isClearable: false,
  name: 'searchSelect',
  className: 'basic-select',
  classNamePrefix: 'basic-select',
  placeholder: 'Sélectionner',
  noOptionsMessage: 'Non résultat',
  isMulti: false,
};
const searchDefaultProps = {
  name: 'searchSelect',
  className: 'basic-select',
  classNamePrefix: 'basic-select',
  placeholder: 'Sélectionner',
  noOptionsMessage: 'Non résultat',
  isMulti: false,
  isClearable: true,
};

CustomInputSelect.defaultProps = defaultProps;

CustomSearchInputSelect.defaultProps = searchDefaultProps;
