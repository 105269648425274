import { notify } from '@sobrus-com/sobrus-design-system';
import { AuthContext } from 'Context';
import { useAcl } from 'Hooks';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import API from 'Services/API';
import { catchFunction } from 'Services/functionHelpers';
import { MESSAGES } from 'Values/CONST';

export const useRequests = (data, setData, loading) => {
  const ACLS = {
    REVIEW_MANAGER: ['review_demand_as_manager'],
    REVIEW_ADMIN: ['review_demand_as_admin'],
  };
  const { can } = useAcl();
  const [validateLoading, setValidateLoading] = useState(false);
  const [openValidate, setOpenValidate] = useState(false);

  const { user } = useContext(AuthContext);
  const [newData, setNewData] = useState([]);
  useLayoutEffect(() => setNewData(data), [data]);
  useEffect(() => {
    if (!loading && data.length > 0) {
      setNewData(
        data?.map((d) => {
          if (
            can(ACLS.REVIEW_ADMIN) &&
            d?.adminStatus === 'Waiting' &&
            d?.managerStatus === 'Waiting' &&
            d?.createdBy?.manager?.id === user?.id
          ) {
            d.status = 'adminAndManager';
            return d;
          }
          if (can(ACLS.REVIEW_MANAGER) && d?.managerStatus === 'Waiting' && d?.createdBy?.manager?.id === user?.id) {
            d.status = 'manager';
            return d;
          }
          if (can(ACLS.REVIEW_MANAGER) && d?.adminStatus === 'Waiting') {
            d.status = 'admin';
            return d;
          }

          return d;
        })
      );
    }
  }, [data, loading, user, can]);
  const handelValidate = async (currentRequest) => {
    const validationData =
      currentRequest.status === 'adminAndManager'
        ? { adminStatus: 'Validated', managerStatus: 'Validated' }
        : currentRequest.status === 'admin'
        ? { adminStatus: 'Validated' }
        : { managerStatus: 'Validated' };
    const review =
      currentRequest.status === 'admin' || currentRequest.status === 'adminAndManager'
        ? 'admin_review'
        : 'manager_review';
    unstable_batchedUpdates(async () => {
      try {
        setValidateLoading(true);
        await API.patch(`/collaboraters/${user?.id}/demands/${currentRequest.id}/${review}`, validationData);
        setOpenValidate(false);
        setValidateLoading(false);
        setData(
          data.map((el) => {
            if (el.id === currentRequest.id) {
              if (currentRequest.status === 'adminAndManager') {
                el.adminStatus = 'Validated';
                el.managerStatus = 'Validated';
              } else if (currentRequest.status === 'admin') {
                el.adminStatus = 'Validated';
              } else {
                el.managerStatus = 'Validated';
              }
              el.status = '';
            }
            return el;
          })
        );
        notify({
          type: 'success',
          msg: MESSAGES.DEMANDES.VALIDATION,
          delay: 5000,
        });
      } catch (error) {
        catchFunction(error, setValidateLoading);
      }
    });
  };
  return {
    newData,
    handelValidate,
    openValidate,
    setOpenValidate,
    validateLoading,
  };
};
