import axios from 'axios';
import { EXT } from 'env';
import { useCallback, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import API from 'Services/API';

export const useAuth = () => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [IP, setIP] = useState('');
  const getUser = useCallback(async () => {
    try {
      const { data } = await API.post('/collaboraters/authenticated-collaborator');
      const res = await API.get('/get-my-ip-address');
      unstable_batchedUpdates(() => {
        setIP(res?.data?.ip);
        setUser(data);
        setLoading(false);
      });
    } catch (err) {
      unstable_batchedUpdates(() => {
        setError(err?.response?.data?.message);
        setLoading(false);
      });
    }
  }, []);

  const logout = async () => {
    try {
      setLoading(true);
      await axios.get(`https://api.workspace.sobrus.${EXT}/auth/revoke`, {
        withCredentials: true,
      });
      setLoading(false);
      setError('code1');
      localStorage.clear();
    } catch (_err) {
      setLoading(false);
    }
  };

  return { user, getUser, error, setError, loading, logout, IP, setUser };
};
