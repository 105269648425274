import moment from 'moment';

export function EventComponent({ data }) {
  return (
    <div className="EventComponent__container">
      <div className="EventComponent__col-1">
        <span className="EventComponent__col-1-time">
          {data && data.start ? moment(data.start).format('HH:mm') : ''}
          <br />
          -<br />
          {data && data.end ? moment(data.end).format('HH:mm') : ''}
        </span>
        <span className="EventComponent__separator" />
      </div>
      <div className="EventComponent__col-2">
        <span className="EventComponent__col-2-desc">{data && data.desc ? data.desc : ''}</span>
        <h4 className="EventComponent__col-2-title">{data && data.title ? data.title : ''}</h4>
      </div>
    </div>
  );
}
