import Iconacl from 'Assets/paramsIcons/acl.svg';
import IconcontratParam from 'Assets/paramsIcons/contratParam.svg';
import Icondemandes from 'Assets/paramsIcons/demandes.svg';
import Iconemail from 'Assets/paramsIcons/email.svg';
import Iconmaterial from 'Assets/paramsIcons/material.svg';
import IconParams from 'Assets/paramsIcons/params.svg';
import Iconpointage from 'Assets/paramsIcons/pointage.svg';
import IconPosts from 'Assets/paramsIcons/posts.svg';
import IconEntities from 'Assets/paramsIcons/entities.svg';

export const settingsAccordion = [
  {
    SettingsAccordionItemHeading: 'Postes et Départements',
    can: ['get_jobs', 'get_departments'],
    path: ['/settings/jobs', '/settings/departments'],
    icon: IconPosts,
    SettingsAccordionPanelItems: [
      {
        label: 'Postes',
        path: '/settings/jobs',
        can: ['get_jobs'],
      },
      {
        label: 'Départements',
        path: '/settings/departments',
        can: ['get_departments'],
      },
    ],
  },
  {
    SettingsAccordionItemHeading: 'Entité',
    icon: IconEntities,
    can: ['get_entities_list'],
    path: ['/settings/entities'],
    SettingsAccordionPanelItems: [
      {
        label: 'Entité',
        path: '/settings/entities',
        can: ['get_entities_list'],
      },
    ],
  },
  {
    SettingsAccordionItemHeading: 'Demandes',
    icon: Icondemandes,
    can: ['get_subjects'],
    path: ['/settings/demands/subjects'],
    SettingsAccordionPanelItems: [
      {
        label: 'Objets des demandes',
        path: '/settings/demands/subjects',
        can: ['get_subjects'],
      },
    ],
  },
  {
    SettingsAccordionItemHeading: 'Paramètres des contrats',
    icon: IconcontratParam,
    can: ['get_article_models', 'get_contract_models'],
    path: ['/settings/contracts/article_models', '/settings/contracts/contract_models'],
    SettingsAccordionPanelItems: [
      {
        label: 'Modèles des articles',
        path: '/settings/contracts/article_models',
        can: ['get_article_models'],
      },
      {
        label: 'Modèles des Contrats',
        path: '/settings/contracts/contract_models',
        can: ['get_contract_models'],
      },
    ],
  },
  {
    SettingsAccordionItemHeading: 'Gestion des compétences',
    icon: IconParams,
    can: ['get_skills'],
    path: ['/settings/skills'],
    SettingsAccordionPanelItems: [
      {
        label: 'Compétences',
        path: '/settings/skills',
        can: ['get_skills'],
      },
    ],
  },
  {
    SettingsAccordionItemHeading: 'Ressources Informatiques',
    icon: Iconmaterial,
    can: ['get_hardwares_types', 'get_software_types', 'get_hardware_assignment_locations_list'],
    path: ['/settings/hardwares/types', '/settings/software/types', '/settings/locations'],
    SettingsAccordionPanelItems: [
      {
        label: 'Types de matériel',
        path: '/settings/hardwares/types',
        can: ['get_hardwares_types'],
      },
      {
        label: 'Types de logiciel',
        path: '/settings/software/types',
        can: ['get_software_types'],
      },
      {
        label: 'Emplacement',
        path: '/settings/locations',
        can: ['get_hardware_assignment_locations_list'],
      },
    ],
  },
  {
    SettingsAccordionItemHeading: "Paramètres de l'application",
    icon: IconParams,
    can: ['get_company_parameters'],
    path: ['/settings/company/params'],
    SettingsAccordionPanelItems: [
      {
        label: "Paramètres de l'entreprise",
        path: '/settings/company/params',
        can: ['get_company_parameters'],
      },
    ],
  },
  {
    SettingsAccordionItemHeading: 'ACL',
    icon: Iconacl,
    can: ['get_all_roles', 'get_actions'],
    path: ['/settings/acl/roles', '/settings/acl/actions'],
    SettingsAccordionPanelItems: [
      {
        label: 'Rôles',
        path: '/settings/acl/roles',
        can: ['get_all_roles', []],
      },
      {
        label: 'Méthodes',
        path: '/settings/acl/actions',
        can: ['get_actions'],
      },
    ],
  },
  {
    SettingsAccordionItemHeading: 'Paramètres des pointages',
    icon: Iconpointage,
    can: ['get_holidays', 'get_default_weekly_time_table'],
    path: ['/settings/time-registration/holidays', '/settings/time-registration/default-timetable'],
    SettingsAccordionPanelItems: [
      {
        label: 'Jours fériés',
        path: '/settings/time-registration/holidays',
        can: ['get_holidays'],
      },
      {
        label: 'Horaires par défaut',
        path: '/settings/time-registration/default-timetable',
        can: ['get_default_weekly_time_table'],
      },
    ],
  },
  {
    SettingsAccordionItemHeading: 'Paramètres des notes de frais',
    icon: IconParams,
    can: ['get_expenses_types', 'get_recharges_types', 'get_kilometer_coefficient'],
    path: [
      '/settings/transactions/expenses',
      '/settings/transactions/recharges',
      '/settings/transactions/kilometers-coefficient',
    ],
    SettingsAccordionPanelItems: [
      {
        label: 'Types des dépenses',
        path: '/settings/transactions/expenses',
        can: ['get_expenses_types'],
      },
      {
        label: 'Types des recharges',
        path: '/settings/transactions/recharges',
        can: ['get_recharges_types'],
      },
      {
        label: 'Coefficient kilométrique',
        path: '/settings/transactions/kilometers-coefficient',
        can: ['get_kilometer_coefficient'],
      },
    ],
  },
  {
    SettingsAccordionItemHeading: 'Paramètres des congés',
    icon: IconParams,
    can: ['get_special_leaves_types', 'get_annual_leaves_params'],
    path: ['/settings/leaves/special-leaves-types', '/settings/leaves/annual-leaves-params'],
    SettingsAccordionPanelItems: [
      {
        label: 'Types des congés spéciaux',
        path: '/settings/leaves/special-leaves-types',
        can: ['get_special_leaves_types'],
      },
      {
        label: 'Paramètres par défaut du congé annuel',
        path: '/settings/leaves/annual-leaves-params',
        can: ['get_annual_leaves_params'],
      },
    ],
  },
  {
    SettingsAccordionItemHeading: 'Paramètres des emails',
    icon: Iconemail,
    can: ['get_job_offer_email_model', 'get_spontaneous_application_email_model'],
    path: ['/settings/emails-models'],
    SettingsAccordionPanelItems: [
      {
        label: 'Modèle des emails',
        path: '/settings/emails-models',
        can: ['get_job_offer_email_model', 'get_spontaneous_application_email_model'],
      },
    ],
  },
  {
    SettingsAccordionItemHeading: 'Paramètres du planning',
    icon: IconParams,
    can: ['get_teams', 'get_events_types'],
    path: ['/settings/planning/teams', '/settings/planning/events'],
    SettingsAccordionPanelItems: [
      {
        label: 'Équipes',
        path: '/settings/planning/teams',
        can: ['get_teams'],
      },
      {
        label: 'Types des événements',
        path: '/settings/planning/events',
        can: ['get_events_types'],
      },
    ],
  },
  {
    SettingsAccordionItemHeading: 'Paramètres des check-list',
    icon: IconParams,
    can: ['get_tasks_models_list'],
    path: ['/settings/tasks-models'],
    SettingsAccordionPanelItems: [
      {
        label: 'Modèles de checklist',
        path: '/settings/tasks-models',
        can: ['get_tasks_models_list'],
      },
    ],
  },
];
