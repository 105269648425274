import { notify } from '@sobrus-com/sobrus-design-system';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useHistory, useParams } from 'react-router-dom';
import API from 'Services/API';
import { catchFunction } from 'Services/functionHelpers';

export const useCrud = (url, name, initialQuery = {}) => {
  const initialQueryState = useMemo(
    () => ({
      page: 1,
      limit: 6,
      order: 'DESC',
      orderBy: '',
      ...initialQuery,
    }),
    [initialQuery]
  );
  const [params, setParams] = useState({
    formData: {},
    queryState: initialQueryState,
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formLoadingLoading, setFormLoadingLoading] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();
  const FetchPost = useCallback(
    async (signal) => {
      try {
        setLoading(true);
        const res = await API.post(`${url}`, params.formData, {
          params: params.queryState,
          signal,
        });
        unstable_batchedUpdates(() => {
          setData(res?.data[name] || res?.data);
          setLoading(false);
        });
      } catch (err) {
        catchFunction(err, setLoading);
      }
    },
    [name, params, url]
  );
  const FetchGet = useCallback(
    async (signal, conditionalUrl) => {
      try {
        setLoading(true);
        console.log({ ...params.queryState, ...params.formData });
        const res = await API.get(`${conditionalUrl || url}`, {
          params: { ...params.queryState, ...params.formData },
          signal,
        });
        unstable_batchedUpdates(() => {
          setData(res?.data[name] || res?.data);
          setLoading(false);
        });
      } catch (err) {
        catchFunction(err, setLoading);
      }
    },
    [name, params, url]
  );

  const handelRefresh = (formData = {}) => {
    setParams({
      formData,
      queryState: initialQueryState,
    });
  };
  const DeleteFromTable = async (deleteUrl, id, setDeleteLoading, setDeleteOpen, message = 'Deleted !!') => {
    try {
      setDeleteLoading(id);
      await API.delete(`${deleteUrl}/${id}`);
      const newData = data.filter((d) => d.id !== id);
      setData(newData);
      setDeleteOpen(false);
      setDeleteLoading(false);

      notify({
        type: 'success',
        msg: message,
        delay: 5000,
      });
    } catch (err) {
      catchFunction(err, setDeleteLoading);
      setDeleteOpen(false);
    }
  };
  const Add = async (
    addUrl,
    formData,
    redirrectUrl = '',
    message = 'Ajouter!!',
    redirrect = false,
    callback = () => {}
  ) => {
    try {
      setFormLoadingLoading(true);
      const { data: addData } = await API.post(`${addUrl}`, formData);
      callback(addData);
      setFormLoadingLoading(false);
      history.push({
        pathname: redirrect || `${redirrectUrl}/${addData?.id}`,
        state: { detail: 'success', message },
      });
    } catch (err) {
      catchFunction(err, setFormLoadingLoading);
    }
  };
  const AddFormData = async (addUrl, formData, redirrectUrl, message, config, callback = () => {}) => {
    try {
      setFormLoadingLoading(true);
      const { data: addData } = await API.post(`${addUrl}`, formData, config);
      callback(addData);
      setFormLoadingLoading(false);
      history.push({
        pathname: `${redirrectUrl}/${addData?.id}`,
        state: { detail: 'success', message },
      });
    } catch (err) {
      catchFunction(err, setFormLoadingLoading);
    }
  };

  const Update = async (
    updateUrl,
    formData,
    redirrectUrl = '',
    message = '',
    redirrect = false,
    callback = () => {}
  ) => {
    try {
      setFormLoadingLoading(true);
      const { data: updateData } = await API.patch(`${updateUrl}`, formData);
      callback(updateData);
      setFormLoadingLoading(false);
      history.push({
        pathname: redirrect || `${redirrectUrl}/${updateData?.id}`,
        state: { detail: 'success', message },
      });
    } catch (err) {
      catchFunction(err, setFormLoadingLoading);
    }
  };
  const UpdateFormData = async (updateUrl, formData, redirrectUrl, message, setProgressValue, callback = () => {}) => {
    try {
      const config = {
        onUploadProgress: (event) => setProgressValue(Math.round((100 * event.loaded) / event.total)),
      };
      setFormLoadingLoading(true);
      const { data: updateData } = await API.patch(`${updateUrl}`, formData, config);
      callback(updateData);
      setFormLoadingLoading(false);
      history.push({
        pathname: `${redirrectUrl}/${updateData?.id}`,
        state: { detail: 'success', message },
      });
    } catch (err) {
      catchFunction(err, setFormLoadingLoading);
    }
  };
  const AddSamePage = async (addSamePageUrl, formData, setOpen, message) => {
    try {
      setFormLoadingLoading(true);
      const { data: addSamePageData } = await API.post(`${addSamePageUrl}`, formData);

      setFormLoadingLoading(false);
      setOpen && setOpen(false);
      setData((prev) => [addSamePageData, ...prev]);

      notify({
        type: 'success',
        msg: message,
        delay: 5000,
      });
    } catch (err) {
      catchFunction(err, setFormLoadingLoading);
    }
  };
  const UpdateSamePage = async (updateSamePageUrl, formData, setOpen, message, setReset) => {
    try {
      setFormLoadingLoading(true);
      const { data: updateSamePageData } = await API.patch(`${updateSamePageUrl}`, formData);

      setFormLoadingLoading(false);
      setOpen(false);
      setReset && setReset(false);
      setData((prev) =>
        prev.map((d) => {
          if (d.id === updateSamePageData.id) {
            return updateSamePageData;
          }
          return d;
        })
      );
      notify({
        type: 'success',
        msg: message,
        delay: 5000,
      });
    } catch (err) {
      catchFunction(err, setFormLoadingLoading);
    }
  };

  const Delete = async (deleteUrl, id, msgData, setDeleteLoading, setDeleteOpen) => {
    try {
      setDeleteLoading(id);
      await API.delete(`${deleteUrl}/${id}`);
      const newData = data.filter((d) => d.id !== id);
      setData(newData);
      setDeleteOpen(false);
      setDeleteLoading(false);
      notify({
        type: 'success',
        msg: `${msgData}`,
        delay: 5000,
      });
    } catch (err) {
      catchFunction(err, setDeleteLoading);
    }
  };
  const [toggleSearch, setToggleSearch] = useState(false);
  const handelToggleSearch = useCallback(() => setToggleSearch(!toggleSearch), [toggleSearch]);
  const newOrder = useMemo(() => ({ ASC: 'DESC', DESC: 'ASC' }), []);
  const handleOrder = useCallback(
    (orderBy) => {
      setParams((prev) => ({
        ...prev,
        queryState: {
          ...prev.queryState,
          orderBy,
          order: prev.queryState.orderBy === orderBy ? newOrder[prev.queryState.order] : 'DESC',
        },
      }));
    },
    [newOrder]
  );
  const handleSubmit = (values) => {
    setParams({
      queryState: Object.keys(values).length > 0 ? { ...params?.queryState, page: 1 } : params?.queryState,
      formData: values,
    });
  };
  return {
    data,
    setData,
    loading,
    setLoading,
    error,
    setError,
    FetchGet,
    FetchPost,
    Delete,
    Update,
    Add,
    AddSamePage,
    UpdateSamePage,
    formLoadingLoading,
    handelRefresh,
    params,
    setParams,
    DeleteFromTable,
    AddFormData,
    UpdateFormData,
    handelToggleSearch,
    toggleSearch,
    handleOrder,
    handleSubmit,
  };
};

export const useGetOne = (url, propsId = null) => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const fetch = useCallback(
    async (signal) => {
      unstable_batchedUpdates(async () => {
        try {
          if (propsId || id) {
            const res = await API.get(`${url}`, { signal });
            setData(res?.data);
          }
          setLoading(false);
        } catch (error) {
          catchFunction(error, setLoading);
        }
      });
    },
    [url, id, propsId]
  );

  useLayoutEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    fetch(signal);
    return () => controller.abort();
  }, [fetch]);
  return { data, loading, setLoading, setData };
};

export const usePostOne = (url, formData) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetch = useCallback(
    async (signal) => {
      unstable_batchedUpdates(async () => {
        try {
          setLoading(true);
          const res = await API.post(`${url}`, formData, { signal });
          setData(res?.data);
          setLoading(false);
        } catch (error) {
          catchFunction(error, setLoading);
        }
      });
    },
    [url]
  );
  useLayoutEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    fetch(signal);
    return () => controller.abort();
  }, [fetch]);

  return { data, loading, setData };
};
