import { AuditCard } from '@sobrus-com/sobrus-design-system';
import { memo } from 'react';
import { compareProps } from 'Services/functionHelpers';

export const CustomAuditCard = memo(({ children }) => {
  return (
    <AuditCard
      style={{
        margin: '0 0 1rem 0 ',
        backgroundColor: 'rgba(120, 94, 168, 0.23)',
      }}
    >
      <p style={{ textAlign: 'center', marginBottom: 0 }}>{children}</p>
    </AuditCard>
  );
}, compareProps);
