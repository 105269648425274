import { DatePicker, FormFeedback, FormGroup, Label } from '@sobrus-com/sobrus-design-system';

import { memo } from 'react';
import { compareProps } from 'Services/functionHelpers';

export const CustomDatePicker = memo((props) => {
  return (
    <FormGroup>
      {!props.label && <Label>{props.placeholder}</Label>}
      <DatePicker
        id={props.name}
        autoComplete="off"
        placeholderText={props.placeholder}
        todayButton
        calendarMainColor="primary"
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        showYearDropdown
        showMonthDropdown
        useShortMonthInDropdown
        invalid={props.hasError && props.isTouched}
        {...props}
      />
      {props.hasError && props.isTouched ? <FormFeedback invalid="true">{props.hasError}</FormFeedback> : null}
    </FormGroup>
  );
}, compareProps);

export const CustomSearchDatePicker = memo((props) => {
  // console.log('CustomDatePicker', props?.name);
  return (
    <DatePicker
      id={props.name}
      autoComplete="off"
      placeholderText={props.placeholder}
      todayButton
      calendarMainColor="primary"
      onChange={props.handleChange}
      onBlur={props.handleBlur}
      showYearDropdown
      showMonthDropdown
      useShortMonthInDropdown
      isClearable
      {...props}
    />
  );
}, compareProps);

const defaultProps = {
  timeFormat: 'HH:mm',
  timeIntervals: 15,
  timeCaption: 'Heure',
  dateFormat: 'yyyy-MM-dd',
  todayButton: "Aujourd'hui",
  popperPlacement: 'auto',
  locale: 'fr',
  placeholder: '',
  isValid: false,
  disabled: false,
  id: 'sob_date_picker_id',
};
CustomDatePicker.defaultProps = defaultProps;
CustomSearchDatePicker.defaultProps = defaultProps;

export default CustomDatePicker;
