/* eslint-disable no-use-before-define */
import React, { memo, useContext, useState } from 'react';
import { AiFillHeart, AiOutlineComment, AiOutlineHeart } from 'react-icons/ai';
import { IconButton } from '@sobrus-com/sobrus-design-system';
// import { Avatar, AvatarGroup } from "@mui/material";
import './Like.css';
import { compareProps } from 'Services/functionHelpers';
import { BASE_URL } from 'env';
import { AuthContext } from 'Context';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomInput } from 'Components';

import Avatar from 'react-avatar';
import { useAcl } from 'Hooks';
import { PAGES } from 'Values/acl';
import Comments from './Comments';
import { PopupLikes } from './popupsLikes';
import { usePost } from './usePosts';

export const CardLikeAndComment = memo((props) => {
  const { can } = useAcl();
  const { onClickLike, post, setPosts, posts } = props;
  const [display, setDisplay] = useState(false);
  const [initialValues] = useState({ content: '', postId: post?.id });
  const [openComment, setOpenComment] = useState(false);
  const [hover, setHover] = useState(false);
  const { user } = useContext(AuthContext);
  const { values, handleSubmit, setFieldValue, handleChange, handleBlur, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      content: Yup.string().trim().required(''),
    }),
    onSubmit: (formValues) => {
      postComment(formValues);
    },
  });

  const {
    postComment,
    likeComment,
    postSubComment,
    deleteComment,
    updateComment,
    likeSubComment,
    deleteSubComment,
    updateSubComment,
  } = usePost(posts, setPosts, setFieldValue, post);
  return (
    <>
      {display && <PopupLikes {...{ open: display, setOpen: setDisplay, post }} />}

      <div className="card_content_like_and_comment_section">
        <div className="card_content_like_and_comment_container">
          <IconButton style={{ margin: 1, lineHeight: 1 }} onClick={onClickLike} color="danger">
            {props?.isLiked ? <AiFillHeart size={20} /> : <AiOutlineHeart size={20} />}
            {hover && post?.reactions?.length > 0 && (
              <div className="likes__popup">
                {post?.reactions?.map((p, key) => (
                  <>
                    {key < 5 && <div>{`${p?.collaborater?.lastName}  ${p?.collaborater?.firstName}`}</div>}
                    {key === 5 && (
                      <div style={{ color: '#707070' }}>
                        Et {post.reactions.length - 5} autre
                        {post.reactions.length - 5 > 1 && 's'} ...
                      </div>
                    )}
                  </>
                ))}
              </div>
            )}
          </IconButton>
          <div
            style={{ cursor: 'pointer' }}
            onMouseEnter={() => {
              setHover(true);
            }}
            onFocus={() => setHover(true)}
            onMouseOut={() => {
              setHover(false);
            }}
            onBlur={() => setHover(false)}
            onClick={() => setDisplay(true)}
            role="button"
            onKeyPress={() => {}}
            tabIndex={0}
          >
            <p> {post?.reactions?.length}</p>
          </div>
        </div>
        <div className="card_content_like_and_comment_container">
          <IconButton style={{ margin: 1, lineHeight: 1 }} onClick={() => setOpenComment(!openComment)} color="primary">
            <AiOutlineComment size={20} />
          </IconButton>
          <p> {post?.comments?.length}</p>
        </div>
      </div>
      {openComment && (
        <div className="card_content_comment_section">
          {can(PAGES.HOME.COMMENT__ADD) && (
            <div className="card_content_comment_empry_comment_container">
              <div className="card_content_comment_empry_comment_image">
                {user?.photo ? (
                  <img
                    style={{ height: 58, width: 58, objectFit: 'cover' }}
                    src={`${BASE_URL}/images/collaborators/${user?.photo}`}
                    alt=""
                  />
                ) : (
                  <Avatar name={`${user?.firstName} ${user?.lastName}`} size="58" round color="#785ea8" />
                )}
              </div>
              <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                <CustomInput
                  label
                  value={values.content}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  hasError={errors.content}
                  isTouched={touched.content}
                  name="content"
                  placeholder="Insérer votre commentaire ici …"
                />
              </form>
            </div>
          )}
          {post?.comments?.map((comment) => (
            <Comments
              {...{
                comment,
                post,
                likeComment,
                postSubComment,
                deleteComment,
                updateComment,
                likeSubComment,
                deleteSubComment,
                updateSubComment,
              }}
              key={comment?.id}
            />
          ))}
        </div>
      )}
    </>
  );
}, compareProps);
