import {
  SettingsAccordion,
  SettingsAccordionItem,
  SettingsAccordionItemButton,
  SettingsAccordionItemHeading,
  SettingsAccordionPanel,
  SettingsAccordionPanelItem,
} from '@sobrus-com/sobrus-design-system';
import { useAcl } from 'Hooks';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { compareProps } from 'Services/functionHelpers';
import { settingsAccordion } from 'Values/SideMeduPath';

import 'index.css';

export const SideMenu = React.memo(({ path }) => {
  const location = useLocation();
  const history = useHistory();
  const { can } = useAcl();
  return (
    <div>
      <SettingsAccordion allowZeroExpanded preExpanded={[path]}>
        {settingsAccordion.map((settingAccordion) => (
          <>
            {can(settingAccordion.can) && (
              <React.Fragment key={settingAccordion.can}>
                <SettingsAccordionItem uuid={settingAccordion.path.find((e) => e === path)}>
                  <SettingsAccordionItemHeading>
                    <SettingsAccordionItemButton>
                      <div className="workspace__sideMenuItem">
                        <img src={settingAccordion?.icon} style={{ marginRight: '1rem' }} alt="" />
                        <span> {settingAccordion.SettingsAccordionItemHeading}</span>
                      </div>
                    </SettingsAccordionItemButton>
                  </SettingsAccordionItemHeading>
                  <SettingsAccordionPanel>
                    {settingAccordion.SettingsAccordionPanelItems.map((settingAccordionPanelItem) => (
                      <>
                        {can(settingAccordionPanelItem.can) && (
                          <React.Fragment key={settingAccordionPanelItem.can}>
                            <SettingsAccordionPanelItem
                              active={location.pathname.startsWith(settingAccordionPanelItem.path)}
                              onClick={() => {
                                history.push(settingAccordionPanelItem.path);
                              }}
                            >
                              {settingAccordionPanelItem.label}
                            </SettingsAccordionPanelItem>
                          </React.Fragment>
                        )}
                      </>
                    ))}
                  </SettingsAccordionPanel>
                </SettingsAccordionItem>
              </React.Fragment>
            )}
          </>
        ))}
      </SettingsAccordion>
    </div>
  );
}, compareProps);
