import { Card, Col, Zoom } from '@sobrus-com/sobrus-design-system';
import { memo } from 'react';
import ContentLoader from 'react-content-loader';
import './Loadings.css';
import logo from 'Assets/loading.png';
import { compareProps } from 'Services/functionHelpers';

const PostCardLoading = memo(
  () => (
    <div className="PostCardLoading" style={{ margin: '2rem 0px' }}>
      <Card className="card">
        <ContentLoader
          speed={2}
          width={476}
          height={66}
          backgroundColor="rgba(120, 94, 168, 0.80)"
          foregroundColor="rgba(120, 94, 168, 0.10)"
          // {...props}
        >
          <rect x="16" y="0" rx="6" ry="6" width="56" height="56" />
          <rect x="88" y="8" rx="3" ry="3" width="88" height="6" />
          <rect x="88" y="26" rx="3" ry="3" width="52" height="6" />
        </ContentLoader>
        <div className="img-container">
          <img className="img" src={logo} alt="" />
        </div>
        <ContentLoader
          speed={2}
          width={476}
          height={100}
          backgroundColor="rgba(120, 94, 168, 0.80)"
          foregroundColor="rgba(120, 94, 168, 0.10)"
          // {...props}
        >
          <rect x="16" y="46" rx="3" ry="3" width="410" height="6" />
          <rect x="16" y="62" rx="3" ry="3" width="380" height="6" />
          <rect x="16" y="78" rx="3" ry="3" width="178" height="6" />
        </ContentLoader>
      </Card>
    </div>
  ),
  compareProps
);
const PostsCardLoading = () =>
  [1, 2].map((item, index) => (
    <Zoom key={String(`PostsCardLoading${index}`)} in style={{ transitionDelay: `${500 + 50 * index}ms` }}>
      <PostCardLoading />
    </Zoom>
  ));

function CardLoading() {
  return (
    <Col className="AllergiesCard">
      <ContentLoader height={329} width={356.7} backgroundColor="#d9d9d9" foregroundColor="#ecebeb">
        <rect x="0" y="15" rx="4" ry="4" width="130" height="12" />
        <rect x="155" y="15" rx="3" ry="3" width="130" height="12" />
        <rect x="0" y="50" rx="3" ry="3" width="90" height="12" />
        <rect x="185" y="50" rx="3" ry="3" width="100" height="12" />
        <rect x="0" y="90" rx="3" ry="3" width="130" height="12" />
        <rect x="160" y="90" rx="3" ry="3" width="120" height="12" />
        <rect x="0" y="130" rx="3" ry="3" width="130" height="12" />
        <rect x="160" y="130" rx="3" ry="3" width="150" height="12" />
        <rect x="0" y="175" rx="4" ry="4" width="130" height="12" />
        <rect x="155" y="175" rx="3" ry="3" width="130" height="12" />
        <rect x="0" y="220" rx="3" ry="3" width="90" height="12" />
        <rect x="115" y="220" rx="3" ry="3" width="60" height="12" />
      </ContentLoader>
    </Col>
  );
}
function LoadingMeasurementDisplayField() {
  return (
    <div className="LoadingMeasurementDisplayField">
      <ContentLoader height={12} width={130} backgroundColor="#d9d9d9" foregroundColor="#ecebeb">
        <rect x="0" y="0" rx="4" ry="4" width="100" height="12" />
      </ContentLoader>
    </div>
  );
}
function LoadingMeasurementDisplayFields() {
  return (
    <div className="LoadingMeasurementDisplayFields-container">
      <LoadingMeasurementDisplayField width={90} />
      <LoadingMeasurementDisplayField width={70} />
      <LoadingMeasurementDisplayField width={100} />
      <LoadingMeasurementDisplayField width={60} />
    </div>
  );
}

const AllergieCardsLoading = () =>
  [1, 2, 3].map((item, index) => (
    <Zoom key={String(`AllergieCardsLoading${index}`)} in style={{ transitionDelay: `${500 + 50 * index}ms` }}>
      <CardLoading />
    </Zoom>
  ));
const MeasurementCardsLoading = () =>
  [1, 2, 3].map((item, index) => (
    <Zoom key={String(`AllergieCardsLoading${index}`)} in style={{ transitionDelay: `${500 + 50 * index}ms` }}>
      <CardLoading />
    </Zoom>
  ));
function TimeLineLoadingItem() {
  return (
    <div className="TimeLineLoadingItem__container">
      <div>
        <ContentLoader height={84} width={340} backgroundColor="#d9d9d9" foregroundColor="#ecebeb">
          <rect x="0" y="0" width="67" height="11" rx="3" />
          <rect x="76" y="0" width="140" height="11" rx="3" />
          <rect x="127" y="48" width="53" height="11" rx="3" />
          <rect x="187" y="48" width="72" height="11" rx="3" />
          <rect x="18" y="48" width="100" height="11" rx="3" />
          <rect x="0" y="71" width="37" height="11" rx="3" />
          <rect x="18" y="23" width="140" height="11" rx="3" />
          <rect x="166" y="23" width="173" height="11" rx="3" />
        </ContentLoader>
      </div>
      <div>
        <ContentLoader height={84} width={340} backgroundColor="#d9d9d9" foregroundColor="#ecebeb">
          <rect x="0" y="0" width="67" height="11" rx="3" />
          <rect x="76" y="0" width="140" height="11" rx="3" />
          <rect x="127" y="48" width="53" height="11" rx="3" />
          <rect x="187" y="48" width="72" height="11" rx="3" />
          <rect x="18" y="48" width="100" height="11" rx="3" />
          <rect x="0" y="71" width="37" height="11" rx="3" />
          <rect x="18" y="23" width="140" height="11" rx="3" />
          <rect x="166" y="23" width="173" height="11" rx="3" />
        </ContentLoader>
      </div>
    </div>
  );
}

function TimeLineLoading() {
  return (
    <div style={{ width: '100%', height: 'fit-content' }}>
      <TimeLineLoadingItem />
      <TimeLineLoadingItem />
      <TimeLineLoadingItem />
      <TimeLineLoadingItem />
    </div>
  );
}

function WaitingRoomLoadingItems() {
  return (
    <div
      style={{
        width: '100%',
        height: 'fit-content',
        background: '#F9F9F9',
        borderRadius: '1rem',
        overflow: 'hidden',
        marginBottom: '10px',
      }}
    >
      <ContentLoader height={84} width={540} backgroundColor="#d9d9d9" foregroundColor="#ecebeb">
        <rect x="44" y="24" rx="3" ry="3" width="98" height="11" />
        <rect x="157" y="23" rx="3" ry="3" width="199" height="11" />
        <rect x="17" y="32" rx="3" ry="3" width="17" height="30" />
        <rect x="44" y="45" rx="3" ry="3" width="140" height="11" />
        <rect x="192" y="44" rx="3" ry="3" width="164" height="11" />
        <rect x="45" y="64" rx="3" ry="3" width="319" height="7" />
        <rect x="512" y="21" rx="3" ry="3" width="17" height="30" />
        <rect x="499" y="56" rx="3" ry="3" width="33" height="6" />
      </ContentLoader>
    </div>
  );
}

function WaitingRoomLoading() {
  return (
    <>
      <WaitingRoomLoadingItems />
      <WaitingRoomLoadingItems />
    </>
  );
}

export {
  PostsCardLoading,
  AllergieCardsLoading,
  MeasurementCardsLoading,
  LoadingMeasurementDisplayFields,
  TimeLineLoading,
  WaitingRoomLoading,
};
