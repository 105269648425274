import {
  AuditCard,
  AuditCardBody,
  AuditCardHeader,
  AuditCardHeaderIcon,
  AuditCardItem,
  AuditCardTitle,
} from '@sobrus-com/sobrus-design-system';
import test from 'Assets/auditCardIcon.jpg';
import { ItemsLoader } from 'Components';
import { memo } from 'react';
import { compareProps } from 'Services/functionHelpers';
import moment from 'Services/moment';

export const Tracabilite = memo(({ createdAt, createdBy, updatedAt, updatedBy, loading }) => {
  // // ('tracabilité')
  return (
    <div data-aos="fade" data-aos-delay="150">
      <AuditCard style={{ margin: '0px' }}>
        <AuditCardHeader>
          <AuditCardHeaderIcon
            icon={
              <img
                src={test}
                style={{
                  height: '1.438rem',
                  objectFit: 'contain',
                  width: '1.198rem',
                }}
                alt=""
              />
            }
          />
          <AuditCardTitle>Informations de traçabilité</AuditCardTitle>
        </AuditCardHeader>
        <AuditCardBody>
          <AuditCardItem
            label="Créée  le :"
            value={
              loading ? (
                <ItemsLoader />
              ) : (
                <p>
                  <strong>{moment(createdAt).format('DD/MM/YYYY à HH:mm')}</strong> par{' '}
                  <strong>
                    {createdBy?.firstName} {createdBy?.lastName}
                  </strong>
                </p>
              )
            }
          />
          <AuditCardItem
            label="Mise à jour le :"
            value={
              loading ? (
                <ItemsLoader />
              ) : (
                <p>
                  <strong>{moment(updatedAt).format('DD/MM/YYYY à HH:mm')}</strong> par{' '}
                  <strong>
                    {updatedBy?.firstName} {updatedBy?.lastName}
                  </strong>
                </p>
              )
            }
          />
        </AuditCardBody>
      </AuditCard>
    </div>
  );
}, compareProps);
