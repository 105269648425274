import { PagesLoader } from 'Components';
import { AuthContext } from 'Context';
import { useAcl } from 'Hooks';
import { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import FailureComponent from './FailureComponent';
// const SentryRoute = Sentry.withSentryRouting(Route);
function PrivateRoutes({ component, to, does, onOfdoes }) {
  const { error, loading } = useContext(AuthContext);
  const { canAll, can } = useAcl();
  return (
    <Route
      exact
      path={to}
      render={(props) => {
        if (loading === false) {
          if (error) {
            return <FailureComponent {...props} />;
          }
          if (onOfdoes) {
            if (can(does)) return <div className="workspace__dashboard">{component}</div>;
            return <Redirect to={{ pathname: '/denied_access' }} />;
          }
          if (!onOfdoes) {
            if (canAll(does)) return <div className="workspace__dashboard">{component}</div>;
            return <Redirect to={{ pathname: '/denied_access' }} />;
          }
          return <div className="workspace__dashboard">{component}</div>;
        }
        return <PagesLoader />;
      }}
    />
  );
}
export default PrivateRoutes;
