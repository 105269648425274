import { CardItemValue } from '@sobrus-com/sobrus-design-system';
import { useAcl } from 'Hooks';
import { useHistory } from 'react-router-dom';

export function CardReddirectItem({ id, children }) {
  const history = useHistory();
  const { can } = useAcl();
  return (
    <CardItemValue
      style={{
        cursor: 'pointer',
        fontWeight: 700,
        color: '#785ea8',
      }}
      onClick={() => {
        if (id && can(['get_collaborater'])) {
          localStorage.removeItem('tabsItem');
          localStorage.removeItem('tabsNumber');
          history.push(`/collaboraters/view/${id}`);
        }
      }}
    >
      {children}
    </CardItemValue>
  );
}
