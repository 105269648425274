import { useContext } from 'react';
import API from 'Services/API';
import { catchFunction } from 'Services/functionHelpers';
import { AuthContext } from 'Context';

export const usePost = (posts, setPosts, setFieldValue, post) => {
  const { user, setError: setAuthError } = useContext(AuthContext);
  // comments add
  const postComment = async (values) => {
    try {
      const { data } = await API.post('/posts-comments', values);
      setPosts(
        posts.map((p) => {
          if (p.id === post.id) {
            p.comments.push(data);
            return p;
          }
          return p;
        })
      );
      setFieldValue('content', '');
    } catch (error) {
      // console.log('error');
      catchFunction(error);
    }
  };
  // comments likes
  const likeComment = async (comment) => {
    try {
      await API.post(`/posts-comments/${comment?.id}/react`);
      setPosts(
        posts.map((p) => {
          p.comments?.map((comm) => {
            if (comm.id === comment?.id) {
              comm.reactions.some((reaction) => reaction.collaborator.id === user?.id)
                ? comm.reactions.splice(
                    comm.reactions.indexOf(comm.reactions.find((x) => x.collaborator.id === user.id)),
                    1
                  )
                : comm.reactions.push({
                    collaborator: {
                      id: user?.id,
                      lastName: user?.lastName,
                      firstName: user?.firstName,
                      photo: user?.photo,
                      job: {
                        jobName: user?.job?.jobName,
                      },
                    },
                  });
              return comm;
            }
            return comm;
          });
          return p;
        })
      );
    } catch (error) {
      catchFunction(error, setAuthError);
    }
  };
  // comments repondre
  const postSubComment = async (values, comment, setFieldValueSubComment) => {
    try {
      const { data } = await API.post('/posts-comments', values);
      setPosts(
        posts.map((p) => {
          if (p.id === post.id) {
            p.comments.map((comm) => {
              if (comm?.id === comment?.id) {
                comm?.replies?.push(data);
                return comm;
              }
              return comm;
            });
            return p;
          }
          return p;
        })
      );
      setFieldValueSubComment('content', '');
    } catch (error) {
      catchFunction(error, setAuthError);
    }
  };

  const likeSubComment = async (subComment, comment) => {
    try {
      await API.post(`/posts-comments/${subComment?.id}/react`);
      setPosts(
        posts.map((p) => {
          p.comments?.map((comm) => {
            if (comm.id === comment?.id) {
              comm.replies.map((replie) => {
                if (replie.id === subComment?.id) {
                  replie.reactions.some((reaction) => reaction.collaborator.id === user?.id)
                    ? replie.reactions.splice(
                        replie.reactions.indexOf(replie.reactions.find((x) => x.collaborator.id === user.id)),
                        1
                      )
                    : replie.reactions.push({
                        collaborator: {
                          id: user?.id,
                          lastName: user?.lastName,
                          firstName: user?.firstName,
                          photo: user?.photo,
                          job: {
                            jobName: user?.job?.jobName,
                          },
                        },
                      });
                  return replie;
                }
                return replie;
              });
              return comm;
            }
            return comm;
          });
          return p;
        })
      );
    } catch (error) {
      catchFunction(error, setAuthError);
    }
  };
  // comment delete
  const deleteComment = async (comment) => {
    try {
      await API.delete(`/posts-comments/${comment?.id}`);
      setPosts(
        posts.map((p) => {
          if (p.id === post.id) {
            p.comments?.map((comm) => {
              if (comm.id === comment?.id) {
                p.comments.splice(p.comments.indexOf(p.comments.find((x) => x.id === comment?.id)), 1);
                return comm;
              }
              return comm;
            });
            return p;
          }
          return p;
        })
      );
    } catch (error) {
      catchFunction(error, setAuthError);
    }
  };
  const updateComment = async (values, comment, setFieldValueUpdateComment, setUpdateDisplayComment) => {
    try {
      await API.patch(`/posts-comments/${comment?.id}`, values);
      setPosts(
        posts.map((p) => {
          if (p.id === post.id) {
            p.comments?.map((comm) => {
              if (comm.id === comment?.id) {
                comm.content = values?.content;
                return comm;
              }
              return comm;
            });
            return p;
          }
          return p;
        })
      );
      setFieldValueUpdateComment('content', '');
      setUpdateDisplayComment(false);
    } catch (error) {
      catchFunction(error, setAuthError);
    }
  };

  // delete sub comment
  const deleteSubComment = async (replie, comment) => {
    try {
      await API.delete(`/posts-comments/${replie?.id}`);
      setPosts(
        posts.map((p) => {
          if (p.id === post.id) {
            p.comments?.map((comm) => {
              if (comm.id === comment?.id) {
                comm?.replies?.map((rep) => {
                  if (rep.id === replie?.id) {
                    comm?.replies.splice(comm?.replies.indexOf(comm?.replies.find((x) => x.id === replie?.id)), 1);
                    return rep;
                  }
                  return rep;
                });
                return comm;
              }
              return comm;
            });
            return p;
          }
          return p;
        })
      );
    } catch (error) {
      catchFunction(error, setAuthError);
    }
  };
  const updateSubComment = async (values, comment, replie, updateSetFildValue, setUpdateDisplaySubComment) => {
    try {
      await API.patch(`/posts-comments/${replie?.id}`, values);
      setPosts(
        posts.map((p) => {
          if (p.id === post.id) {
            p.comments?.map((comm) => {
              if (comm.id === comment?.id) {
                comm?.replies?.map((rep) => {
                  if (rep.id === replie?.id) {
                    rep.content = values?.content;
                    return rep;
                  }
                  return rep;
                });
                return comm;
              }
              return comm;
            });
            return p;
          }
          return p;
        })
      );
      updateSetFildValue('content', '');
      setUpdateDisplaySubComment(false);
    } catch (error) {
      catchFunction(error, setAuthError);
    }
  };
  return {
    postComment,
    likeComment,
    postSubComment,
    deleteComment,
    updateComment,
    likeSubComment,
    deleteSubComment,
    updateSubComment,
  };
};
