import React from 'react';
import './RadioButton.scss';

const propTypes = {};

const defaultProps = {};

function RadioButton({ onChange, name, value, label, id, checked }) {
  return (
    <>
      <input type="radio" name={name} id={id} value={value} checked={checked} onChange={onChange} />
      <label htmlFor={id}>{label}</label>
    </>
  );
}

RadioButton.propTypes = propTypes;
RadioButton.defaultProps = defaultProps;

export { RadioButton };
