/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import classNames from 'classnames';
import RUG, { DropArea } from 'react-upload-gallery';
import { v4 as uuidv4 } from 'uuid';
import addImage from 'Assets/add_image.png';
import { mapToCssModules } from '../../utils';
// Add style manually
import 'react-upload-gallery/dist/style.css'; // or scss
import './style.css';
// const propTypes = {
//   children: PropTypes.node,
//   horizontal: PropTypes.bool,
//   className: PropTypes.string,
//   cssModule: PropTypes.object,
//   onRemove: PropTypes.func,
//   onUpdate: PropTypes.func,
//   desc: PropTypes.string,
//   hideDeleteBtn: PropTypes.bool,
//   hideDeleteBtn: PropTypes.bool,
//   hideUpdateBtn: PropTypes.bool,
// };

const defaultProps = {
  horizontal: true,
  desc: 'Sélectionnez ou Glissez-déposez votre logo ici ..',
};

function ImagePicker(props) {
  const {
    className,
    cssModule,
    horizontal,
    onRemove,
    desc,
    hideDeleteBtn,
    hideUpdateBtn,
    imagesData,
    setImagesData,
    img,
    ...attributes
  } = props;

  const classes = mapToCssModules(
    classNames(
      'sob-image-picker',
      horizontal ? 'sob-image-picker-direction-horizontal' : 'sob-image-picker-direction-vertical',
      className
    ),
    cssModule
  );
  let customOpenDialogue = null;
  const remove = (image) => {
    setImagesData(imagesData.filter((item) => item.uid !== image.uid));
  };
  return (
    <RUG
      autoUpload={false}
      className={classes}
      header={({ openDialogue }) => {
        customOpenDialogue = openDialogue;
        if (imagesData?.length === 0) {
          return (
            <DropArea>
              {(isDrag) => (
                <button
                  type="button"
                  onClick={openDialogue}
                  className={`sob-image-picker-header ${isDrag ? 'sob-image-is-drag' : ''}`}
                >
                  {img ?? <img className="sob-image-picker-header-icon" src={addImage} alt="add icon" />}
                  <span className="sob-image-picker-header-desc">{desc}</span>
                </button>
              )}
            </DropArea>
          );
        }
        return '';
      }}
      onChange={(images) => {
        if (images[0]) {
          setImagesData([images[0]]);
        } else {
          setImagesData([]);
        }
      }}
      {...attributes}
    >
      {(_isDrag) => (
        <div>
          {imagesData.map((image) => {
            return (
              <div key={uuidv4()}>
                <div>
                  <div className="rug-item">
                    <div className="rug-card __error">
                      <div className="rug-card-name">
                        <div>
                          {image.name}
                          <div className="rug-card-size">{image.size}</div>
                        </div>
                      </div>
                      <div className="rug-card-image" style={{ backgroundImage: `url(${image.source})` }} />

                      <div className="card-image-actions">
                        {!hideUpdateBtn ? (
                          <button type="button" onClick={customOpenDialogue} className="card-image-action-update">
                            Changer
                          </button>
                        ) : (
                          ''
                        )}
                        {!hideDeleteBtn ? (
                          <button
                            type="button"
                            onClick={() => {
                              onRemove(() => remove(image));
                            }}
                            className="card-image-action-delete"
                          >
                            Supprimer
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </RUG>
  );
}

// ImagePicker.propTypes = propTypes;
ImagePicker.defaultProps = defaultProps;

export { ImagePicker };
