import { AsyncSelect, FormFeedback, FormGroup, Label } from '@sobrus-com/sobrus-design-system';
import { memo } from 'react';
import { compareProps } from 'Services/functionHelpers';

export const CustomAsyncSelect = memo((props) => {
  return (
    <FormGroup>
      {!props.label && <Label htmlFor={props.name}>{props.placeholder}</Label>}
      <AsyncSelect
        id={props.name}
        cacheOptions
        invalid={props.hasError && props.isTouched}
        styles={{
          option: (provided, state) => {
            return {
              ...provided,
              backgroundColor: state.isSelected ? '#785ea8' : '#fff',
              color: !state.isSelected ? '#000' : '#FFF',
              fontFamily: 'Poppins, sans-serif',
              fontWeight: '300',
              fontSize: 14,
              ':hover': {
                color: state.isSelected && '#fff',
                backgroundColor: state.isSelected ? '#785ea8' : '#785ea8c1',
              },
            };
          },
        }}
        {...props}
      />
      {props.hasError && props.isTouched ? <FormFeedback invalid="true">{props.hasError}</FormFeedback> : null}
    </FormGroup>
  );
}, compareProps);

export const CustomSearchAsyncSelect = memo((props) => {
  // console.log('asyncSelect', props.name);
  return (
    <AsyncSelect
      {...props}
      id={props.name}
      cacheOptions
      styles={{
        option: (provided, state) => {
          return {
            ...provided,
            backgroundColor: state.isSelected ? '#785ea8' : '#fff',
            color: !state.isSelected ? '#000' : '#FFF',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: '300',
            fontSize: 14,
            ':hover': {
              color: state.isSelected && '#fff',
              backgroundColor: state.isSelected ? '#785ea8' : '#785ea8c1',
            },
          };
        },
      }}
    />
  );
}, compareProps);

export default CustomAsyncSelect;
