/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { BASE_URL } from 'env';
import React, { useState, useContext } from 'react';
import moment from 'Services/moment';
import { AuthContext } from 'Context';
import Avatar from 'react-avatar';
import { useAcl } from 'Hooks';
import { CustomInput, TooltipMenuDelete } from 'Components';
import { TooltipMenu, TooltipMenuEdit } from '@sobrus-com/sobrus-design-system';
import { FiCornerDownRight } from 'react-icons/fi';
import { RiMore2Fill } from 'react-icons/ri';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PAGES } from 'Values/acl';
import SubComments from './SubComments';
import { PopupCommentLikes } from './popupsLikes';

function Comments({
  comment,
  post,
  likeComment,
  postSubComment,
  deleteComment,
  updateComment,
  likeSubComment,
  deleteSubComment,
  updateSubComment,
}) {
  const { can } = useAcl();
  const [openResponses, setOpenResponses] = useState(false);
  const [displayCommentLike, setDisplayCommentLike] = useState(false);
  // const [openUpdateCommernt, setOpenUpdateCommernt] = useState(false);
  const [updateDisplayComment, setUpdateDisplayComment] = useState(false);
  const { user } = useContext(AuthContext);
  const [initialValues, setInitialValues] = useState({ content: '' });
  const { values, handleSubmit, setFieldValue, handleChange, handleBlur, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      content: Yup.string().trim().required(''),
    }),
    onSubmit: (formValues) => {
      updateComment(formValues, comment, setFieldValue, setUpdateDisplayComment);
    },
  });
  return (
    <>
      {displayCommentLike && (
        <PopupCommentLikes
          {...{
            open: displayCommentLike,
            setOpen: setDisplayCommentLike,
            comment: displayCommentLike,
          }}
        />
      )}
      <div className="card_content_comment_empry_comment_container">
        <div className="card_content_comment_empry_comment_image">
          {comment?.createdBy?.photo ? (
            <img
              style={{ height: 58, width: 58, objectFit: 'cover' }}
              src={`${BASE_URL}/images/collaborators/${comment?.createdBy?.photo}`}
              alt=""
            />
          ) : (
            <Avatar
              name={`${comment?.createdBy?.firstName} ${comment?.createdBy?.lastName}`}
              size="58"
              round
              color="#785ea8"
            />
          )}
        </div>
        {updateDisplayComment ? (
          <form style={{ width: '100%' }} onSubmit={handleSubmit}>
            <CustomInput
              label
              value={values.content}
              handleBlur={handleBlur}
              handleChange={handleChange}
              hasError={errors.content}
              isTouched={touched.content}
              name="content"
              placeholder="Insérer votre commentaire ici …"
            />
          </form>
        ) : (
          <>
            <div style={{ flex: 1 }}>
              <div className="card_content_comment_content_container">
                <p className="commented_name">{`${comment?.createdBy?.lastName} ${comment?.createdBy?.firstName}`}</p>
                <p className="comment_content">{comment?.content}</p>
              </div>
              <div className="card_content_comment_content_container">
                <p className="coment_and_like">
                  {can(PAGES.HOME.REACTIONS__ADD) && (
                    <span
                      onClick={() => likeComment(comment)}
                      style={{
                        cursor: 'pointer',
                        color: comment.reactions.some((reaction) => reaction.collaborator.id === user?.id)
                          ? '#785ea8'
                          : '#000',
                        fontWeight: comment.reactions.some((reaction) => reaction.collaborator.id === user?.id)
                          ? 600
                          : 400,
                      }}
                      aria-hidden="true"
                    >
                      J’aime
                    </span>
                  )}
                  <span
                    onClick={() =>
                      can(PAGES.HOME.COMMENT__ADD) && setOpenResponses(!openResponses ? comment?.id : false)
                    }
                    style={{ cursor: 'pointer' }}
                    aria-hidden="true"
                  >
                    Répondre
                  </span>
                </p>
                <p className="coment_and_like_timing">
                  {moment(comment?.createdAt).fromNow()} &bull;{' '}
                  <span
                    style={{
                      cursor: comment?.reactions?.length > 0 ? 'pointer' : 'auto',
                    }}
                    onClick={() => {
                      comment?.reactions?.length > 0 && setDisplayCommentLike(displayCommentLike ? false : comment);
                    }}
                    aria-hidden="true"
                  >
                    {comment?.reactions?.length ?? '0'} {`J’aime  ${comment?.reactions?.length > 1 ? 's' : ''}`}
                  </span>{' '}
                </p>
              </div>
              {comment?.replies?.length > 0 && !openResponses && (
                <p
                  style={{
                    fontSize: 12,
                    cursor: 'pointer',
                    userSelect: 'none',
                    marginBottom: 0,
                  }}
                  onClick={() => setOpenResponses(!openResponses ? comment?.id : false)}
                >
                  <FiCornerDownRight size="12" /> {comment?.replies?.length} réponse{' '}
                </p>
              )}
            </div>
            <div>
              {can(PAGES.HOME.COMMENT__DELETEORUPDATE) && (user?.isAdmin || user?.id === comment?.createdBy?.id) && (
                <TooltipMenu
                  trigger={
                    <button type="button" className="TooltipMenu__actions-trigger-btn">
                      {' '}
                      <RiMore2Fill size={19} color="#785ea8" />
                    </button>
                  }
                >
                  {(user?.isAdmin || user?.id === comment?.createdBy?.id) && can(PAGES.HOME.COMMENT__DELETE) && (
                    <TooltipMenuDelete
                      onClick={() => {
                        deleteComment(comment);
                      }}
                    />
                  )}
                  {user?.id === comment?.createdBy?.id && can(PAGES.HOME.COMMENT__UPDATE) && (
                    <TooltipMenuEdit
                      onClick={() => {
                        setUpdateDisplayComment(!updateDisplayComment);
                        setInitialValues((prev) => ({
                          ...prev,
                          content: comment?.content,
                        }));
                      }}
                    />
                  )}
                </TooltipMenu>
              )}
            </div>
          </>
        )}
      </div>

      <SubComments
        {...{
          openResponses,
          comment,
          post,
          postSubComment,
          setDisplayCommentLike,
          displayCommentLike,
          likeSubComment,
          deleteSubComment,
          updateSubComment,
        }}
      />
    </>
  );
}

export default Comments;
