import { Tag } from '@sobrus-com/sobrus-design-system';
import { memo } from 'react';
import { compareProps } from 'Services/functionHelpers';

export const CTag = memo(({ bg, children, color, MinSize }) => {
  return (
    <Tag
      style={{
        backgroundColor: bg,
        color,
        width: MinSize,
        justifyContent: 'center',
      }}
    >
      {children}
    </Tag>
  );
}, compareProps);

CTag.defaultProps = {
  color: '#ffffff',
};
