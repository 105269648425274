import { useCallback, useEffect, useState } from 'react';
import API from 'Services/API';
import { catchFunction } from 'Services/functionHelpers';

export const useFetchAsync = (url, options, name, does, serchBy, methode = 'get') => {
  const [data, setData] = useState([]);
  const [error] = useState(false);
  const [loading, setLoading] = useState('');
  const [, setInputValue] = useState('');
  const fetch = useCallback(async (inputValue = null, callback = () => {}) => {
    try {
      if (does) {
        setLoading(true);
        const res = await API[methode](
          `${url}?limit=100&page=1`,
          methode === 'get'
            ? {
                params: { [serchBy || 'label']: inputValue },
              }
            : { [serchBy || 'label']: inputValue }
        );
        const formattedData =
          name === 'clients'
            ? res?.data[name]?.map((item) => ({
                value: item?.[options[0]] ?? 0,
                label: item?.[options[1]] ?? '',
                city: item?.city?.name,
                ...item,
              }))
            : res?.data[name]?.map((item) => ({
                value: item?.[options[0]] ?? 0,
                label: item?.[options[1]] ?? '',
                ...item,
              }));

        callback(formattedData);
        setData(formattedData);
        setLoading(false);
      }
    } catch (err) {
      catchFunction(err, setLoading);
    }
  }, []);
  useEffect(() => {
    fetch();
  }, [fetch]);
  const handleInputChange = useCallback((e) => {
    // (e)
    setInputValue(e);
    return e;
  }, []);
  return [data, fetch, handleInputChange, loading, error];
};
