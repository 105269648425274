import Aos from 'aos';
import 'aos/dist/aos.css';
import { AuthContext, NotificationsContext } from 'Context';
import { useNotifications } from 'Hooks';
import mixpanel from 'mixpanel-browser';
import { lazy, Suspense, useContext, useEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { PagesLoader } from 'Components';
import { useSwr } from 'Hooks/useSwr';
import PrivateRoutes from 'Pages/Logs/PrivateRoutes';
import { ROUTES } from 'Values/acl';
import './mobile.css';
import { EXT } from 'env';
import { Font } from '@react-pdf/renderer';
import poppinsRegular from 'fonts/Poppins-Regular.ttf';
import poppinsItalic from 'fonts/Poppins-Italic.ttf';
import poppinsLight from 'fonts/Poppins-Light.ttf';
import poppinsLightItalic from 'fonts/Poppins-LightItalic.ttf';
import poppinsMedium from 'fonts/Poppins-Medium.ttf';
import poppinsMediumItalic from 'fonts/Poppins-MediumItalic.ttf';
import poppinsSemiBold from 'fonts/Poppins-SemiBold.ttf';
import poppinsSemiBoldItalic from 'fonts/Poppins-SemiBoldItalic.ttf';
import poppinsBold from 'fonts/Poppins-Bold.ttf';
import poppinsBoldItalic from 'fonts/Poppins-BoldItalic.ttf';

Font.register({
  family: 'Poppins',
  fonts: [
    { src: poppinsRegular }, // font-style: normal, font-weight: normal
    { src: poppinsItalic, fontStyle: 'italic' },
    { src: poppinsLight, fontStyle: 'normal', fontWeight: 300 },
    { src: poppinsLightItalic, fontStyle: 'italic', fontWeight: 300 },
    { src: poppinsMedium, fontStyle: 'normal', fontWeight: 500 },
    { src: poppinsMediumItalic, fontStyle: 'italic', fontWeight: 500 },
    { src: poppinsSemiBold, fontStyle: 'normal', fontWeight: 600 },
    { src: poppinsSemiBoldItalic, fontStyle: 'italic', fontWeight: 600 },
    { src: poppinsBold, fontStyle: 'normal', fontWeight: 700 },
    { src: poppinsBoldItalic, fontStyle: 'italic', fontWeight: 700 },
  ],
});

const Mobile = lazy(() => import(/* webpackChunkName: "Mobile" */ 'Pages/Mobile/Mobile'));
const UnauthorizedComponent = lazy(() =>
  import(/* webpackChunkName: "UnauthorizedComponent" */ 'Pages/Logs/UnauthorizedComponent/UnauthorizedComponent')
);
const ErrorPage = lazy(() => import(/* webpackChunkName: "ErrorPage" */ 'Pages/Logs/ErrorPage'));
// const Page404 = lazy(() => import(  /* webpackChunkName: "Page404" */'Pages/Logs/Page404'));
const ProtectedHeader = lazy(() =>
  import(/* webpackChunkName: "ProtectedHeader" */ 'Components/Header/ProtectedHeader')
);
const LandingPage = lazy(() => import(/* webpackChunkName: "LandingPage" */ 'Pages/landing_page/LandingPage'));
const SignIn = lazy(() => import(/* webpackChunkName: "SignIn" */ 'Pages/Logs/SignIn/SignIn'));
const Home = lazy(() => import(/* webpackChunkName: "Home" */ 'Pages/Home/Home'));
const Profile = lazy(() => import(/* webpackChunkName: "Profile" */ 'Pages/Profile/Profile'));
// ------------------------------------------------------- NOTIFICATIONS COMPONENTS ----------------------------------------------//
const Notifications = lazy(() => import(/* webpackChunkName: "Notifications" */ 'Pages/Notifications/Notifications'));
const NotificationsHistory = lazy(() =>
  import(/* webpackChunkName: "NotificationsHistory" */ 'Pages/Notifications/NotificationsHistory')
);
// ------------------------------------------------------- NOTIFICATIONS COMPONENTS ----------------------------------------------//
// ------------------------------------------------------- COLLABORATERS COMPONENTS ----------------------------------------------//
const Collaboraters = lazy(() =>
  import(/* webpackChunkName: "Collaboraters" */ 'Pages/Collaboraters/Collaboraters/Collaboraters')
);
const CollaboratersAddOrUpdate = lazy(() =>
  import(
    /* webpackChunkName: "CollaboratersAddOrUpdate" */
    'Pages/Collaboraters/CollaboratersAddOrUpdate/CollaboratersAddOrUpdate'
  )
);
const CollaboraterView = lazy(() =>
  import(/* webpackChunkName: "CollaboraterView" */ 'Pages/Collaboraters/CollaboraterView/CollaboraterView')
);
const CollaboratersInterviewAdd = lazy(() =>
  import(
    /* webpackChunkName: "CollaboratersInterviewAdd" */ 'Pages/Collaboraters/CollaboraterView/CollaboratersInterviewAdd'
  )
);
const UpdatePhonebookParams = lazy(() =>
  import(
    /* webpackChunkName: "UpdatePhonebookParams" */ 'Pages/Collaboraters/UpdatePhonebookParams/UpdatePhonebookParams'
  )
);
const UpdateAccountParams = lazy(() =>
  import(/* webpackChunkName: "UpdateAccountParams" */ 'Pages/Collaboraters/UpdateAccountParams/UpdateAccountParams')
);

const CollaboratersUpdateShedule = lazy(() =>
  import(
    /* webpackChunkName: "CollaboratersUpdateShedule" */
    'Pages/Collaboraters/CollaboratersUpdateShedule/CollaboratersUpdateShedule'
  )
);
// ------------------------------------------------------- COLLABORATERS COMPONENTS ----------------------------------------------//
// ------------------------------------------------------- COLLABORATERS Interviews COMPONENTS ----------------------------------------------//
const CollaBorateursInterviews = lazy(() =>
  import(/* webpackChunkName: "CollaBorateursInterviews" */ 'Pages/CollaboratersInterviews/CollaBorateursInterviews')
);
const CollaBorateursInterviewsView = lazy(() =>
  import(
    /* webpackChunkName: "CollaBorateursInterviewsView" */ 'Pages/CollaboratersInterviews/CollaBorateursInterviewsView'
  )
);
const CollaborateursInterviewsUpdate = lazy(() =>
  import(
    /* webpackChunkName: "CollaborateursInterviewsUpdate" */ 'Pages/CollaboratersInterviews/CollaborateursInterviewsUpdate'
  )
);
// ------------------------------------------------------- COLLABORATERS Interviews COMPONENTS ----------------------------------------------//
// ------------------------------------------------------- REQUESTS COMPONENTS ----------------------------------------------//
const AllRequests = lazy(() => import(/* webpackChunkName: "AllRequests" */ 'Pages/Requests/AllRequests'));
const MyRequests = lazy(() => import(/* webpackChunkName: "MyRequests" */ 'Pages/Requests/MyRequests'));
const RequestView = lazy(() => import(/* webpackChunkName: "RequestView" */ 'Pages/Requests/RequestView'));
const MyRequestView = lazy(() => import(/* webpackChunkName: "MyRequestView" */ 'Pages/Requests/MyRequestView'));
const RequestAddOrUpdate = lazy(() =>
  import(/* webpackChunkName: "RequestAddOrUpdate" */ 'Pages/Requests/RequestAddOrUpdate')
);
const TeamRequests = lazy(() => import(/* webpackChunkName: "TeamRequests" */ 'Pages/Requests/TeamRequests'));
const TeamRequestView = lazy(() => import(/* webpackChunkName: "TeamRequestView" */ 'Pages/Requests/TeamRequestView'));
// ------------------------------------------------------- REQUESTS COMPONENTS ----------------------------------------------//
// ------------------------------------------------------- CONTRQCTS COMPONENTS ----------------------------------------------//
const Contrats = lazy(() => import(/* webpackChunkName: "Contrats" */ 'Pages/Contrats/Contrats'));
const GenerateContract = lazy(() =>
  import(/* webpackChunkName: "GenerateContract" */ 'Pages/Contrats/GenerateContract')
);
// ------------------------------------------------------- CONTRQCTS COMPONENTS ----------------------------------------------//
// ------------------------------------------------------- GALERIE COMPONENTS ----------------------------------------------//
const Galerie = lazy(() => import(/* webpackChunkName: "Galerie" */ 'Pages/Galerie/Galerie'));
const GalerieAddOrUpdate = lazy(() =>
  import(/* webpackChunkName: "GalerieAddOrUpdate" */ 'Pages/Galerie/GalerieAddOrUpdate')
);
const GalerieView = lazy(() => import(/* webpackChunkName: "GalerieView" */ 'Pages/Galerie/GalerieView/GalerieView'));
// ------------------------------------------------------- GALERIE COMPONENTS ----------------------------------------------//
// ------------------------------------------------------- Activites COMPONENTS ----------------------------------------------//
const ActivitesList = lazy(() => import(/* webpackChunkName: "Activites" */ 'Pages/Activities/ActivitesList'));
const ActivitesAddOrUpdate = lazy(() =>
  import(/* webpackChunkName: "ActivitesAddOrUpdate" */ 'Pages/Activities/ActivitesAddOrUpdate')
);
const ActivitesView = lazy(() => import(/* webpackChunkName: "ActivitesView" */ 'Pages/Activities/ActivitesView'));
// ------------------------------------------------------- Evenements COMPONENTS ----------------------------------------------//
// ------------------------------------------------------- HARDWARES COMPONENTS ----------------------------------------------//
const Hardwares = lazy(() => import(/* webpackChunkName: "Hardwares" */ 'Pages/Hardwares/Hardwares'));
const HardwaresAddOrUpdate = lazy(() =>
  import(/* webpackChunkName: "HardwaresAddOrUpdate" */ 'Pages/Hardwares/HardwaresAddOrUpdate')
);
const HardwaresView = lazy(() => import(/* webpackChunkName: "HardwaresView" */ 'Pages/Hardwares/HardwaresView'));
// ------------------------------------------------------- HARDWARES COMPONENTS ----------------------------------------------//
// ------------------------------------------------------- SOFTWARES COMPONENTS ----------------------------------------------//
const SoftWares = lazy(() => import(/* webpackChunkName: "SoftWares" */ 'Pages/SoftWares/SoftWares'));
const SoftWaresAddOrUpdate = lazy(() =>
  import(/* webpackChunkName: "SoftWaresAddOrUpdate" */ 'Pages/SoftWares/SoftWaresAddOrUpdate')
);
const SoftWaresView = lazy(() => import(/* webpackChunkName: "SoftWaresView" */ 'Pages/SoftWares/SoftWaresView'));
// ------------------------------------------------------- SOFTWARES COMPONENTS ----------------------------------------------//
// ------------------------------------------------------- MISSION COMPONENTS ----------------------------------------------//
const Missions = lazy(() => import(/* webpackChunkName: "Missions" */ 'Pages/Missions/Missions'));
const MissionAddOrUpdate = lazy(() =>
  import(/* webpackChunkName: "MissionAddOrUpdate" */ 'Pages/Missions/MissionAddOrUpdate')
);
const MissionView = lazy(() => import(/* webpackChunkName: "MissionView" */ 'Pages/Missions/MissionView'));
// ------------------------------------------------------- MISSION COMPONENTS ----------------------------------------------//
// ------------------------------------------------------- Transactions COMPONENTS ----------------------------------------------//
const Transactions = lazy(() => import(/* webpackChunkName: "Transactions" */ 'Pages/Transactions/Transactions'));
const TransactionsDetails = lazy(() =>
  import(/* webpackChunkName: "TransactionsDetails" */ 'Pages/Transactions/TransactionsDetails')
);
const TransactionsExpensesAdd = lazy(() =>
  import(/* webpackChunkName: "TransactionsExpensesAdd" */ 'Pages/Transactions/TransactionsExpensesAdd')
);
const TransactionsRechargeAdd = lazy(() =>
  import(/* webpackChunkName: "TransactionsRechargeAdd" */ 'Pages/Transactions/TransactionsRechargeAdd')
);
const TransactionsRechargeView = lazy(() =>
  import(/* webpackChunkName: "TransactionsRechargeView" */ 'Pages/Transactions/TransactionsRechargeView')
);
const TransactionsExpensesView = lazy(() =>
  import(/* webpackChunkName: "TransactionsExpensesView" */ 'Pages/Transactions/TransactionsExpensesView')
);
// ------------------------------------------------------- Transactions COMPONENTS ----------------------------------------------//
// ------------------------------------------------------- RECRUTEMENT COMPONENTS ----------------------------------------------//
const Candidates = lazy(() => import(/* webpackChunkName: "Candidates" */ 'Pages/Recrutements/Candidates'));
const CandidatesApplications = lazy(() =>
  import(/* webpackChunkName: "CandidatesApplications" */ 'Pages/Recrutements/CandidatesApplications')
);
const CandidatesApplicationsAdd = lazy(() =>
  import(/* webpackChunkName: "CandidatesApplicationsAdd" */ 'Pages/Recrutements/CandidatesApplicationsAdd')
);
const CandidatesApplicationsUpdate = lazy(() =>
  import(/* webpackChunkName: "CandidatesApplicationsUpdate" */ 'Pages/Recrutements/CandidatesApplicationsUpdate')
);
const CandidatesApplicationsView = lazy(() =>
  import(/* webpackChunkName: "CandidatesApplicationsView" */ 'Pages/Recrutements/CandidatesApplicationsView')
);
const CandidatesInterviewView = lazy(() =>
  import(/* webpackChunkName: "CandidatesInterviewView" */ 'Pages/Recrutements/CandidatesInterviewView')
);
const MyCantidates = lazy(() => import(/* webpackChunkName: "MyCantidates" */ 'Pages/Recrutements/MyCantidates'));
const Newsletter = lazy(() => import(/* webpackChunkName: "Newsletter" */ 'Pages/Recrutements/Newsletter'));
const RecruitmentRequests = lazy(() =>
  import(/* webpackChunkName: "RecruitmentRequests" */ 'Pages/Recrutements/RecruitmentRequests')
);
const RecruitmentRequestsAddUpdate = lazy(() =>
  import(
    /* webpackChunkName: "RecruitmentRequestsAddUpdate" */ 'Pages/Recrutements/RecruitmentRequests/RecruitmentRequestsAddUpdate'
  )
);
const RecruitmentRequestsView = lazy(() =>
  import(
    /* webpackChunkName: "RecruitmentRequestsView" */ 'Pages/Recrutements/RecruitmentRequests/RecruitmentRequestsView'
  )
);
// ------------------------------------------------------- RECRUTEMENT COMPONENTS ----------------------------------------------//
// ------------------------------------------------------- TIMEREGISTRATION COMPONENTS ----------------------------------------------//

const TimeRegistrationsAll = lazy(() =>
  import(/* webpackChunkName: "TimeRegistrationsAll" */ 'Pages/TimeRegistrations/TimeRegistrationsAll')
);
const TimeRegistrationsAllCollaboraters = lazy(() =>
  import(
    /* webpackChunkName: "TimeRegistrationsAllCollaboraters" */ 'Pages/TimeRegistrations/TimeRegistrationsAllCollaboraters'
  )
);
const TimeRegistrationsDailyGaps = lazy(() =>
  import(/* webpackChunkName: "TimeRegistrationsDailyGaps" */ 'Pages/TimeRegistrations/TimeRegistrationsDailyGaps')
);
// ------------------------------------------------------- TIMEREGISTRATION COMPONENTS ----------------------------------------------//
// ------------------------------------------------------- ABSENCES & LEAVES COMPONENTS ----------------------------------------------//
const AllLeaves = lazy(() => import(/* webpackChunkName: "AllLeaves" */ 'Pages/AbsencesLeaves/AllLeaves'));
const AllLeavesDetails = lazy(() =>
  import(/* webpackChunkName: "AllLeavesDetails" */ 'Pages/AbsencesLeaves/AllLeavesDetails')
);
const SpecialLeavesAdd = lazy(() =>
  import(/* webpackChunkName: "SpecialLeavesAdd" */ 'Pages/AbsencesLeaves/SpecialLeavesAdd')
);
const SpecialLeavesView = lazy(() =>
  import(/* webpackChunkName: "SpecialLeavesView" */ 'Pages/AbsencesLeaves/SpecialLeavesView')
);
const GrantedLeavesView = lazy(() =>
  import(/* webpackChunkName: "GrantedLeavesView" */ 'Pages/AbsencesLeaves/GrantedLeavesView')
);
const AllLeaveRequestsView = lazy(() =>
  import(/* webpackChunkName: "AllLeaveRequestsView" */ 'Pages/AbsencesLeaves/AllLeaveRequestsView')
);
const MyLeaves = lazy(() => import(/* webpackChunkName: "MyLeaves" */ 'Pages/AbsencesLeaves/MyLeaves'));
const MyLeaveRequestsView = lazy(() =>
  import(/* webpackChunkName: "MyLeaveRequestsView" */ 'Pages/AbsencesLeaves/MyLeaveRequestsView')
);
const AllLeavesRequests = lazy(() =>
  import(/* webpackChunkName: "AllLeavesRequests" */ 'Pages/AbsencesLeaves/AllLeavesRequests')
);
const AllLeaveRequestsAddOrUpdate = lazy(() =>
  import(/* webpackChunkName: "AllLeaveRequestsAddOrUpdate" */ 'Pages/AbsencesLeaves/AllLeaveRequestsAddOrUpdate')
);
const MyLeavesRequests = lazy(() =>
  import(/* webpackChunkName: "MyLeavesRequests" */ 'Pages/AbsencesLeaves/MyLeavesRequests')
);
const MyLeaveRequestsAddOrUpdate = lazy(() =>
  import(/* webpackChunkName: "MyLeaveRequestsAddOrUpdate" */ 'Pages/AbsencesLeaves/MyLeaveRequestsAddOrUpdate')
);
const TeamLeavesRequests = lazy(() =>
  import(/* webpackChunkName: "TeamLeavesRequests" */ 'Pages/AbsencesLeaves/TeamLeavesRequests')
);
const TeamLeaveRequestsView = lazy(() =>
  import(/* webpackChunkName: "TeamLeaveRequestsView" */ 'Pages/AbsencesLeaves/TeamLeaveRequestsView')
);
const AllAbsences = lazy(() => import(/* webpackChunkName: "AllAbsences" */ 'Pages/AbsencesLeaves/AllAbsences'));
const MyAbsences = lazy(() => import(/* webpackChunkName: "MyAbsences" */ 'Pages/AbsencesLeaves/MyAbsences'));
// ------------------------------------------------------- ABSENCES & LEAVES COMPONENTS ----------------------------------------------//
// ------------------------------------------------------- CALENDAR COMPONENTS ----------------------------------------------//
const Calendar = lazy(() => import(/* webpackChunkName: "Calendar" */ 'Pages/Calendar/HomeCalendar'));
const EventsAddOrUpdate = lazy(() =>
  import(/* webpackChunkName: "EventsAddOrUpdate" */ 'Pages/Calendar/EventsAddOrUpdate')
);
const EventView = lazy(() => import(/* webpackChunkName: "EventView" */ 'Pages/Calendar/EventView'));
const CalendarRapport = lazy(() =>
  import(/* webpackChunkName: "CalendarRapport" */ 'Pages/Calendar/CalendarRapport/CalendarRapport')
);
const StatusRapport = lazy(() =>
  import(/* webpackChunkName: "StatusRapport" */ 'Pages/Calendar/CalendarRapport/StatusRapport')
);
const CollaboratorRapport = lazy(() =>
  import(/* webpackChunkName: "CollaboratorRapport" */ 'Pages/Calendar/CalendarRapport/CollaboratorRapport')
);
const TypesRapport = lazy(() =>
  import(/* webpackChunkName: "TypesRapport" */ 'Pages/Calendar/CalendarRapport/TypesRapport')
);

// ------------------------------------------------------- CALENDAR COMPONENTS ----------------------------------------------//
// ------------------------------------------------------- SETTINGS COMPONENTS ----------------------------------------------//
const Jobs = lazy(() => import(/* webpackChunkName: "Jobs" */ 'Pages/Settings/Jobs/Jobs'));
const JobsAddOrUpdate = lazy(() =>
  import(/* webpackChunkName: "JobsAddOrUpdate" */ 'Pages/Settings/Jobs/JobsAddOrUpdate/JobsAddOrUpdate')
);
const Entities = lazy(() => import(/* webpackChunkName: "Entities" */ 'Pages/Settings/Entities/Entities'));
const Departments = lazy(() => import(/* webpackChunkName: "Departments" */ 'Pages/Settings/Departments/Departments'));
const DepartmentAddOrUpdate = lazy(() =>
  import(/* webpackChunkName: "DepartmentAddOrUpdate" */ 'Pages/Settings/Departments/DepartmentAddOrUpdate')
);
const DemandsSubjects = lazy(() =>
  import(/* webpackChunkName: "DemandsSubjects" */ 'Pages/Settings/DemandsSubjects/DemandsSubjects')
);
const DemandsSubjectsAddOrUpdate = lazy(() =>
  import(
    /* webpackChunkName: "DemandsSubjectsAddOrUpdate" */
    'Pages/Settings/DemandsSubjects/DemandsSubjectsAddOrUpdate/DemandsSubjectsAddOrUpdate'
  )
);
const ContractsArticleModels = lazy(() =>
  import(
    /* webpackChunkName: "ContractsArticleModels" */ 'Pages/Settings/ContractsArticleModels/ContractsArticleModels'
  )
);
const ContractsArticleModelsAddOrUpdate = lazy(() =>
  import('Pages/Settings/ContractsArticleModels/ContractsArticleModelsAddOrUpdate/ContractsArticleModelsAddOrUpdate')
);
const ContractsContractModels = lazy(() =>
  import(
    /* webpackChunkName: "ContractsContractModels" */ 'Pages/Settings/ContractsContractModels/ContractsContractModels'
  )
);
const ContractsContractModelsAddOrUpdate = lazy(() =>
  import(
    /* webpackChunkName: "ContractsContractModelsAddOrUpdate" */
    'Pages/Settings/ContractsContractModels/ContractsContractModelsAddOrUpdate/ContractsContractModelsAddOrUpdate'
  )
);
const Skills = lazy(() => import(/* webpackChunkName: "Skills" */ 'Pages/Settings/Skills/Skills'));
const SkillsAddOrUpdate = lazy(() =>
  import(/* webpackChunkName: "SkillsAddOrUpdate" */ 'Pages/Settings/Skills/SkillsAddOrUpdate/SkillsAddOrUpdate')
);
const HardwaresTypes = lazy(() =>
  import(/* webpackChunkName: "HardwaresTypes" */ 'Pages/Settings/HardwaresTypes/HardwaresTypes')
);
const HardwaresTypesAddOrUpdate = lazy(() =>
  import(
    /* webpackChunkName: "HardwaresTypesAddOrUpdate" */
    'Pages/Settings/HardwaresTypes/HardwaresTypesAddOrUpdate/HardwaresTypesAddOrUpdate'
  )
);
const SoftwareTypes = lazy(() =>
  import(/* webpackChunkName: "SoftwareTypes" */ 'Pages/Settings/SoftwareTypes/SoftwareTypes')
);
const SoftwareTypesAddOrUpdate = lazy(() =>
  import(
    /* webpackChunkName: "SoftwareTypesAddOrUpdate" */
    'Pages/Settings/SoftwareTypes/SoftwareTypesAddOrUpdate/SoftwareTypesAddOrUpdate'
  )
);
const Locations = lazy(() => import(/* webpackChunkName: "Locations" */ 'Pages/Settings/Locations/Locations'));
const LocationsAddOrUpdate = lazy(() =>
  import(
    /* webpackChunkName: "LocationsAddOrUpdate" */
    'Pages/Settings/Locations/LocationsAddOrUpdate/LocationsAddOrUpdate'
  )
);
const CompanyParams = lazy(() =>
  import(/* webpackChunkName: "CompanyParams" */ 'Pages/Settings/CompanyParams/CompanyParams')
);
const CompanyParamsAddOrUpdate = lazy(() =>
  import(
    /* webpackChunkName: "CompanyParamsAddOrUpdate" */ 'Pages/Settings/CompanyParams/CompanyParamsEdit/CompanyParamsEdit'
  )
);
const AclRoles = lazy(() => import(/* webpackChunkName: "AclRoles" */ 'Pages/Settings/AclRoles/AclRoles'));
const AclRolesAddOrUpdate = lazy(() =>
  import(
    /* webpackChunkName: "AclRolesAddOrUpdate" */ 'Pages/Settings/AclRoles/AclRolesAddOrUpdate/AclRolesAddOrUpdate'
  )
);
const AclRolesAllowActions = lazy(() =>
  import(
    /* webpackChunkName: "AclRolesAllowActions" */ 'Pages/Settings/AclRoles/AclRolesAllowActions/AclRolesAllowActions'
  )
);
const AclActions = lazy(() => import(/* webpackChunkName: "AclActions" */ 'Pages/Settings/AclActions/AclActions'));
const TimeRegistrationHolidays = lazy(() =>
  import(
    /* webpackChunkName: "TimeRegistrationHolidays" */ 'Pages/Settings/TimeRegistrationHolidays/TimeRegistrationHolidays'
  )
);
const TimeRegistrationHolidaysAddOrUpdate = lazy(() =>
  import(
    /* webpackChunkName: "TimeRegistrationHolidaysAddOrUpdate" */
    'Pages/Settings/TimeRegistrationHolidays/TimeRegistrationHolidaysAddOrUpdate/TimeRegistrationHolidaysAddOrUpdate'
  )
);
const TimeRegistrationDefaultTimetable = lazy(() =>
  import(
    /* webpackChunkName: "TimeRegistrationDefaultTimetable" */
    'Pages/Settings/TimeRegistrationDefaultTimetable/TimeRegistrationDefaultTimetable'
  )
);
const TimeRegistrationDefaultTimetableEdit = lazy(() =>
  import(
    /* webpackChunkName: "TimeRegistrationDefaultTimetableEdit" */
    'Pages/Settings/TimeRegistrationDefaultTimetable/TimeRegistrationDefaultTimetableEdit/TimeRegistrationDefaultTimetableEdit'
  )
);
const TransactionsExpenses = lazy(() =>
  import(/* webpackChunkName: "TransactionsExpenses" */ 'Pages/Settings/TransactionsExpenses/TransactionsExpenses')
);
const TransactionsExpensesAddOrUpdate = lazy(() =>
  import(
    /* webpackChunkName: "TransactionsExpensesAddOrUpdate" */
    'Pages/Settings/TransactionsExpenses/TransactionsExpensesAddOrUpdate/TransactionsExpensesAddOrUpdate'
  )
);
const TransactionsRecharges = lazy(() =>
  import(/* webpackChunkName: "TransactionsRecharges" */ 'Pages/Settings/TransactionsRecharges/TransactionsRecharges')
);
const TransactionsRechargesAddOrUpdate = lazy(() =>
  import(
    /* webpackChunkName: "TransactionsRechargesAddOrUpdate" */
    'Pages/Settings/TransactionsRecharges/TransactionsRechargesAddOrUpdate/TransactionsRechargesAddOrUpdate'
  )
);
const TransactionsKilometers = lazy(() =>
  import(
    /* webpackChunkName: "TransactionsKilometers" */ 'Pages/Settings/TransactionsKilometers/TransactionsKilometers'
  )
);
const TransactionsKilometersEdit = lazy(() =>
  import(
    /* webpackChunkName: "TransactionsKilometersEdit" */
    'Pages/Settings/TransactionsKilometers/TransactionsKilometersEdit/TransactionsKilometersEdit'
  )
);
const LeavesSpecialLeavesTypes = lazy(() =>
  import(
    /* webpackChunkName: "LeavesSpecialLeavesTypes" */ 'Pages/Settings/LeavesSpecialLeavesTypes/LeavesSpecialLeavesTypes'
  )
);
const LeavesSpecialLeavesTypesAddOrUpdate = lazy(() =>
  import(
    /* webpackChunkName: "LeavesSpecialLeavesTypesAddOrUpdate" */
    'Pages/Settings/LeavesSpecialLeavesTypes/LeavesSpecialLeavesTypesAddOrUpdate/LeavesSpecialLeavesTypesAddOrUpdate'
  )
);
const LeavesAnnualLeavesParams = lazy(() =>
  import(
    /* webpackChunkName: "LeavesAnnualLeavesParams" */ 'Pages/Settings/LeavesAnnualLeavesParams/LeavesAnnualLeavesParams'
  )
);
const AnnualLeavesParamsAddOrUpdate = lazy(() =>
  import(
    /* webpackChunkName: "AnnualLeavesParamsAddOrUpdate" */
    'Pages/Settings/LeavesAnnualLeavesParams/AnnualLeavesParamsAddOrUpdate/AnnualLeavesParamsAddOrUpdate'
  )
);
const EmailModels = lazy(() => import(/* webpackChunkName: "EmailModels" */ 'Pages/Settings/Email/EmailModels'));
const SpontaneousApplicationModel = lazy(() =>
  import(/* webpackChunkName: "SpontaneousApplicationModel" */ 'Pages/Settings/Email/SpontaneousApplicationModel')
);
const JobOfferModel = lazy(() => import(/* webpackChunkName: "JobOfferModel" */ 'Pages/Settings/Email/JobOfferModel'));
const Teams = lazy(() => import(/* webpackChunkName: "Teams" */ 'Pages/Settings/Planning/Teams/Teams'));
const Events = lazy(() => import(/* webpackChunkName: "Events" */ 'Pages/Settings/Planning/Events/Events'));
const TaskModels = lazy(() => import(/* webpackChunkName: "TaskModels" */ 'Pages/Settings/TaskModels/TaskModels'));
const TaskModelsAddOrUpdate = lazy(() =>
  import(/* webpackChunkName: "TaskModelsAddOrUpdate" */ 'Pages/Settings/TaskModels/TaskModelsAddOrUpdate')
);

// ------------------------------------------------------- SETTINGS COMPONENTS ----------------------------------------------//
// ------------------------------------------------------- CSV COMPONENTS ----------------------------------------------//
const CsvModifier = lazy(() => import(/* webpackChunkName: "CsvModifier" */ 'Pages/CsvModifier/CsvModifier'));
// ------------------------------------------------------- CSV COMPONENTS ----------------------------------------------//
// ------------------------------------------------------- PAYRISES COMPONENTS ----------------------------------------------//
const PayRisesAddOrUpdate = lazy(() =>
  import(/* webpackChunkName: "PayRisesAddOrUpdate" */ 'Pages/PayRises/PayRisesAddOrUpdate')
);
const PayRisesView = lazy(() => import(/* webpackChunkName: "PayRisesView" */ 'Pages/PayRises/PayRisesView'));
// ------------------------------------------------------- PAYRISES COMPONENTS ----------------------------------------------//

function MainNavigator() {
  const location = window.location.pathname;
  const initialQueryState = {
    page: 1,
    limit: 10,
  };
  const [queryState, setQueryState] = useState(initialQueryState);

  const { user } = useContext(AuthContext);
  const {
    state: defaultState,
    getNotifications,
    loadingNotifications,
    dispatch,
  } = useNotifications(user?.id, queryState);

  useEffect(() => Aos.init({ once: true }));
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(window?.matchMedia('(max-width: 900px)')?.matches);
    window?.addEventListener('resize', () => {
      window?.innerWidth > 900 ? setIsMobile(false) : setIsMobile(true);
    });
    if (EXT === 'com') {
      const script = document.createElement('script');
      script.text = `!function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}(p=t.createElement("script")).type="text/javascript",p.async=!0,p.src=s.api_host+"/static/array.js",(r=t.getElementsByTagName("script")[0]).parentNode.insertBefore(p,r);var u=e;for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return u.toString(1)+".people (stub)"},o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags".split(" "),n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);
posthog.init('phc_6ib4tIGMCaNgJDSGt9HiWrBnSPzQeK8VZF26N5u3Bkt',{api_host:'https://app.posthog.com'})`;
      document.head.appendChild(script);
      const beusable = document.createElement('script');
      beusable.setAttribute('type', 'text/javascript');
      beusable.text = `(function(w, d, a){
          w.__beusablerumclient__ = {
              load : function(src){
                  var b = d.createElement("script");
                  b.src = src; b.async=true; b.type = "text/javascript";
                  d.getElementsByTagName("head")[0].appendChild(b);
              }
          };w.__beusablerumclient__.load(a + "?url=" + encodeURIComponent(d.URL));
      })(window, document, "//rum.beusable.net/load/b220902e185435u483");`;
      document.head.appendChild(beusable);
    }
    const meta = document.createElement('meta');
    meta.setAttribute('name', 'description');
    meta.setAttribute(
      'content',
      'Sobrus Workspace est une solution de communication interne qui vise à renforcer notre culture d’entreprise, resserrer les liens entre les collaborateurs et gérer toute la partie RH'
    );
    document.head.appendChild(meta);
    mixpanel.init('e8022dc7aefa6baada7e48d013166cd6', { debug: true });
    mixpanel.track('All app');
    document.title = 'Sobrus Workspace - La solution préférée de nos collaborateurs !  ';
    return () => window.removeEventListener('resize', null);
  }, []);

  useSwr(`/collaboraters/${user?.id}/notifications?page=${queryState.page}&limit=10`, user, defaultState, dispatch);
  const value = useMemo(
    () => ({
      state: defaultState,
      loadingNotifications,
      getNotifications,
      dispatch,
      queryState,
      setQueryState,
    }),
    [defaultState, loadingNotifications, getNotifications, dispatch, queryState, setQueryState]
  );
  return (
    <NotificationsContext.Provider value={value}>
      <Router>
        <Suspense fallback={null}>
          {location !== '/auth/login' &&
            location !== '/' &&
            location !== '/denied_access' &&
            location !== '/404' &&
            location !== '/error' && <ProtectedHeader />}
        </Suspense>
        <Switch>
          <Suspense fallback={<PagesLoader />}>
            <Route exact path="/" render={(props) => <LandingPage {...props} />} />
            <Route exact path="/auth/login" render={(props) => <SignIn {...props} />} />
            {isMobile ? (
              <Mobile />
            ) : (
              <>
                <PrivateRoutes
                  exact
                  to="/auth/success"
                  component={<Redirect to="/home" />}
                  does={ROUTES.AUTH_SUCCESS}
                />

                <PrivateRoutes exact to="/home" component={<Home />} does={ROUTES.HOME} />
                <PrivateRoutes exact to="/profile" component={<Profile />} does={ROUTES.PROFILE} />
                {/* ------------------------------------------------------- NOTIFICATION  ---------------------------------------------- */}
                <PrivateRoutes exact to="/notifications" component={<Notifications />} does={ROUTES.NOTIFICATIONS} />
                <PrivateRoutes
                  exact
                  to="/notifications/history"
                  component={<NotificationsHistory />}
                  does={ROUTES.NOTIFICATIONS__HISTORY}
                />
                {/* ------------------------------------------------------- NOTIFICATION  ---------------------------------------------- */}
                {/* ------------------------------------------------------- COLLABORATERS  ---------------------------------------------- */}
                <PrivateRoutes exact to="/collaboraters" component={<Collaboraters />} does={ROUTES.COLLABORATERS} />
                <PrivateRoutes
                  exact
                  to="/collaboraters/add_or_update"
                  component={<CollaboratersAddOrUpdate />}
                  does={ROUTES.COLLABORATER__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/collaboraters/add_or_update/:id"
                  component={<CollaboratersAddOrUpdate />}
                  does={ROUTES.COLLABORATER__UPDATE}
                />
                <PrivateRoutes
                  exact
                  to="/collaboraters/view/:id"
                  component={<CollaboraterView />}
                  does={ROUTES.COLLABORATER__VIEW}
                />
                <PrivateRoutes
                  exact
                  to="/collaboraters/:id/interviews/add"
                  component={<CollaboratersInterviewAdd />}
                  does={ROUTES.COLLABORATER__INTERVIEW__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/collaboraters/update_phonebook_params/:id"
                  component={<UpdatePhonebookParams />}
                  does={ROUTES.COLLABORATER__PHONEBOOKPARAMS__UPDATE}
                />
                <PrivateRoutes
                  exact
                  to="/collaboraters/update_account_params/:id"
                  component={<UpdateAccountParams />}
                  does={ROUTES.COLLABORATER__ACCOUNTPARAMS__UPDATE}
                />
                <PrivateRoutes
                  exact
                  to="/collaboraters/update_schedule/:id"
                  component={<CollaboratersUpdateShedule />}
                  does={ROUTES.COLLABORATER__SHEDULE__UPDATE}
                />
                {/* ------------------------------------------------------- COLLABORATERS  ---------------------------------------------- */}
                {/* ------------------------------------------------------- COLLABORATERS INTERVIEWS  ---------------------------------------------- */}
                <PrivateRoutes
                  exact
                  to="/collaboraters/interviews"
                  component={<CollaBorateursInterviews />}
                  does={ROUTES.INTERVIEWS}
                />
                <PrivateRoutes
                  exact
                  to="/collaboraters/interviews/view/:id"
                  component={<CollaBorateursInterviewsView />}
                  does={ROUTES.INTERVIEWS__VIEW}
                />
                <PrivateRoutes
                  exact
                  to="/collaboraters/interviews/update/:id"
                  component={<CollaborateursInterviewsUpdate />}
                  does={ROUTES.INTERVIEWS__UPDATE}
                />
                {/* ------------------------------------------------------- COLLABORATERS INTERVIEWS  ---------------------------------------------- */}
                {/* ------------------------------------------------------- REQUESTS  ---------------------------------------------- */}
                <PrivateRoutes exact to="/requests/all-requests" component={<AllRequests />} does={ROUTES.REQUESTS} />
                <PrivateRoutes exact to="/requests/view/:id" component={<RequestView />} does={ROUTES.REQUESTS__VIEW} />
                <PrivateRoutes exact to="/requests/my-requests" component={<MyRequests />} does={ROUTES.MY__REQUESTS} />
                <PrivateRoutes
                  exact
                  to="/requests/my-requests/view/:id"
                  component={<MyRequestView />}
                  does={ROUTES.MY__REQUESTS__VIEW}
                />
                <PrivateRoutes
                  exact
                  to="/requests/add_or_update"
                  component={<RequestAddOrUpdate />}
                  does={ROUTES.REQUESTS__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/requests/add_or_update/:id"
                  component={<RequestAddOrUpdate />}
                  does={ROUTES.REQUESTS__UPDATE}
                />
                <PrivateRoutes
                  exact
                  to="/requests/my-team-requests"
                  component={<TeamRequests />}
                  does={ROUTES.TEAM__REQUESTS}
                />
                <PrivateRoutes
                  exact
                  to="/requests/my-team-requests/view/:id"
                  component={<TeamRequestView />}
                  does={ROUTES.TEAM__REQUESTS__VIEW}
                />
                {/* ------------------------------------------------------- REQUESTS  ---------------------------------------------- */}
                {/* ------------------------------------------------------- CONTRACTS  ---------------------------------------------- */}
                <PrivateRoutes exact to="/contracts" component={<Contrats />} does={ROUTES.CONTRACTS} />
                <PrivateRoutes
                  exact
                  to="/contracts/generate_contract"
                  component={<GenerateContract />}
                  does={ROUTES.CONTRACTS__CREATE}
                />
                {/* ------------------------------------------------------- CONTRACTS  ---------------------------------------------- */}
                {/* ------------------------------------------------------- GALERIE  ---------------------------------------------- */}
                <PrivateRoutes exact to="/galerie" component={<Galerie />} does={ROUTES.GALERIE} />
                <PrivateRoutes
                  exact
                  to="/galerie/add_or_update"
                  component={<GalerieAddOrUpdate />}
                  does={ROUTES.GALERIE__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/galerie/add_or_update/:id"
                  component={<GalerieAddOrUpdate />}
                  does={ROUTES.GALERIE__UPDATE}
                />
                <PrivateRoutes exact to="/galerie/view/:id" component={<GalerieView />} does={ROUTES.GALERIE__VIEW} />
                {/* ------------------------------------------------------- GALERIE  ---------------------------------------------- */}
                {/* ------------------------------------------------------- ACTIVITES  ---------------------------------------------- */}
                <PrivateRoutes exact to="/activities" component={<ActivitesList />} does={ROUTES.ACTIVITES} />
                <PrivateRoutes
                  exact
                  to="/activities/add_or_update"
                  component={<ActivitesAddOrUpdate />}
                  does={ROUTES.ACTIVITES__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/activities/add_or_update/:id"
                  component={<ActivitesAddOrUpdate />}
                  does={ROUTES.ACTIVITES__UPDATE}
                />
                <PrivateRoutes
                  exact
                  to="/activities/view/:id"
                  component={<ActivitesView />}
                  does={ROUTES.ACTIVITES__VIEW}
                />
                {/* ------------------------------------------------------- ACTIVITES  ---------------------------------------------- */}
                {/* ------------------------------------------------------- HARDWARES  ---------------------------------------------- */}
                <PrivateRoutes exact to="/hardwares" component={<Hardwares />} does={ROUTES.HARDWARES} />
                <PrivateRoutes
                  exact
                  to="/hardwares/view/:id"
                  component={<HardwaresView />}
                  does={ROUTES.HARDWARES__VIEW}
                />
                <PrivateRoutes
                  exact
                  to="/hardwares/add_or_update"
                  component={<HardwaresAddOrUpdate />}
                  does={ROUTES.HARDWARES__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/hardwares/add_or_update/:id"
                  component={<HardwaresAddOrUpdate />}
                  does={ROUTES.HARDWARES__UPDATE}
                />
                {/* ------------------------------------------------------- HARDWARES  ---------------------------------------------- */}
                {/* ------------------------------------------------------- HARDWARES  ---------------------------------------------- */}
                <PrivateRoutes exact to="/softwares" component={<SoftWares />} does={ROUTES.SOFTWARES} />
                <PrivateRoutes
                  exact
                  to="/softwares/view/:id"
                  component={<SoftWaresView />}
                  does={ROUTES.SOFTWARES__VIEW}
                />
                <PrivateRoutes
                  exact
                  to="/softwares/add_or_update"
                  component={<SoftWaresAddOrUpdate />}
                  does={ROUTES.SOFTWARES__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/softwares/add_or_update/:id"
                  component={<SoftWaresAddOrUpdate />}
                  does={ROUTES.SOFTWARES__UPDATE}
                />
                {/* ------------------------------------------------------- HARDWARES  ---------------------------------------------- */}
                {/* ------------------------------------------------------- MISSIONS  ---------------------------------------------- */}
                <PrivateRoutes exact to="/missions" component={<Missions />} does={ROUTES.MISSIONS} />
                <PrivateRoutes exact to="/missions/view/:id" component={<MissionView />} does={ROUTES.MISSIONS__VIEW} />
                <PrivateRoutes
                  exact
                  to="/missions/add_or_update"
                  component={<MissionAddOrUpdate />}
                  does={ROUTES.MISSIONS__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/missions/add_or_update/:id"
                  component={<MissionAddOrUpdate />}
                  does={ROUTES.MISSIONS__UPDATE}
                />
                {/* ------------------------------------------------------- MISSIONS  ---------------------------------------------- */}
                {/* ------------------------------------------------------- PAYRISES  ---------------------------------------------- */}
                <PrivateRoutes
                  exact
                  to="/collaboraters/:user_id/pay-rises/add_or_update"
                  component={<PayRisesAddOrUpdate />}
                  does={ROUTES.PAYRISES__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/collaboraters/:user_id/pay-rises/add_or_update/:id"
                  component={<PayRisesAddOrUpdate />}
                  does={ROUTES.PAYRISES__UPDATE}
                />
                <PrivateRoutes
                  exact
                  to="/pay-rises/view/:id"
                  component={<PayRisesView />}
                  does={ROUTES.PAYRISES__VIEW}
                />
                {/* ------------------------------------------------------- PAYRISES  ---------------------------------------------- */}
                {/* ------------------------------------------------------- TRANSACTIONS  ---------------------------------------------- */}
                <PrivateRoutes exact to="/transactions" component={<Transactions />} does={ROUTES.TRANSACTIONS} />
                <PrivateRoutes
                  exact
                  to="/transactions/details/:id"
                  component={<TransactionsDetails />}
                  does={ROUTES.TRANSACTIONS__VIEW}
                />
                <PrivateRoutes
                  exact
                  to="/transactions/expenses/add"
                  component={<TransactionsExpensesAdd />}
                  does={ROUTES.TRANSACTIONS__EXPENSES__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/transactions/recharges/add"
                  component={<TransactionsRechargeAdd />}
                  does={ROUTES.TRANSACTIONS__RECHARGE__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/transactions/recharges/view/:id"
                  component={<TransactionsRechargeView />}
                  does={ROUTES.TRANSACTIONS__RECHARGE__VIEW}
                />
                <PrivateRoutes
                  exact
                  to="/transactions/expenses/view/:id"
                  component={<TransactionsExpensesView />}
                  does={ROUTES.TRANSACTIONS__EXPENSES__VIEW}
                />
                {/* ------------------------------------------------------- TRANSACTIONS  ---------------------------------------------- */}
                {/* ------------------------------------------------------- RECRUTEMENT  ---------------------------------------------- */}
                <PrivateRoutes
                  exact
                  to="/recruitment/candidates/applications"
                  component={<CandidatesApplications />}
                  does={ROUTES.RECRUTEMENT__APPLICATIONS}
                />
                <PrivateRoutes
                  exact
                  to="/recruitement/candidates/applications/view/:id"
                  component={<CandidatesApplicationsView />}
                  does={ROUTES.RECRUTEMENT__APPLICATIONS__VIEW}
                />
                <PrivateRoutes
                  exact
                  to="/recruitement/candidates/applications/:id/interviews/add"
                  component={<CandidatesApplicationsAdd />}
                  does={ROUTES.RECRUTEMENT__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/recruitement/candidates/applications/interviews/view/:id"
                  component={<CandidatesInterviewView />}
                  does={ROUTES.RECRUTEMENT__INTERVIEW__VIEW}
                />
                <PrivateRoutes
                  exact
                  to="/recruitement/candidates/applications/interviews/update/:id"
                  component={<CandidatesApplicationsUpdate />}
                  does={ROUTES.RECRUTEMENT__UPDATE}
                />
                <PrivateRoutes
                  exact
                  to="/recruitment/candidates"
                  component={<Candidates />}
                  does={ROUTES.RECRUTEMENT__CANDIDATES}
                />
                <PrivateRoutes
                  exact
                  to="/recruitment/my-candidates/applications"
                  component={<MyCantidates />}
                  does={ROUTES.RECRUTEMENT__MYCANDIDATE}
                />
                <PrivateRoutes
                  exact
                  to="/recruitment/newsletter"
                  component={<Newsletter />}
                  does={ROUTES.RECRUTEMENT__NEWSLETTER}
                />
                <PrivateRoutes
                  exact
                  to="/recruitment/recruitment-requests"
                  component={<RecruitmentRequests />}
                  does={ROUTES.RECRUTEMENTREQUESTS}
                />
                <PrivateRoutes
                  exact
                  to="/recruitment/recruitment-requests/view/:id"
                  component={<RecruitmentRequestsView />}
                  does={ROUTES.RECRUTEMENTREQUESTS__VIEW}
                />
                <PrivateRoutes
                  exact
                  to="/recruitment/recruitment-requests/add_or_update"
                  component={<RecruitmentRequestsAddUpdate />}
                  does={ROUTES.RECRUTEMENTREQUESTS__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/recruitment/recruitment-requests/add_or_update/:id"
                  component={<RecruitmentRequestsAddUpdate />}
                  does={ROUTES.RECRUTEMENTREQUESTS__UPDATE}
                />
                {/* ------------------------------------------------------- RECRUTEMENT  ---------------------------------------------- */}
                {/* ------------------------------------------------------- TIMEREGISTRATION  ---------------------------------------------- */}
                <PrivateRoutes
                  exact
                  to="/time-registrations/all-collaboraters"
                  component={<TimeRegistrationsAllCollaboraters />}
                  does={ROUTES.TIMEREGISTRANTION__ALLCOLLABORATERS}
                />
                <PrivateRoutes
                  exact
                  to="/time-registrations/all"
                  component={<TimeRegistrationsAll />}
                  does={ROUTES.TIMEREGISTRANTION__ALL}
                />
                <PrivateRoutes
                  exact
                  to="/time-registrations/daily-gaps"
                  component={<TimeRegistrationsDailyGaps />}
                  does={ROUTES.TIMEREGISTRANTION__DAILYGAP}
                />
                {/* ------------------------------------------------------- TIMEREGISTRATION  ---------------------------------------------- */}
                {/* ------------------------------------------------------- LEAVES  ---------------------------------------------- */}
                <PrivateRoutes
                  exact
                  to="/absences-leaves/all-leaves"
                  component={<AllLeaves />}
                  does={ROUTES.ALLLEAVES}
                />
                <PrivateRoutes
                  exact
                  to="/absenses-leaves/special-leaves/add"
                  component={<SpecialLeavesAdd />}
                  does={ROUTES.SPECIALLEAVES__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/absences-leaves/all-leaves/:id/details"
                  component={<AllLeavesDetails />}
                  does={ROUTES.ALLLEAVES__VIEW}
                />
                <PrivateRoutes
                  exact
                  to="/absenses-leaves/special-leaves/view/:id"
                  component={<SpecialLeavesView />}
                  does={ROUTES.SPECIALLEAVES__VIEW}
                />
                <PrivateRoutes
                  exact
                  to="/absenses-leaves/granted-leaves/view/:id"
                  component={<GrantedLeavesView />}
                  does={ROUTES.GRANTEDLEAVES__VIEW}
                />
                <PrivateRoutes
                  exact
                  to="/absenses-leaves/all-leave-requests/view/:id"
                  component={<AllLeaveRequestsView />}
                  does={ROUTES.ALLLEAVES__REQUEST__VIEW}
                />
                <PrivateRoutes exact to="/absences-leaves/my-leaves" component={<MyLeaves />} does={ROUTES.MYLEAVES} />
                <PrivateRoutes
                  exact
                  to="/absenses-leaves/my-leave-requests/view/:id"
                  component={<MyLeaveRequestsView />}
                  does={ROUTES.MYLEAVES__VIEW}
                />
                <PrivateRoutes
                  exact
                  to="/absences-leaves/all-leave-requests"
                  component={<AllLeavesRequests />}
                  does={ROUTES.ALLLEAVES__REQUESTS}
                />
                <PrivateRoutes
                  exact
                  to="/absenses-leaves/all-leave-requests/add_or_uptade"
                  component={<AllLeaveRequestsAddOrUpdate />}
                  does={ROUTES.ALLLEAVES__REQUESTS__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/absences-leaves/my-leave-requests"
                  component={<MyLeavesRequests />}
                  does={ROUTES.MYLEAVES__REQUESTS}
                />
                <PrivateRoutes
                  exact
                  to="/absences-leaves/my-leave-requests/add_or_update"
                  component={<MyLeaveRequestsAddOrUpdate />}
                  does={ROUTES.MYLEAVES__REQUESTS__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/absences-leaves/team-leave-requests"
                  component={<TeamLeavesRequests />}
                  does={ROUTES.TEAMLEAVES__REQUESTS}
                />
                <PrivateRoutes
                  exact
                  to="/absences-leaves/team-leave-requests/view/:id"
                  component={<TeamLeaveRequestsView />}
                  does={ROUTES.TEAMLEAVES__REAUEST__VIEW}
                />
                <PrivateRoutes
                  exact
                  to="/absences-leaves/all-absences"
                  component={<AllAbsences />}
                  does={ROUTES.ALLABSENCES}
                />
                <PrivateRoutes
                  exact
                  to="/absences-leaves/my-absences"
                  component={<MyAbsences />}
                  does={ROUTES.MYABSENCES}
                />
                {/* ------------------------------------------------------- LEAVES  ---------------------------------------------- */}
                {/* ------------------------------------------------------- CALENDAR  ---------------------------------------------- */}
                <PrivateRoutes exact to="/events" component={<Calendar />} does={ROUTES.EVENTS} onOfdoes />
                <PrivateRoutes
                  exact
                  to="/events/add_or_update"
                  component={<EventsAddOrUpdate />}
                  does={ROUTES.EVENTS__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/events/add_or_update/:id"
                  component={<EventsAddOrUpdate />}
                  does={ROUTES.EVENTS__UPDATE}
                />
                <PrivateRoutes exact to="/events/view/:id" component={<EventView />} does={ROUTES.EVENTS__VIEW} />
                <PrivateRoutes
                  exact
                  to="/events/statistics"
                  component={<CalendarRapport />}
                  does={ROUTES.ECENTS__STATSTICS}
                  onOfdoes
                />
                <PrivateRoutes
                  exact
                  to="/events/statistics/status"
                  component={<StatusRapport />}
                  does={ROUTES.ECENTS__STATSTICS__STATUS}
                />
                <PrivateRoutes
                  exact
                  to="/events/statistics/collaboraters"
                  component={<CollaboratorRapport />}
                  does={ROUTES.ECENTS__STATSTICS__COLLABORATERS}
                />
                <PrivateRoutes
                  exact
                  to="/events/statistics/types"
                  component={<TypesRapport />}
                  does={ROUTES.ECENTS__STATSTICS__TYPES}
                />
                {/* ------------------------------------------------------- CALENDAR  ---------------------------------------------- */}
                {/* ------------------------------------------------------- SETTINGS  ---------------------------------------------- */}
                <PrivateRoutes exact to="/settings/jobs" component={<Jobs />} does={ROUTES.JOBS} />
                <PrivateRoutes
                  exact
                  to="/settings/jobs/add_or_update"
                  component={<JobsAddOrUpdate />}
                  does={ROUTES.JOBS__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/settings/jobs/add_or_update/:id"
                  component={<JobsAddOrUpdate />}
                  does={ROUTES.JOBS__UPDATE}
                />
                <PrivateRoutes exact to="/settings/entities" component={<Entities />} does={ROUTES.ENTITIES} />
                <PrivateRoutes exact to="/settings/departments" component={<Departments />} does={ROUTES.DEPARTEMENT} />
                <PrivateRoutes
                  exact
                  to="/settings/departments/add_or_update"
                  component={<DepartmentAddOrUpdate />}
                  does={ROUTES.DEPARTEMENT__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/settings/departments/add_or_update/:id"
                  component={<DepartmentAddOrUpdate />}
                  does={ROUTES.DEPARTEMENT__UPDATE}
                />
                <PrivateRoutes
                  exact
                  to="/settings/demands/subjects"
                  component={<DemandsSubjects />}
                  does={ROUTES.DEMANDE__SUBJECT}
                />
                <PrivateRoutes
                  exact
                  to="/settings/demands/subjects/add_or_update"
                  component={<DemandsSubjectsAddOrUpdate />}
                  does={ROUTES.DEMANDE__SUBJECT__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/settings/demands/subjects/add_or_update/:id"
                  component={<DemandsSubjectsAddOrUpdate />}
                  does={ROUTES.DEMANDE__SUBJECT__UPDATE}
                />
                <PrivateRoutes
                  exact
                  to="/settings/contracts/article_models"
                  component={<ContractsArticleModels />}
                  does={ROUTES.CONTRACTS__ARTICLEMODEL}
                />
                <PrivateRoutes
                  exact
                  to="/settings/contracts/article_models/add_or_update"
                  component={<ContractsArticleModelsAddOrUpdate />}
                  does={ROUTES.CONTRACTS__ARTICLEMODEL__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/settings/contracts/article_models/add_or_update/:id"
                  component={<ContractsArticleModelsAddOrUpdate />}
                  does={ROUTES.CONTRACTS__ARTICLEMODEL__UPDATE}
                />
                <PrivateRoutes
                  exact
                  to="/settings/contracts/contract_models"
                  component={<ContractsContractModels />}
                  does={ROUTES.CONTRACTS__CONTACTMODEL}
                />
                <PrivateRoutes
                  exact
                  to="/settings/contracts/contract_models/add_or_update"
                  component={<ContractsContractModelsAddOrUpdate />}
                  does={ROUTES.CONTRACTS__CONTACTMODEL__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/settings/contracts/contract_models/add_or_update/:id"
                  component={<ContractsContractModelsAddOrUpdate />}
                  does={ROUTES.CONTRACTS__CONTACTMODEL__UPDATE}
                />
                <PrivateRoutes exact to="/settings/skills" component={<Skills />} does={ROUTES.SKILLS} />
                <PrivateRoutes
                  exact
                  to="/settings/skills/add_or_update"
                  component={<SkillsAddOrUpdate />}
                  does={ROUTES.SKILLS__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/settings/skills/add_or_update/:id"
                  component={<SkillsAddOrUpdate />}
                  does={ROUTES.SKILLS__UPDATE}
                />
                <PrivateRoutes
                  exact
                  to="/settings/hardwares/types"
                  component={<HardwaresTypes />}
                  does={ROUTES.HARDWARESTYPE}
                />
                <PrivateRoutes
                  exact
                  to="/settings/hardwares/types/add_or_update"
                  component={<HardwaresTypesAddOrUpdate />}
                  does={ROUTES.HARDWARESTYPE__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/settings/hardwares/types/add_or_update/:id"
                  component={<HardwaresTypesAddOrUpdate />}
                  does={ROUTES.HARDWARESTYPE__UPDATE}
                />
                <PrivateRoutes
                  exact
                  to="/settings/software/types"
                  component={<SoftwareTypes />}
                  does={ROUTES.SOFTWARETYPE}
                />
                <PrivateRoutes
                  exact
                  to="/settings/software/types/add_or_update"
                  component={<SoftwareTypesAddOrUpdate />}
                  does={ROUTES.SOFTWARETYPE__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/settings/software/types/add_or_update/:id"
                  component={<SoftwareTypesAddOrUpdate />}
                  does={ROUTES.SOFTWARETYPE__UPDATE}
                />
                <PrivateRoutes exact to="/settings/locations" component={<Locations />} does={ROUTES.LOCATIONS} />
                <PrivateRoutes
                  exact
                  to="/settings/locations/add_or_update"
                  component={<LocationsAddOrUpdate />}
                  does={ROUTES.LOCATIONS__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/settings/locations/add_or_update/:id"
                  component={<LocationsAddOrUpdate />}
                  does={ROUTES.LOCATIONS__UPDATE}
                />
                <PrivateRoutes
                  exact
                  to="/settings/company/params"
                  component={<CompanyParams />}
                  does={ROUTES.COMPANYPARAMS}
                />
                <PrivateRoutes
                  exact
                  to="/settings/company/params/edit"
                  component={<CompanyParamsAddOrUpdate />}
                  does={ROUTES.COMPANYPARAMS__UPDATE}
                />
                <PrivateRoutes exact to="/settings/acl/roles" component={<AclRoles />} does={ROUTES.ACLROLES} />
                <PrivateRoutes
                  exact
                  to="/settings/acl/roles/add_or_update"
                  component={<AclRolesAddOrUpdate />}
                  does={ROUTES.ACLROLES__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/settings/acl/roles/add_or_update/:id"
                  component={<AclRolesAddOrUpdate />}
                  does={ROUTES.ACLROLES__UPDATE}
                />
                <PrivateRoutes
                  exact
                  to="/settings/acl/roles/allow_actions/:id"
                  component={<AclRolesAllowActions />}
                  does={ROUTES.ACLROLES__ALOWEDACTIONS}
                />
                <PrivateRoutes exact to="/settings/acl/actions" component={<AclActions />} does={ROUTES.ACLACTIONS} />
                <PrivateRoutes
                  exact
                  to="/settings/time-registration/holidays"
                  component={<TimeRegistrationHolidays />}
                  does={ROUTES.TIMEREGISTRATION__HOLIDAYS}
                />
                <PrivateRoutes
                  exact
                  to="/settings/time-registration/holidays/add_or_update"
                  component={<TimeRegistrationHolidaysAddOrUpdate />}
                  does={ROUTES.TIMEREGISTRATION__HOLIDAYS__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/settings/time-registration/holidays/add_or_update/:id"
                  component={<TimeRegistrationHolidaysAddOrUpdate />}
                  does={ROUTES.TIMEREGISTRATION__HOLIDAYS__UPDATE}
                />
                <PrivateRoutes
                  exact
                  to="/settings/time-registration/default-timetable"
                  component={<TimeRegistrationDefaultTimetable />}
                  does={ROUTES.TIMEREGISTRATION__DEFAULT}
                />
                <PrivateRoutes
                  exact
                  to="/settings/time-registration/default-timetable/EDIT"
                  component={<TimeRegistrationDefaultTimetableEdit />}
                  does={ROUTES.TIMEREGISTRATION__DEFAULT__UPDATE}
                />
                <PrivateRoutes
                  exact
                  to="/settings/transactions/expenses"
                  component={<TransactionsExpenses />}
                  does={ROUTES.TRANSACTIONEXPENCES}
                />
                <PrivateRoutes
                  exact
                  to="/settings/transactions/expenses/add_or_update"
                  component={<TransactionsExpensesAddOrUpdate />}
                  does={ROUTES.TRANSACTIONEXPENCES__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/settings/transactions/expenses/add_or_update/:id"
                  component={<TransactionsExpensesAddOrUpdate />}
                  does={ROUTES.TRANSACTIONEXPENCES__UPDATE}
                />
                <PrivateRoutes
                  exact
                  to="/settings/transactions/recharges"
                  component={<TransactionsRecharges />}
                  does={ROUTES.TRANSACTIONRECHARGES}
                />
                <PrivateRoutes
                  exact
                  to="/settings/transactions/recharges/add_or_update"
                  component={<TransactionsRechargesAddOrUpdate />}
                  does={ROUTES.TRANSACTIONRECHARGES__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/settings/transactions/recharges/add_or_update/:id"
                  component={<TransactionsRechargesAddOrUpdate />}
                  does={ROUTES.TRANSACTIONRECHARGES__UPDATE}
                />
                <PrivateRoutes
                  exact
                  to="/settings/transactions/kilometers-coefficient"
                  component={<TransactionsKilometers />}
                  does={ROUTES.TRANSACTIONKILOMETRE}
                />
                <PrivateRoutes
                  exact
                  to="/settings/transactions/kilometers-coefficient/edit"
                  component={<TransactionsKilometersEdit />}
                  does={ROUTES.TRANSACTIONKILOMETRE__UPDATE}
                />
                <PrivateRoutes
                  exact
                  to="/settings/leaves/special-leaves-types"
                  component={<LeavesSpecialLeavesTypes />}
                  does={ROUTES.SPECIALLEAVESTYPES}
                />
                <PrivateRoutes
                  exact
                  to="/settings/leaves/special-leaves-types/add_or_update"
                  component={<LeavesSpecialLeavesTypesAddOrUpdate />}
                  does={ROUTES.SPECIALLEAVESTYPES__ADD}
                />
                <PrivateRoutes
                  exact
                  to="/settings/leaves/special-leaves-types/add_or_update/:id"
                  component={<LeavesSpecialLeavesTypesAddOrUpdate />}
                  does={ROUTES.SPECIALLEAVESTYPES__UPDATE}
                />
                <PrivateRoutes
                  exact
                  to="/settings/leaves/annual-leaves-params"
                  component={<LeavesAnnualLeavesParams />}
                  does={ROUTES.ANNUALLEAVESPARAMS}
                />
                <PrivateRoutes
                  exact
                  to="/settings/leaves/annual-leaves-params/edit"
                  component={<AnnualLeavesParamsAddOrUpdate />}
                  does={ROUTES.ANNUALLEAVESPARAMS__UPDATE}
                />
                <PrivateRoutes
                  exact
                  to="/settings/emails-models"
                  component={<EmailModels />}
                  does={ROUTES.EMAILMODEL}
                />
                <PrivateRoutes
                  exact
                  to="/settings/emails-models/spontaneous-application-model"
                  component={<SpontaneousApplicationModel />}
                  does={ROUTES.SPONTANEOUSAPPLICATION}
                />
                <PrivateRoutes
                  exact
                  to="/settings/emails-models/job-offer-model"
                  component={<JobOfferModel />}
                  does={ROUTES.JOBOFFERMODEL}
                />
                <PrivateRoutes exact to="/settings/planning/teams" component={<Teams />} does={ROUTES.SETTINGSTEAMS} />
                <PrivateRoutes
                  exact
                  to="/settings/planning/events"
                  component={<Events />}
                  does={ROUTES.SETTINGSEVENTS}
                />
                <PrivateRoutes exact to="/settings/tasks-models" component={<TaskModels />} does={ROUTES.TASKMODELS} />
                <PrivateRoutes
                  exact
                  to="/settings/tasks-models/add_or_update"
                  component={<TaskModelsAddOrUpdate />}
                  does={ROUTES.TASKMODELS}
                />
                <PrivateRoutes
                  exact
                  to="/settings/tasks-models/add_or_update/:id"
                  component={<TaskModelsAddOrUpdate />}
                  does={ROUTES.TASKMODELS}
                />
                {/* ------------------------------------------------------- SETTINGS  ---------------------------------------------- */}
                {/* ------------------------------------------------------- CSV  ---------------------------------------------- */}
                <PrivateRoutes exact to="/csv-modifier" component={<CsvModifier />} does={ROUTES.CSV} />
                <Route exact path="/denied_access" render={(props) => <UnauthorizedComponent {...props} />} />
                <Route exact path="/auth/failed" render={(props) => <ErrorPage {...props} />} />
                {/* <Route exact path='*' render={(props) => <div {...props}> ok </div>} /> */}
              </>
            )}
          </Suspense>
        </Switch>
      </Router>
    </NotificationsContext.Provider>
  );
}

export default MainNavigator;
