import { FormFeedback, FormGroup, Input, Label } from '@sobrus-com/sobrus-design-system';
import { memo } from 'react';
import { compareProps } from 'Services/functionHelpers';

export const CustomInput = memo((props) => {
  return (
    <FormGroup>
      {!props.label && <Label htmlFor={props.name}>{props.newLabel || props.placeholder}</Label>}
      <Input
        id={props.name}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        autoComplete="off"
        rows="6"
        invalid={props.hasError && props.isTouched}
        {...props}
      />
      {props.hasError && props.isTouched ? <FormFeedback invalid="true">{props.hasError}</FormFeedback> : null}
    </FormGroup>
  );
}, compareProps);
export const CustomSearchInput = memo((props) => {
  // console.log(`Custom input ----------------------- ${props.name}`);
  return (
    <Input
      id={props.name}
      onChange={props.handleChange}
      onBlur={props.handleBlur}
      autoComplete="off"
      rows="6"
      {...props}
    />
  );
}, compareProps);

const defaultProps = {
  type: 'text',
};

CustomInput.defaultProps = defaultProps;

CustomSearchInput.defaultProps = defaultProps;
