import { Box } from '@material-ui/core';
import {
  Button,
  CircularProgress,
  EmptyData,
  Fade,
  LinearProgress,
  TableHeader,
  TableHeaderActions,
  TableHeaderTitle,
  TableLoader,
  TableNextPage,
  TablePage,
  TablePreviousPage,
  TableRefreshBtn,
  TableSearch,
  TableSearchBtn,
  TableSearchItem,
  TableSearchRow,
  Th,
} from '@sobrus-com/sobrus-design-system';
import {
  CustomSearchAsyncSelect,
  CustomSearchDatePicker,
  CustomSearchInput,
  CustomSearchInputSelect,
} from 'Components';
import { useAcl } from 'Hooks';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'Services/moment';

export function CustomTableauLoading({ lengthLoaderTh, numberOfLoadingRows }) {
  const columns = [];
  [...Array(lengthLoaderTh)].map((_) => columns.push({ type: 'medium' }));
  return <TableLoader numberOfRows={numberOfLoadingRows} loading columns={columns} />;
}

CustomTableauLoading.defaultProps = {
  numberOfLoadingRows: 20,
};

export function CustomTableHeader({
  theaderTitle,
  loadingNewData,
  handelToggleSearch,
  toggleSearch,
  handelRefresh,
  tableHeaderActions,
  children,
}) {
  return (
    <TableHeader>
      <TableHeaderTitle style={{ whiteSpace: 'pre', marginRight: '.5rem' }}>{theaderTitle}</TableHeaderTitle>
      {loadingNewData && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      <div style={{ display: 'flex' }}>
        {children}
        <TableHeaderActions>
          {tableHeaderActions.refrech && <TableRefreshBtn style={{ margin: '0 0.5rem' }} onClick={handelRefresh} />}
          {tableHeaderActions.search && <TableSearchBtn onClick={handelToggleSearch} open={toggleSearch} />}
        </TableHeaderActions>
      </div>
    </TableHeader>
  );
}
CustomTableHeader.defaultProps = {
  tableHeaderActions: { search: true, refrech: true },
};

export function CustomThead({ filterAndSearchData, handleOrder, order, orderBy, actions, Theadfilter, hideOrder }) {
  // // console.log('CustomThead');
  return (
    <thead>
      <tr>
        {filterAndSearchData?.map(
          (d) =>
            !d?.notInTh && (
              <Th
                key={d?.filter}
                isActive={!d?.hideOrder && Theadfilter && orderBy === d?.filter}
                order={!d?.hideOrder && Theadfilter && orderBy === d?.filter && (order === 'ASC' ? 'down' : 'up')}
                onClick={() => !d?.hideOrder && Theadfilter && handleOrder(d?.filter)}
                hideOrder={hideOrder || d?.hideOrder}
              >
                {d?.label}
              </Th>
            )
        )}

        {actions && (
          <Th style={{ textAlign: 'end' }} hideOrder>
            {' '}
            Actions{' '}
          </Th>
        )}
      </tr>
    </thead>
  );
}

CustomThead.defaultProps = {
  actions: false,
  Theadfilter: true,
  hideOrder: false,
};
export function CustomTableauPagination({ setParams, page, limit, length, loadingNewData }) {
  // --------------------------------------------------- NEXT/PREV
  // // console.log('CustomTableauPagination');
  const handelPrev = useCallback(
    () =>
      setParams((prev) => ({
        ...prev,
        queryState: {
          ...prev.queryState,
          page: prev.queryState.page - 1,
        },
      })),
    [setParams]
  );
  const handelNext = useCallback(
    () =>
      setParams((prev) => ({
        ...prev,
        queryState: {
          ...prev.queryState,
          page: prev.queryState.page + 1,
        },
      })),
    [setParams]
  );

  // --------------------------------------------------- NEXT/PREV
  return (
    <>
      <tr>
        <td colSpan={12}>
          <div className="sob-table-pagination">
            <TablePreviousPage disabled={(page === 1 || loadingNewData) && true} onClick={handelPrev} />
            <TablePage>{loadingNewData ? <CircularProgress style={{ color: '#FFF' }} size={16} /> : page}</TablePage>
            <TableNextPage disabled={(length < limit || loadingNewData) && true} onClick={handelNext} />
          </div>
        </td>
      </tr>
    </>
  );
}

export function CustomEmtyDataTableau({ emptyData }) {
  const history = useHistory();
  const { can } = useAcl();
  // // console.log('CustomEmtyDataTableau');
  return (
    <Fade in>
      <tr>
        <td colSpan={12}>
          <EmptyData
            title={(can(emptyData?.can) && emptyData?.title) || 'Information non trouvée'}
            desc={(can(emptyData?.can) && emptyData?.message) || 'Aucun résultat ne correspond à votre recherche'}
          >
            {emptyData?.buttonMessage && can(emptyData?.can) && (
              <Button
                outline
                color="primary"
                style={{ marginRight: 0 }}
                onClick={() => (emptyData?.onClick ? emptyData?.onClick() : history.push(emptyData?.link))}
              >
                {emptyData?.buttonMessage}
              </Button>
            )}
          </EmptyData>
        </td>
      </tr>
    </Fade>
  );
}

export function CustomFormSearch({ submitForm, filterAndSearchData, value, setFieldValue, handleChange }) {
  // // console.log('CustomFormSearch');
  const witchInput = (el) => {
    switch (el?.type) {
      case 'select':
        return (
          <CustomSearchInputSelect
            placeholder={`Rechercher par ${el?.label.toLowerCase()} ...`}
            name={el?.search || el?.filter}
            options={el?.options?.length > 0 && el?.options}
            handleChange={(e) => {
              e
                ? el?.event
                  ? setFieldValue(el?.search || el?.filter, e)
                  : setFieldValue(el?.search || el?.filter, e[el?.value || 'value'])
                : setFieldValue(el?.search || el?.filter, e);
              submitForm();
            }}
            value={
              value[el?.search || el?.filter]
                ? el?.options?.find((x) => x.value === value[el?.search || el?.filter])
                : value[el?.search || el?.filter] === false && el?.nullValue
            }
          />
        );
      case 'date':
        return (
          <CustomSearchDatePicker
            placeholder={`Rechercher par ${el?.label.toLowerCase()} ...`}
            name={el?.search || el?.filter}
            selected={value[el?.search || el?.filter] ? new Date(value[el?.search || el?.filter]) : ''}
            value={() => (value[el?.search || el?.filter] ? new Date(value[el?.search || el?.filter]) : '')}
            handleChange={(date) => {
              setFieldValue(el?.search || el?.filter, date ? moment(date).format('YYYY-MM-DD') : '');
              submitForm();
            }}
          />
        );
      case 'async':
        return (
          <CustomSearchAsyncSelect
            isClearable
            value={value[el?.search || el?.filter] && value[el?.search || el?.filter]}
            name={el?.search || el?.filter}
            placeholder={`Rechercher par ${el?.label.toLowerCase()} ...`}
            cacheOptions
            loadOptions={el?.options?.fetchCity}
            defaultOptions={el?.options?.cityOptions}
            onInputChange={el?.options?.handleInputChangeCity}
            onChange={(e) => {
              setFieldValue(el?.search || el?.filter, e);
              submitForm();
            }}
          />
        );
      case 'number':
        return (
          <CustomSearchInput
            value={value[el?.search || el?.filter]}
            handleChange={handleChange}
            placeholder={`Rechercher par ${el?.label.toLowerCase()} ...`}
            name={el?.search || el?.filter}
            type="number"
          />
        );
      default:
        return (
          <CustomSearchInput
            value={value[el?.search || el?.filter]}
            handleChange={handleChange}
            placeholder={`Rechercher par ${el?.label.toLowerCase()} ...`}
            name={el?.search || el?.filter}
          />
        );
    }
  };
  return (
    <form id="searchForm">
      <TableSearch
        noAdvancedSearch
        onSearch={(e) => {
          e.preventDefault();
          submitForm();
        }}
      >
        <TableSearchRow id="simple">
          {filterAndSearchData?.map((el) => (
            <>{!el?.not && <TableSearchItem key={el?.filter}>{witchInput(el)}</TableSearchItem>}</>
          ))}
        </TableSearchRow>
      </TableSearch>
    </form>
  );
}
