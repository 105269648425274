import { AuthContext } from 'Context';
import { useCallback, useContext } from 'react';

export const useAcl = () => {
  const { user, IP } = useContext(AuthContext);

  const aclActions = user?.role?.allowedActions;
  const userIP = user?.role?.allowedIpAddress;
  const can = useCallback(
    (action) => {
      if (action !== undefined) {
        if (user) {
          if (user?.isAdmin) return true;
          if (!userIP || userIP === IP) return aclActions?.some((object) => action.includes(object.name));
          return false;
        }
        return false;
      }
      return false;
    },
    [aclActions, user, IP, userIP]
  );

  const canIndex = useCallback(
    (action) => {
      if (user?.isAdmin) return 0;
      return action.indexOf(action?.find((a) => aclActions.some((acl) => acl.name === a)));
    },
    [aclActions, user]
  );

  const canAll = useCallback(
    (action) => {
      if (action !== undefined) {
        if (user) {
          if (user?.isAdmin) return true;
          if (!userIP || userIP === IP) {
            const verifActions = action.map((_, key) => aclActions?.some((object) => object.name === action[key]));
            return verifActions.every((el) => el === true);
          }
          return false;
        }
        return false;
      }
      return false;
    },
    [aclActions, user, userIP, IP]
  );
  return { can, canIndex, canAll };
};
