import {
  Breadcrumb,
  BreadcrumbBody,
  BreadcrumbItem,
  BreadcrumbTitle,
  IconButton,
} from '@sobrus-com/sobrus-design-system';
import React, { memo } from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import { compareProps } from 'Services/functionHelpers';
import './CustomBreadcrumb.css';

export const CustomBreadcrumb = memo(({ title, back, subTitle }) => {
  const history = useHistory();
  return (
    <Breadcrumb>
      {back && (
        <IconButton
          className="cart_title_header_container_button"
          color="primary"
          style={{ margin: '0  1rem 0 0', lineHeight: 1 }}
          onClick={() => history.goBack()}
        >
          <MdKeyboardArrowLeft color="primary" size={24} />
        </IconButton>
      )}
      <div>
        <p className="breadcrumb__title">{title}</p>
        <p className="breadcrumb__subtitle">{subTitle}</p>
      </div>
    </Breadcrumb>
  );
}, compareProps);

export const CBreadcrumb = memo(({ title, back, body = [] }) => {
  const history = useHistory();
  return (
    <Breadcrumb>
      {back && (
        <IconButton
          color="primary"
          style={{ margin: '0  1rem 0 0', lineHeight: 1 }}
          onClick={() => history.goBack()}
          className="cart_title_header_container_button"
        >
          <MdKeyboardArrowLeft size={24} color="primary" />
        </IconButton>
      )}
      <BreadcrumbTitle>{title}</BreadcrumbTitle>
      <BreadcrumbBody>
        {body?.map((item, key) => (
          <React.Fragment key={item.to}>
            <BreadcrumbItem active={key === body.length - 1}>
              {key !== body.length - 1 && item?.to ? <Link to={item?.to}> {item?.el} </Link> : <>{item?.el}</>}
            </BreadcrumbItem>
          </React.Fragment>
        ))}
      </BreadcrumbBody>
    </Breadcrumb>
  );
}, compareProps);
