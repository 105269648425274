import { ModalBody, ModalHeader } from '@sobrus-com/sobrus-design-system';
import profilePic from 'Assets/profile.svg';
import likeImage from 'Assets/likesImage.svg';
import { BASE_URL } from 'env';
import { v4 as uuidv4 } from 'uuid';
import { PopupContainer } from '../Popups/PopupContainer';

export function PopupLikes({ open, setOpen, post }) {
  return (
    <PopupContainer {...{ open, setOpen, close: true }}>
      <div style={{ margin: '1rem' }}>
        <ModalHeader style={{ fontWeight: 600, fontSize: 22 }}>
          <div>J&apos;aimes</div>{' '}
          <span
            style={{
              fontWeight: 300,
              marginLeft: '10px',
              fontSize: 14,
              marginTop: '5px',
            }}
          >
            {' '}
            ({post?.reactions?.length})
          </span>
        </ModalHeader>
        <ModalBody>
          <div className="post__likes">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {post?.reactions?.map((p, key) => (
                <div
                  className={`likes__container ${
                    key === post.reactions.length - 1 ? 'likes__container-lastChild' : ''
                  }`}
                  key={uuidv4()}
                >
                  <div
                    style={{
                      width: 66,
                      height: 66,
                      borderRadius: 50,
                      position: 'relative',
                    }}
                  >
                    <img
                      style={{
                        objectFit: 'cover',
                        borderRadius: 50,
                        width: 66,
                        height: 66,
                      }}
                      alt=""
                      src={
                        p?.collaborater?.photo
                          ? `${BASE_URL}/images/collaborators/${p?.collaborater?.photo}`
                          : profilePic
                      }
                    />
                    <div>
                      <img src={likeImage} className="like__image" alt="" />
                    </div>
                  </div>
                  <div>
                    <p
                      style={{
                        fontSize: 20,
                        fontWeight: 700,
                        marginBottom: 0,
                      }}
                    >
                      {' '}
                      {`${p?.collaborater?.lastName} ${p?.collaborater?.firstName}`}
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                        color: '#969696',
                        marginBottom: 0,
                      }}
                    >
                      {p?.collaborater?.job?.jobName || '--'}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ModalBody>
      </div>
    </PopupContainer>
  );
}

export function PopupCommentLikes({ open, setOpen, comment }) {
  return (
    <PopupContainer {...{ open, setOpen, close: true }}>
      <div style={{ margin: '1rem' }}>
        <ModalHeader style={{ fontWeight: 600, fontSize: 22 }}>
          <div>J&apos;aimes</div>{' '}
          <span
            style={{
              fontWeight: 300,
              marginLeft: '10px',
              fontSize: 14,
              marginTop: '5px',
            }}
          >
            {' '}
            ({comment?.reactions?.length})
          </span>
        </ModalHeader>
        <ModalBody>
          <div className="post__likes">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {comment?.reactions?.map((p, key) => (
                <div
                  className={`likes__container ${
                    key === comment.reactions.length - 1 ? 'likes__container-lastChild' : ''
                  }`}
                  key={uuidv4()}
                >
                  <div
                    style={{
                      width: 66,
                      height: 66,
                      borderRadius: 50,
                      position: 'relative',
                    }}
                  >
                    <img
                      style={{
                        objectFit: 'cover',
                        borderRadius: 50,
                        width: 66,
                        height: 66,
                      }}
                      alt=""
                      src={
                        p?.collaborator?.photo
                          ? `${BASE_URL}/images/collaborators/${p?.collaborator?.photo}`
                          : profilePic
                      }
                    />
                    <div>
                      <img src={likeImage} className="like__image" alt="" />
                    </div>
                  </div>
                  <div>
                    <p
                      style={{
                        fontSize: 20,
                        fontWeight: 700,
                        marginBottom: 0,
                      }}
                    >
                      {' '}
                      {`${p?.collaborator?.lastName} ${p?.collaborator?.firstName}`}
                    </p>
                    <p
                      style={{
                        fontSize: 15,
                        fontWeight: 500,
                        color: '#969696',
                        marginBottom: 0,
                      }}
                    >
                      {p?.collaborator?.job?.jobName || '--'}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ModalBody>
      </div>
    </PopupContainer>
  );
}
