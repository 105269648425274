let BASE_URL = 'https://api.workspace.sobrus.ovh';
let EXT = 'ovh';
let REACT_APP_ENV = 'development';
let REDDIREC_URI =
  'https://api.account.sobrus.ovh/authorize?response_type=code&client_id=10013&scope=openid%20offline_access%20profile%20email&redirect_uri=https://api.workspace.sobrus.ovh/auth/token&prompt=login';

if (process.env.REACT_APP_ENV === 'production') {
  BASE_URL = 'https://api.workspace.sobrus.com';
  EXT = 'com';
  REACT_APP_ENV = 'production';
  REDDIREC_URI = `https://api.account.sobrus.com/authorize?response_type=code&client_id=10013&scope=openid%20offline_access%20profile%20email&redirect_uri=https://api.workspace.sobrus.com/auth/token`;
}

export { BASE_URL, EXT, REACT_APP_ENV, REDDIREC_URI };
