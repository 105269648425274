import { Button, CircularProgress, IconButton } from '@sobrus-com/sobrus-design-system';
import { useAcl } from 'Hooks';
import { memo } from 'react';
import { FiEdit3 } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { compareProps } from 'Services/functionHelpers';

export const CustomButton = memo((props) => {
  const { canAll } = useAcl();
  // console.log(props.name);
  return (
    <>
      {props.can ? (
        canAll(props.can) && (
          <Button
            outline={props.outline}
            style={props.style || { marginRight: 0 }}
            color={props.color}
            onClick={props.onClick}
            className={props.loader ? 'disabled' : ''}
            form={props.form}
            type="submit"
          >
            {props.name}
            {props.loader ? (
              <CircularProgress
                style={{
                  marginLeft: 15,
                  marginBottom: -2,
                  color: `${props.outline ? '#785ea8' : '#fff'}`,
                }}
                size={18}
              />
            ) : (
              ''
            )}
          </Button>
        )
      ) : (
        <Button
          outline={props.outline}
          style={props.style || { marginRight: 0 }}
          color={props.color}
          onClick={props.onClick}
          className={props.loader ? 'disabled' : ''}
          form={props.form}
          type="submit"
        >
          {props.icon && <span style={{ marginRight: 8, marginBottom: -6 }}>{props.icon}</span>}{' '}
          <span>{props.name}</span>
          {props.loader ? (
            <CircularProgress
              style={{
                marginLeft: 15,
                marginBottom: -2,
                color: `${props.outline ? '#785ea8' : '#fff'}`,
              }}
              size={18}
            />
          ) : (
            ''
          )}
        </Button>
      )}
    </>
  );
}, compareProps);

CustomButton.defaultProps = {
  outline: false,
  color: 'primary',
  name: 'Sauvegarder',
};

export const EditButton = memo((props) => {
  const { canAll } = useAcl();
  // // console.log('editButton');
  return (
    <>
      {canAll(props.can) && (
        <Link onClick={props.onClick} to={props.to}>
          <IconButton color="primary" style={{ margin: '0 4px', lineHeight: 1 }} data-title="Modifier">
            <FiEdit3 size={20} />
          </IconButton>
        </Link>
      )}
    </>
  );
}, compareProps);
export const EditPopupButton = memo((props) => {
  const { canAll } = useAcl();

  return (
    <>
      {canAll(props.can) && (
        <IconButton
          color="primary"
          style={{ margin: '0 4px', lineHeight: 1 }}
          data-title="Modifier"
          onClick={props.onClick}
        >
          <FiEdit3 size={20} />
        </IconButton>
      )}
    </>
  );
}, compareProps);
export const DeletButton = memo((props) => {
  const { canAll } = useAcl();
  return (
    <>
      {canAll(props.can) && (
        <IconButton
          style={{
            margin: '0 4px',
            lineHeight: 1,
          }}
          color="danger"
          onClick={props.onClick}
          data-title="Supprimer"
        >
          <RiDeleteBin6Line size={20} />
        </IconButton>
      )}
    </>
  );
}, compareProps);
