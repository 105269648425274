import { notify } from '@sobrus-com/sobrus-design-system';
import { AuthContext } from 'Context';
import { useAcl } from 'Hooks';
import { useContext, useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useParams } from 'react-router-dom';
import API from 'Services/API';
import { catchFunction } from 'Services/functionHelpers';
import { MESSAGES } from 'Values/CONST';

export const useRequest = (data, setData, FetchGet) => {
  const { id } = useParams();
  const { can } = useAcl();
  const [openValidate, setOpenValidate] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [status, setStatus] = useState('');
  const { user } = useContext(AuthContext);
  const ACLS = {
    REVIEW_MANAGER: ['review_demand_as_manager'],
    REVIEW_ADMIN: ['review_demand_as_admin'],
  };
  useEffect(() => {
    if (
      can(ACLS.REVIEW_ADMIN) &&
      data?.adminStatus === 'Waiting' &&
      data?.managerStatus === 'Waiting' &&
      data?.createdBy?.manager?.id === user?.id
    ) {
      setStatus('adminAndManager');
    } else {
      if (can(ACLS.REVIEW_MANAGER) && data?.managerStatus === 'Waiting' && data?.createdBy?.manager?.id === user?.id) {
        setStatus('manager');
      }
      if (can(ACLS.REVIEW_ADMIN) && data?.adminStatus === 'Waiting') {
        setStatus('admin');
      }
    }
  }, [data, user, can]);
  const handelValidate = async () => {
    const validationData =
      status === 'adminAndManager'
        ? { adminStatus: 'Validated', managerStatus: 'Validated' }
        : status === 'admin'
        ? { adminStatus: 'Validated' }
        : { managerStatus: 'Validated' };
    const review = status === 'admin' || status === 'adminAndManager' ? 'admin_review' : 'manager_review';

    unstable_batchedUpdates(async () => {
      try {
        setFormLoading(true);
        await API.patch(`/collaboraters/${user?.id}/demands/${id}/${review}`, validationData);
        FetchGet && FetchGet();
        setData((prev) => ({
          ...prev,
          ...validationData,
        }));
        setFormLoading(false);
        setOpenValidate(false);
        setStatus('');

        notify({
          type: 'success',
          msg: MESSAGES.DEMANDES.VALIDATION,
          delay: 5000,
        });
      } catch (error) {
        catchFunction(error, setFormLoading);
      }
    });
  };
  return {
    handelValidate,
    formLoading,
    openValidate,
    setOpenValidate,
    status,
    setStatus,
  };
};
