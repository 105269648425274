export const ROUTES = {
  AUTH_SUCCESS: ['get_authenticated_collaborator'],
  HOME: ['get_authenticated_collaborator'],
  PROFILE: ['get_authenticated_collaborator'],
  // ------------------------------------------------------- NOTIFICATIONS ACL ----------------------------------------------//
  NOTIFICATIONS: ['get_collaborater_notifications'],
  NOTIFICATIONS__HISTORY: ['get_collaborater_notifications'],
  // ------------------------------------------------------- NOTIFICATIONS ACL ----------------------------------------------//
  // ------------------------------------------------------- COLLABORATERS ACL ----------------------------------------------//
  COLLABORATERS: ['get_authenticated_collaborator'],
  COLLABORATER__ADD: ['register_collaborater'],
  COLLABORATER__UPDATE: ['patch_collaborater', 'get_collaborater'],
  COLLABORATER__VIEW: ['get_collaborater'],
  COLLABORATER__INTERVIEW__ADD: ['add_collaborater_interview'],
  COLLABORATER__PAYRISES__ADDORUPDATE: ['post_pay_rise', 'get_collaborater_last_active_payrise_after_a_date'],
  COLLABORATER__PHONEBOOKPARAMS__UPDATE: ['patch_collaborater_phonebook', 'get_collaborater'],
  COLLABORATER__ACCOUNTPARAMS__UPDATE: [
    'patch_collaborater_role',
    'patch_collaborater_linked_user',
    'get_collaborater',
  ],
  COLLABORATER__SHEDULE__UPDATE: ['add_or_update_collaborater_weekly_time_table', 'get_collaborater'],
  // ------------------------------------------------------- COLLABORATERS ACL ----------------------------------------------//
  // ------------------------------------------------------- COLLABORATERS INTERVIEWS ACL ----------------------------------------------//
  REQUESTS: ['get_demands'],
  REQUESTS__VIEW: ['get_demand'],
  REQUESTS__UPDATE: ['patch_demand', 'get_demand_of_authenticated_collaborator'],
  REQUESTS__ADD: ['post_demand'],
  MY__REQUESTS: ['get_collaborater_demands'],
  MY__REQUESTS__VIEW: ['get_demand_of_authenticated_collaborator'],
  TEAM__REQUESTS: ['get_collaborater_team_demands'],
  TEAM__REQUESTS__VIEW: ['get_demand_of_authenticated_collaborator_team'],
  // ------------------------------------------------------- COLLABORATERS INTERVIEWS ACL ----------------------------------------------//
  // ------------------------------------------------------- REQUESTS ACL ----------------------------------------------//
  INTERVIEWS: ['get_all_collaboraters_interviews'],
  INTERVIEWS__VIEW: ['get_interview'],
  INTERVIEWS__UPDATE: ['edit_an_interview', 'get_interview'],
  // ------------------------------------------------------- REQUESTS ACL ----------------------------------------------//
  // ------------------------------------------------------- CONTRACTS ACL ----------------------------------------------//
  CONTRACTS: ['get_contracts'],
  CONTRACTS__CREATE: ['generate_collaborater_contract'],
  // ------------------------------------------------------- CONTRACTS ACL ----------------------------------------------//
  // ------------------------------------------------------- GALERIE ACL ----------------------------------------------//

  GALERIE: ['get_albums_gallery'],
  GALERIE__ADD: ['create_new_album'],
  GALERIE__UPDATE: ['update_album_details', 'get_album_details'],
  GALERIE__VIEW: ['get_album_details'],

  // ------------------------------------------------------- GALERIE ACL ----------------------------------------------//
  // ------------------------------------------------------- EVENEMENTS ACL ----------------------------------------------//
  ACTIVITES: ['get_activities_list'],
  ACTIVITES__ADD: ['create_new_activity'],
  ACTIVITES__UPDATE: ['update_activity_details', 'get_activity_details'],
  ACTIVITES__VIEW: ['get_activity_details'],

  // ------------------------------------------------------- EVENEMENTS ACL ----------------------------------------------//
  // ------------------------------------------------------- HARDWARES ACL ----------------------------------------------//
  HARDWARES: ['get_hardwares'],
  HARDWARES__VIEW: ['get_hardware'],
  HARDWARES__ADD: ['post_hardwares'],
  HARDWARES__UPDATE: ['get_hardware', 'patch_hardwares'],
  // ------------------------------------------------------- HARDWARES ACL ----------------------------------------------//
  // ------------------------------------------------------- SOFTWARES ACL ----------------------------------------------//
  SOFTWARES: ['get_software_list'],
  SOFTWARES__VIEW: ['get_software_details'],
  SOFTWARES__ADD: ['create_new_software'],
  SOFTWARES__UPDATE: ['get_software_details', 'edit_software'],
  // ------------------------------------------------------- SOFTWARES ACL ----------------------------------------------//
  // ------------------------------------------------------- MISSIONS ACL ----------------------------------------------//
  MISSIONS: ['get_missions'],
  MISSIONS__VIEW: ['get_mission'],
  MISSIONS__ADD: ['post_mission'],
  MISSIONS__UPDATE: ['patch_mission', 'get_mission'],
  // ------------------------------------------------------- MISSION ACL ----------------------------------------------//
  // ------------------------------------------------------- TRANSACTION ACL ----------------------------------------------//
  TRANSACTIONS: ['get_collaborators_balances'],
  TRANSACTIONS__VIEW: ['get_collaborator_transactions'],
  TRANSACTIONS__EXPENSES__ADD: ['post_expense'],
  TRANSACTIONS__RECHARGE__ADD: ['post_recharge'],
  TRANSACTIONS__RECHARGE__VIEW: ['get_recharge'],
  TRANSACTIONS__EXPENSES__VIEW: ['get_expense'],
  // ------------------------------------------------------- TRANSACTION ACL ----------------------------------------------//
  // ------------------------------------------------------- RECRUTEMENT ACL ----------------------------------------------//
  RECRUTEMENT__APPLICATIONS: ['get_candidates'],
  RECRUTEMENT__APPLICATIONS__VIEW: ['get_candidate'],
  RECRUTEMENT__ADD: ['add_candidate_interview'],
  RECRUTEMENT__INTERVIEW__VIEW: ['get_interview'],
  RECRUTEMENT__UPDATE: ['edit_an_interview', 'get_interview'],
  RECRUTEMENT__CANDIDATES: ['get_candidates_grouped_by_mobile_number'],
  RECRUTEMENT__MYCANDIDATE: ['get_authenticated_collaborater_candidates'],
  RECRUTEMENT__NEWSLETTER: ['get_newsletter_subscribers'],
  RECRUTEMENTREQUESTS: ['get_recruitment_requests_list'],
  RECRUTEMENTREQUESTS__ADD: ['create_new_recruitment_request'],
  RECRUTEMENTREQUESTS__UPDATE: ['update_recruitment_request_details', 'get_recruitment_request_details'],
  RECRUTEMENTREQUESTS__VIEW: ['get_recruitment_request_details'],

  // ------------------------------------------------------- RECRUTEMENT ACL ----------------------------------------------//
  // ------------------------------------------------------- TIMEREGISTRATION ACL ----------------------------------------------//
  TIMEREGISTRANTION__ALLCOLLABORATERS: ['get_all_collaborators_last_time_registrations'],
  TIMEREGISTRANTION__ALL: ['get_all_time_registrations'],
  TIMEREGISTRANTION__DAILYGAP: ['get_all_daily_working_time_gaps'],
  // ------------------------------------------------------- TIMEREGISTRATION ACL ----------------------------------------------//
  // ------------------------------------------------------- LEAVES ACL ----------------------------------------------//
  ALLLEAVES: ['get_list_of_all_leaves'],
  SPECIALLEAVES__ADD: ['post_special_leave'],
  ALLLEAVES__VIEW: ['get_collaborater_leaves'],
  SPECIALLEAVES__VIEW: ['get_special_leave_by_id'],
  GRANTEDLEAVES__VIEW: ['get_granted_leave_by_id'],
  ALLLEAVES__REQUEST__VIEW: ['get_one_leave_request_by_its_id'],
  MYLEAVES: ['get_authenticated_collaborater_leaves'],
  MYLEAVES__VIEW: ['get_own_leave_request_by_id'],
  ALLLEAVES__REQUESTS: ['get_list_of_all_leave_requests'],
  ALLLEAVES__REQUESTS__ADD: ['post_leave_request'],
  MYLEAVES__REQUESTS: ['get_authenticated_collaborater_leave_requests'],
  MYLEAVES__REQUESTS__ADD: ['post_leave_request_of_authenticated_user'],
  TEAMLEAVES__REQUESTS: ['get_collaborater_team_leave_requests'],
  TEAMLEAVES__REAUEST__VIEW: ['get_one_leave_request_of_team_member_by_id'],
  ALLABSENCES: ['get_absences'],
  MYABSENCES: ['get_authenticated_collaborater_absences'],
  // ------------------------------------------------------- LEAVES ACL ----------------------------------------------//
  // ------------------------------------------------------- CALENDAR ACL ----------------------------------------------//
  EVENTS: ['get_list_of_all_events_paginated_and_filtered', 'get_list_of_all_events_calendar'],
  EVENTS__ADD: ['create_new_event'],
  EVENTS__UPDATE: ['update_an_event'],
  EVENTS__VIEW: ['get_one_event_by_its_id'],
  ECENTS__STATSTICS: [
    'get_events_reports_by_status',
    'get_events_reports_by_collaborators',
    'get_events_reports_by_types',
  ],
  ECENTS__STATSTICS__STATUS: ['get_events_reports_by_status'],
  ECENTS__STATSTICS__COLLABORATERS: ['get_events_reports_by_collaborators'],
  ECENTS__STATSTICS__TYPES: ['get_events_reports_by_types'],
  // ------------------------------------------------------- CALENDAR ACL ----------------------------------------------//
  // ------------------------------------------------------- SETTINGS ACL ----------------------------------------------//
  JOBS: ['get_jobs'],
  JOBS__ADD: ['post_job'],
  JOBS__UPDATE: ['patch_job', 'get_job'],
  ENTITIES: ['get_entities_list'],
  DEPARTEMENT: ['get_departments'],
  DEPARTEMENT__ADD: ['post_department'],
  DEPARTEMENT__UPDATE: ['patch_department', 'get_department'],
  DEMANDE__SUBJECT: ['get_subjects'],
  DEMANDE__SUBJECT__ADD: ['post_subject'],
  DEMANDE__SUBJECT__UPDATE: ['patch_subject', 'get_subject'],
  CONTRACTS__ARTICLEMODEL: ['get_article_models'],
  CONTRACTS__ARTICLEMODEL__ADD: ['post_article_model'],
  CONTRACTS__ARTICLEMODEL__UPDATE: ['patch_article_model', 'get_article_model'],
  CONTRACTS__CONTACTMODEL: ['get_contract_models'],
  CONTRACTS__CONTACTMODEL__ADD: ['post_contract_model'],
  CONTRACTS__CONTACTMODEL__UPDATE: ['patch_contract_model', 'get_contract_model', 'get_article_model'],
  SKILLS: ['get_skills'],
  SKILLS__ADD: ['post_skill'],
  SKILLS__UPDATE: ['patch_skill', 'get_skill'],
  HARDWARESTYPE: ['get_hardwares_types'],
  HARDWARESTYPE__ADD: ['post_hardwares_types'],
  HARDWARESTYPE__UPDATE: ['patch_hardware_type', 'get_hardware_type'],
  SOFTWARETYPE: ['get_software_types'],
  SOFTWARETYPE__ADD: ['post_software_type'],
  SOFTWARETYPE__UPDATE: ['patch_software_type', 'get_software_type'],
  LOCATIONS: ['get_hardware_assignment_locations_list'],
  LOCATIONS__ADD: ['add_new_hardware_assignment_location'],
  LOCATIONS__UPDATE: ['edit_hardware_assignment_location', 'get_hardware_assignment_location_details'],
  COMPANYPARAMS: ['get_company_parameters'],
  COMPANYPARAMS__UPDATE: ['patch_company_parameters', 'get_company_parameters'],
  ACLROLES: ['get_all_roles'],
  ACLROLES__ADD: ['create_role'],
  ACLROLES__UPDATE: ['patch_role', 'get_role_by_id'],
  ACLROLES__ALOWEDACTIONS: ['patch_role', 'get_actions', 'get_all_roles'],
  ACLACTIONS: ['get_actions'],
  TIMEREGISTRATION__HOLIDAYS: ['get_holidays'],
  TIMEREGISTRATION__HOLIDAYS__ADD: ['post_holiday'],
  TIMEREGISTRATION__HOLIDAYS__UPDATE: ['patch_holiday', 'get_holiday'],
  TIMEREGISTRATION__DEFAULT: ['get_default_weekly_time_table'],
  TIMEREGISTRATION__DEFAULT__UPDATE: ['patch_default_weekly_time_table', 'get_default_weekly_time_table'],
  TRANSACTIONEXPENCES: ['get_expenses_types'],
  TRANSACTIONEXPENCES__ADD: ['post_expense_type'],
  TRANSACTIONEXPENCES__UPDATE: ['patch_expense_type', 'get_expense_type_by_id'],
  TRANSACTIONRECHARGES: ['get_recharges_types'],
  TRANSACTIONRECHARGES__ADD: ['post_recharge_type'],
  TRANSACTIONRECHARGES__UPDATE: ['patch_recharge_type', 'get_recharge_type_by_id'],
  TRANSACTIONKILOMETRE: ['get_kilometer_coefficient'],
  TRANSACTIONKILOMETRE__UPDATE: ['patch_kilometer_coefficient', 'get_kilometer_coefficient'],
  SPECIALLEAVESTYPES: ['get_special_leaves_types'],
  SPECIALLEAVESTYPES__ADD: ['post_special_leaves_type'],
  SPECIALLEAVESTYPES__UPDATE: ['patch_special_leaves_type', 'get_special_leave_type_by_id'],
  ANNUALLEAVESPARAMS: ['get_annual_leaves_params'],
  ANNUALLEAVESPARAMS__UPDATE: ['patch_annual_leaves_params', 'get_annual_leaves_params'],
  EMAILMODEL: ['get_job_offer_email_model', 'get_spontaneous_application_email_model'],
  SPONTANEOUSAPPLICATION: ['patch_spontaneous_application_email_model', 'get_spontaneous_application_email_model'],
  JOBOFFERMODEL: ['patch_job_model_email_model', 'get_job_offer_email_model'],
  SETTINGSTEAMS: ['get_teams'],
  SETTINGSEVENTS: ['get_events_types'],
  TASKMODELS: ['get_tasks_models_list'],
  CSV: ['csv_editor'],
  // ------------------------------------------------------- SETTINGS ACL ----------------------------------------------//
  // ------------------------------------------------------- PAYRISES ACL ----------------------------------------------//
  PAYRISES__ADD: ['post_pay_rise', 'get_collaborater_last_active_payrise_after_a_date'],
  PAYRISES__UPDATE: ['patch_pay_rise', 'get_collaborater_last_active_payrise_after_a_date'],
  PAYRISES__VIEW: ['get_pay_rise'],
  // ------------------------------------------------------- PAYRISES ACL ----------------------------------------------//
};

export const PARAMS = {
  JOBS__ADD: ['post_job'],
  JOBS__UPDATE: ['patch_job', 'get_job'],
  JOBS__DELETE: ['delete_job'],
  ENTITIES__ADD: ['create_new_entity'],
  ENTITIES__UPDATE: ['get_entity_details', 'update_entity_details'],
  ENTITIES__DELETE: ['toggle_entity_status'],
  DEPARTEMENT__ADD: ['post_department'],
  DEPARTEMENT__UPDATE: ['patch_department', 'get_department'],
  DEPARTEMENT__DELETE: ['delete_department'],
  POSTSUBJECT__ADD: ['post_subject'],
  POSTSUBJECT__UPDATE: ['patch_subject', 'get_subject'],
  POSTSUBJECT__DELETE: ['delete_subject'],
  ARTICLEMODEL__ADD: ['post_article_model'],
  ARTICLEMODEL__UPDATE: ['patch_article_model', 'get_article_model'],
  ARTICLEMODEL__DELETE: ['delete_article_model'],
  CONTRATMODEL__ADD: ['post_contract_model'],
  CONTRATMODEL__UPDATE: ['patch_contract_model', 'get_contract_model', 'get_article_models'],
  CONTRATMODEL__DELETE: ['delete_contract_model'],
  SKILLS__ADD: ['post_job'],
  SKILLS__UPDATE: ['patch_skill', 'get_skill'],
  SKILLS__DELETE: ['delete_skill'],
  HARDWARSTYPE__ADD: ['post_hardwares_types'],
  HARDWARSTYPE__UPDATE: ['patch_hardware_type', 'get_hardware_type'],
  HARDWARSTYPE__DELETE: ['delete_hardware_type'],
  SOFTWARETYPE__ADD: ['post_software_type'],
  SOFTWARETYPE__UPDATE: ['patch_software_type', 'get_software_type'],
  SOFTWARETYPE__DELETE: ['delete_software_type'],
  // get_hardware_assignment_locations_list
  LOCATIONS__ADD: ['add_new_hardware_assignment_location'],
  LOCATIONS__UPDATE: ['edit_hardware_assignment_location', 'get_hardware_assignment_location_details'],
  LOCATIONS__DELETE: ['delete_hardware_assignment_location'],
  COMPANYPARAMS__UPDATE: ['patch_company_parameters', 'get_company_parameters'],
  ROLE__CREATE: ['create_role'],
  ROLE__ALLOWEDACTIONS: ['patch_role', 'get_actions', 'get_all_roles'],
  ROLE__UPDATE: ['patch_role', 'get_role_by_id'],
  LEAVESPARAMS__EDIT: ['patch_annual_leaves_params', 'get_annual_leaves_params'],
  SPECIALLEAVESTYPE__ADD: ['post_special_leaves_type'],
  SPECIALLEAVESTYPE__EDIT: ['patch_special_leaves_type', 'get_special_leave_type_by_id'],
  SPECIALLEAVESTYPE__DELETE: ['delete_special_leaves_type'],
  EVENTS__ADD: ['create_new_event'],
  EVENTS__DELETE: ['archive_events_type'],
  EVENTS__UPDATE: ['get_events_type_by_its_id', 'edit_events_type'],
  TEAMS__CREATE: ['create_new_team'],
  TEAMS__UPDATE: ['get_team_by_its_id', 'edit_team'],
  TEAMS__DELETE: ['archive_team'],
  UPDATE__WEEKTIME: ['patch_default_weekly_time_table', 'get_default_weekly_time_table'],
  HOLIDAY__POST: ['post_holiday'],
  HOLIDAY__UPDATE: ['patch_holiday', 'get_holiday'],
  HOLIDAY__DELETE: ['delete_holiday'],
  EXPENSES_ADD: ['post_expense_type'],
  EXPENSES_UPDATE: ['patch_expense_type', 'get_expense_type_by_id'],
  EXPENSES_DELETE: ['delete_expense_type'],
  KILLOMETRECOFF__UPDATE: ['patch_kilometer_coefficient', 'get_kilometer_coefficient'],
  RECHARGETYPE__ADD: ['post_recharge_type'],
  RECHARGETYPE__UPDATE: ['patch_recharge_type', 'get_recharge_type_by_id'],
  TASKSMODEL__ADD: ['create_new_checklist_model'],
  TASKSMODEL__UPDATE: ['update_checklist_model', 'get_checklist_model'],
  TASKSMODEL__DELETE: ['delete_tasks_model'],
  TASKSMODEL__VIEW: ['get_checklist_model'],
  TASKSMODEL__CHANGESTATUS: ['toggle_checklist_model_status'],
};

export const PAGES = {
  NOTIFICATIONS: {
    GETLEAVEREQUEST: ['get_one_leave_request_by_its_id'],
    GET__EVENET: ['get_one_event_by_its_id'],
    SEEN: ['mark_notification_as_seen'],
  },
  HOME: {
    AUTHENTICATED__USER: ['get_authenticated_collaborator'],
    REACTION: ['record_authenticated_collaborator_mood'],
    POST__UPDATE: ['patch_post', 'get_post'],
    POST__PATCH: ['patch_post'],
    POST__ADD: ['post_post'],
    POST__VIEW: ['get_posts'],
    POST__DELETE: ['delete_post'],
    COMMENT__ADD: ['add_new_post_comment'],
    COMMENT__DELETEORUPDATE: ['archive_a_post_comment', 'edit_a_post_comment'],
    COMMENT__DELETE: ['archive_a_post_comment'],
    COMMENT__UPDATE: ['edit_a_post_comment'],
    REACTIONS__ADD: ['add_new_reaction_on_post_comment'],
    GET__GALERIE: ['get_albums_gallery'],
    GET__ACTIVITIES: ['get_activities_list'],
    ANNIVERSARY: ['get_actual_anniversaries'],
  },
  COLLABORATERS: {
    EDIT: ['patch_collaborater', 'get_collaborater'],
    DELETE: ['delete_collaborater'],
    DOWNLOAD: ['get_collaborators_actual_salaries'],
    CREATE: ['register_collaborater'],
    GET: ['get_collaborater'],
  },
  COLLABORATER__VIEW: {
    GET_DEMANDE: ['get_collaborater_demands'],
    GET__CONTRATS: ['get_collaborater_contracts'],
    GET__MISSION: ['get_collaborater_missions'],
    GET__PAYRISES: ['get_collaborater_payrises'],
    GET__HARDWARE_USAGE: ['get_collaborater_hardware_usages'],
    GET__INTERVIEW: ['get_collaborater_interviews'],
    GET__CHECKLIST: ['get_checklist_model'],
    CHECKLIST__CREATE: ['get_checklist_model'],
    UPDATE: ['patch_collaborater', 'get_collaborater'],
    PATCH__IMAGE: 'patch_collaborator_photo',
    MODIFIER__PARAMETRE__ANNUAIRE: ['patch_collaborater_phonebook', 'get_collaborater'],
    MODIFIER__PARAMETRE__COMPTE: ['patch_collaborater_role', 'patch_collaborater_linked_user', 'get_collaborater'],
    MODIFIER__HORAIRE: ['add_or_update_collaborater_weekly_time_table', 'get_collaborater'],
    DEFAULT__HORAIRE: ['get_default_weekly_time_table'],
    AJOUTER__AUGMENTATION: ['post_pay_rise', 'get_collaborater_last_active_payrise_after_a_date'],
    GENERER__CONTRAT: ['generate_collaborater_contract'],
    GET__REQUEST: ['get_demand'],
    CONTRACT__CREATE: ['generate_collaborater_contract'],
    MISSION__ADD: ['post_mission'],
    MISSION__UPDATE: ['patch_mission', 'get_mission'],
    MISSION__GET: ['get_mission'],
    MISSION__DELETE: ['delete_mission'],
    SALARY__CREATE: ['post_pay_rise', 'get_collaborater_last_active_payrise_after_a_date'],
    SALARY__GET: ['get_pay_rise'],
    MATERIALS__GET: ['get_pay_rise'],
    INTERVIEW__HISTORY__ADD: ['add_collaborater_interview'],
    INTERVIEW__HISTORY__GET: ['get_interview'],
    INTERVIEW__HISTORY__EDIT: ['edit_an_interview', 'get_interview'],
  },
  COLLABORATERS__INTERVIEW: {
    EDIT: ['edit_an_interview', 'get_interview'],
    REDDIRECT: ['get_interview'],
    DELETE: ['cancel_interview'],
  },
  REQUEST: {
    GET: ['get_demand'],
    GET__HISTORY: ['get_demand_status_history'],
  },
  MyREQUEST: {
    POST: ['post_demand'],
    GET: ['get_demand_of_authenticated_collaborator'],
    UPDATE: ['patch_demand', 'get_demand_of_authenticated_collaborator'],
  },
  ALLREQUEST: {
    GET: ['get_demand_of_authenticated_collaborator_team'],
  },
  CONTRACT: {
    POST: ['generate_collaborater_contract'],
  },
  GALERIE: {
    GET: ['get_album_details'],
    GALERIE__ARCHIVED: ['get_archived_albums_gallery'],
    POST: ['create_new_album', 'add_album_photo', 'remove_album_photos'],
    UPDATE: ['update_album_details', 'get_album_details'],
    ARCHIV__ALBUM: ['toggle_album_status'],
    DELETE: ['remove_photos_from_album'],
  },
  ACTIVITES: {
    GET: ['get_activity_details'],
    POST: ['create_new_activity'],
    UPDATE: ['update_activity_details', 'get_activity_details'],
  },
  HARDWARES: {
    EXPORT: ['export_hardware_list_as_excel_file'],
    POST: ['post_hardwares'],
    GET: ['get_hardware'],
  },
  SOFTWARES: {
    EXPORT: ['export_software_list_as_excel_file'],
    POST: ['create_new_software'],
    GET: ['get_software_details'],
    UPDATE: ['edit_software', 'get_software_details'],
    ARCHIVED: ['get_archived_software'],
    RESTITUTION: ['toggle_software_status'],
  },
  HARDWARES__VIEW: {
    GET: ['get_hardware_usages'],
    RESTITUTION: ['app_hardwares_restitutehardware'],
    AFFECTATION: ['app_hardwares_assignhardwaretocollaborater'],
    UPDATE: ['get_hardware', 'patch_hardwares'],
  },
  MISSIONS: {
    ADD: ['post_mission'],
    GET: ['get_mission'],
    EDIT: ['patch_mission', 'get_mission'],
    DELETE: ['delete_mission'],
  },
  TRNSACTIONS: {
    ADD__EXPENSE: ['post_expense'],
    ADD__RECHARGE: ['post_recharge'],
    DELETE__RECHARGE: ['cancel_recharge'],
    DELETE__EXPENSE: ['cancel_expense'],
    REDDIRECT: ['get_collaborator_transactions'],
    DOWNLOAD: ['get_collaborater_transactions_summary'],
  },
  CANDIDATE__APPLICAYION: {
    EXPORT: ['export_candidates_as_excel_file'],
    GET: ['get_candidate'],
    CONVERT: ['register_collaborater'],
    CHANGE_STATUS: ['change_candidate_status'],
  },
  CANDIDATE__STATISTIC: {
    EXPORT: ['export_candidates_statistics_as_excel_file'],
    GET: ['get_candidates_statistics'],
  },
  INTERVIEW__HISTORY: {
    GET__INTERVIEWS__HISTORY: ['get_candidate_interviews'],
    ADD: ['add_candidate_interview'],
    GET: ['get_interview'],
    EDIT: ['get_interview', 'edit_an_interview'],
  },
  INTERVIEWS__VIEW: {
    DELETE: ['cancel_interview'],
    EDIT: ['edit_an_interview', 'get_interview'],
  },
  MY__CANDIDATE: {
    GET: ['get_interview'],
  },
  NEWSLETTER: {
    EXPORT: ['export_newsletter_subscribers_as_excel_file'],
  },
  RECRUTEMENTREQUESTS: {
    VIEW: ['get_recruitment_request_details'],
    UPDATE: ['update_recruitment_request_details', 'get_recruitment_request_details'],
    ADD: ['create_new_recruitment_request'],
    ARCHIVED: ['get_archived_recruitment_requests_list'],
    TOGGLESTATUS: ['toggle_recruitment_request_status'],
  },
  TIMEREGISTRANTION: {
    EXPORT: ['export_time_registrations_as_excel_file'],
  },
  TIMEREGISTRANTIONGAP: {
    EXPORT: ['export_daily_gaps_as_excel_file'],
  },
  LEAVES: {
    POST: ['post_leave_request'],
    GET_ONE_BYID: ['get_one_leave_request_by_its_id'],
  },
  MY__LEAVES: {
    ADD: ['post_leave_request_of_authenticated_user'],
    VIEW: ['get_own_leave_request_by_id'],
    CANCEL: ['cancel_leave_request'],
  },
  ALL__LEAVES: {
    POST: ['post_special_leave'],
    GET: ['get_collaborater_leaves'],
  },
  SPECIAL__LEAVES__VIEW: {
    DELETE: ['cancel_special_leave'],
    GET_COLLABORATERS: ['get_collaborater'],
  },
  GRANTED__LEAVES__VIEW: {
    CANCEL: ['cancel_standard_or_seniority_leave'],
  },
  ALL__ABSANCES: {
    RECALCULATION: ['month_absences_recalculation'],
    GET__STATE: ['get_absences_state'],
    CHANGE__STATUS: ['change_absence_status'],
  },
  REVIEW__REQUESTS: {
    ADMIN: ['review_demand_as_admin'],
  },
  REDDIRECT__COLLABORATER: ['get_collaborater'],
  EVENTS: {
    CREATE: ['create_new_event'],
    UPDATE: ['update_an_event'],
    GETALL: ['get_list_of_all_events_paginated_and_filtered', 'get_list_of_all_events_calendar'],
    GETONE: ['get_one_event_by_its_id'],
    REPPORT: ['report_event'],
    GETLISTT: ['get_list_of_all_events_calendar'],
  },
  PAYRISES: {
    UPDATE: ['patch_pay_rise', 'get_collaborater_last_active_payrise_after_a_date'],
    DELETE: ['cancel_pay_rise'],
  },
};
